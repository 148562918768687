import React from 'react'
import { Row } from 'reactstrap'
import { useHistory } from 'react-router-dom'

const BreadCrumbs = ({ trail }) => {
  let history = useHistory()
  return (
    <h3 className="page-title">
      <Row>
        {trail.map((crumb, index) => {
          if (index < trail.length - 1) {
            return (
              <React.Fragment>
                <div style={{ color: '#4196D6', cursor: 'pointer' }} onClick={() => history.go(index - trail.length + 1)}>
                  {crumb}
                </div>
                <div style={{ padding: '0px 5px 0px 5px' }}>{'>'}</div>
              </React.Fragment>
            )
          } else {
            return <div>{crumb}</div>
          }
        })}
      </Row>
    </h3>
  )
}
export default BreadCrumbs
