import React from 'react'
import { Route } from 'react-router-dom'
import { Switch, useRouteMatch } from 'react-router-dom'
import Configuration from './'

export default () => {
    let match = useRouteMatch()
    return (
        <div>
            <Switch>
                <Route path={`${match.path}/`} component={Configuration} />
            </Switch>
        </div>
    )
}
