import React, { useState, useEffect, useCallback } from 'react'
import styles from './animated-checkmark.module.scss'

const checkMark = ({ success }) => {
  const successMark = () => {
    return (
      // <React.Fragment>
      <div class="status-mark">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle
            class={(styles['path'], styles['circle'])}
            fill="none"
            stroke="#73AF55"
            strokeWidth="6"
            strokeMiterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <polyline
            class={(styles['path'], styles['check'])}
            fill="none"
            stroke="#73AF55"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            points="100.2,40.2 51.5,88.8 29.8,67.5 "
          />
        </svg>
      </div>
      // </React.Fragment>
    )
  }

  const failureMark = () => {
    return (
      <div class="status-mark">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
          <circle
            class={(styles['path'], styles['circle'])}
            fill="none"
            stroke="#D06079"
            strokeWidth="6"
            strokeMiterlimit="10"
            cx="65.1"
            cy="65.1"
            r="62.1"
          />
          <line
            class={(styles['path'], styles['line'])}
            fill="none"
            stroke="#D06079"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="34.4"
            y1="37.9"
            x2="95.8"
            y2="92.3"
          />
          <line
            class={(styles['path'], styles['line'])}
            fill="none"
            stroke="#D06079"
            strokeWidth="6"
            strokeLinecap="round"
            strokeMiterlimit="10"
            x1="95.8"
            y1="38"
            x2="34.4"
            y2="92.2"
          />
        </svg>
      </div>
    )
  }

  if(success === null)
    return "Loading" 
  else
    return success ? successMark() : failureMark()
}

export default checkMark
