import React, { useState, useEffect } from 'react';
import { Button, ButtonToolbar, Input, Col, Row } from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import MailRuIcon from 'mdi-react/MailRuIcon';
import WebIcon from 'mdi-react/WebIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import CellphoneIphoneIcon from 'mdi-react/CellphoneIphoneIcon'
import { useQuery, useMutation } from '@apollo/react-hooks'
import CityIcon from 'mdi-react/CityIcon'
import Panel from 'shared/components/Panel.jsx'
import HomeIcon from 'mdi-react/HomeIcon'
import MapMarkerIcon from 'mdi-react/MapMarkerIcon'
import * as Actions from "./Profile.graphql"
import { NotificationManager } from 'react-notifications';
import Picture from './Picture'
import PageView from 'shared/components/PageView'
import { useRouteMatch } from 'react-router-dom'


const ProfileForm = () => {
    const [errorUI, setErrorUI] = useState(false);
    const [ID, setID] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [province, setProvince] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [website, setWebsite] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [active, setActive] = useState(true);
    let match = useRouteMatch();


    const { data, loading, error, refetch } = useQuery(
        Actions.GET_ME, { fetchPolicy: 'network-only' }
    )
    const [updateUser] = useMutation(Actions.UPDATE_USER);

    useEffect(() => {
        try {
            if (data && data.me) {
                setID(data.me['id'])
                setFirstName(data.me['firstName'])
                setLastName(data.me['lastName'])
                setUserName(data.me['username'])
                setEmail(data.me['email'])
                setCity(data.me['city'])
                setCountry(data.me['country'])
                setProvince(data.me['province'])
                setPhoneNumber(data.me['phone_number'])
                setWebsite(data.me['website'])
                setAddress(data.me['address'])
                setPostalCode(data.me['postal_code'])
                setActive(data.me['active']);
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [data])


    const onFormSubmit = async () => {
        if (firstName && userName && password && email) {
            let inputs = {
                firstName: firstName,
                lastName: lastName,
                username: userName,
                password: password,
                email: email,
                city: city,
                country: country,
                province: province,
                website: website,
                phone_number: phoneNumber,
                postal_code: postalCode,
                address: address,
                active: active
            }
            let result = await updateUser({ variables: { id: ID, input: inputs } })
                .catch(e => {
                    NotificationManager.error('Error in saving', 'User Information', 3000)
                })
            if (result) {
                setPassword('');
                NotificationManager.success('Successfully saved', 'User Information', 2000);
            }
            refetch();
        }
        else {
            NotificationManager.error('Mandatory fields are not provided', 'User Information', 3000)
        }
    }

    const isLoading = () => {
        return errorUI || loading || error
    }

    // const getBreadCrumbValue = () => {
    //     let product = match.path.toString().split('/');
    //     if (product.includes("smartbuilding")) {
    //         return "Power Billing"
    //     }
    //     else if (product.includes("fireblight")) {
    //         return "Fire Blight"
    //     }
    //     else if (product.includes("davis")) {
    //         return "Davis Weatherstation"
    //     }
    //     else if (product.includes("hanatech")) {
    //         return "Configuration"
    //     }
    //     else if (product.includes("dashboard")) {
    //         return "Facilities"
    //     }
    // }

    return (
        isLoading() ? '' :
            (
                <PageView trail={["Home", 'Profile']}>
                    <Panel xs={12} md={12} lg={12} xl={12} title="Profile">
                        <Picture />
                        <Row form>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">First Name</span>
                                    <div className="form__form-group-field">
                                        <Input
                                            name="firstname"
                                            type="text"
                                            value={firstName}
                                            onChange={e => setFirstName(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Last Name</span>
                                    <div className="form__form-group-field">
                                        <Input
                                            name="lastname"
                                            type="text"
                                            value={lastName}
                                            onChange={e => setLastName(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Username</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <AccountOutlineIcon />
                                        </div>
                                        <Input
                                            name="username"
                                            type="text"
                                            value={userName}
                                            onChange={e => setUserName(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">E-mail</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MailRuIcon />
                                        </div>
                                        <Input
                                            name="email"
                                            type="text"
                                            value={email}
                                            onChange={e => setEmail(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Password</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <KeyVariantIcon />
                                        </div>
                                        <Input
                                            name="password"
                                            type="password"
                                            value={password}
                                            onChange={e => setPassword(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Phone Number</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <CellphoneIphoneIcon />
                                        </div>
                                        <Input
                                            name="phonenumber"
                                            type="text"
                                            value={phoneNumber}
                                            onChange={e => setPhoneNumber(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Website</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <WebIcon />
                                        </div>
                                        <Input
                                            name="website"
                                            type="text"
                                            value={website}
                                            onChange={e => setWebsite(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Address</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <MapMarkerIcon />
                                        </div>
                                        <Input
                                            name="address"
                                            type="text"
                                            value={address}
                                            onChange={e => setAddress(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Zip-Code</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <HomeIcon />
                                        </div>
                                        <Input
                                            name="postalcode"
                                            type="text"
                                            value={postalCode}
                                            onChange={e => setPostalCode(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">City</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <CityIcon />
                                        </div>
                                        <Input
                                            name="city"
                                            type="text"
                                            value={city}
                                            onChange={e => setCity(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Province</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <CityIcon />
                                        </div>
                                        <Input
                                            name="province"
                                            type="text"
                                            value={province}
                                            onChange={e => setProvince(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">Country</span>
                                    <div className="form__form-group-field">
                                        <div className="form__form-group-icon">
                                            <CityIcon />
                                        </div>
                                        <Input
                                            name="country"
                                            type="text"
                                            value={country}
                                            onChange={e => setCountry(e.currentTarget.value)}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <ButtonToolbar className="form__button-toolbar">
                            <Button color="primary" type="submit" onClick={onFormSubmit}>Submit</Button>
                        </ButtonToolbar>
                    </Panel>
                </PageView>
            )
    )
}

export default ProfileForm