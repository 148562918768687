import React, { Component, Fragment } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, HashRouter } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader'
import 'bootstrap/dist/css/bootstrap.css'
import '../../scss/app.scss'
import Router from './Router'
import store from './store'
import ScrollToTop from './ScrollToTop'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from '../../shared/graphql/authentication'
import { NotificationContainer } from 'react-notifications';

class App extends Component {
  constructor() {
    super()
    this.state = {
      loading: true,
      loaded: false,
    }
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.setState({ loaded: true })
    })
  }

  render() {
    const { loaded, loading } = this.state
    return (
      <div>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <HashRouter basename="/">
              <ScrollToTop>
                <Fragment>
                  <NotificationContainer />
                  {!loaded && (
                    <div className={`load${loading ? '' : ' loaded'}`}>
                      <div className="load__icon-wrap">
                        <svg className="load__icon">
                          <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                      </div>
                    </div>
                  )}
                  <div>
                    <Router />
                  </div>
                </Fragment>
              </ScrollToTop>
            </HashRouter>
          </Provider>
        </ApolloProvider>
      </div>
    )
  }
}

export default hot(module)(App)
