import React, { useRef, useEffect, useState } from 'react'
import { Table, Col, Container, Row, Card, CardBody, Button } from 'reactstrap'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
// import ReactToPrint from 'react-to-print';
import BreadCrumbs from 'shared/components/BreadCrumbs'
import PageView from 'shared/components/PageView'
import styles from './Bill.module.scss'
const Bill = ({ match }) => {
  const [image, setImage] = useState('');
  const [errorUI, setErrorUI] = useState(false);
  const [imageEnabled, setImageEnabled] = useState(false);
  const [loadImage, { data: imageData, loading: imageLoading, error: imageError, refetch: imageRefetch }] = useLazyQuery(gql`
  query{
    getWhiteLabelPicture{
        filename,
        encoding,
      }
    }
  `, { fetchPolicy: 'no-cache' });

  const { data: meData, loading: meLoading, error: meError } = useQuery(gql`
    query getMe {
      me{
        account{
          id
          white_label_enabled
        }
      }
  }`, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    try {
      if (meData && meData.me && meData.me.account) {
        if (meData.me.account.white_label_enabled && meData.me.account.id != 1) {
          setImageEnabled(meData.me.account.white_label_enabled);
          loadImage();
        }
      }
    }
    catch{
      setErrorUI(true)
    }
  }, [meData])


  useEffect(() => {
    try {
      if (imageData && imageData.getWhiteLabelPicture) {
        setImage(parseImage(imageData.getWhiteLabelPicture));
      }
    }
    catch{
      setErrorUI(true)
    }
  }, [imageData])

  useEffect(() => {
    try {
      if (imageError) {
        setImage('');
      }
    }
    catch{
      setErrorUI(true)
    }
  }, [imageError])

  const parseImage = (imgData) => {
    if (imgData.filename && imgData.encoding) {
      let img = `data:${imgData.filename};base64,${imgData.encoding}`;
      return img;
    }
  }



  const { data, loading, error } = useQuery(
    gql`
      query GetBillByInvoiceNumber($invoiceNumber: String!) {
        getBillByInvoiceNumber(invoice_number: $invoiceNumber) {
          invoice_number
          client_name
          creation_date
          location_name
          facility_name
          facility_address
          facility_address_ext
          city
          province
          country
          postal_code
          from_date
          to_date
          meters_bill {
            meter_id
            meter_name
            prev_reading_dt
            prev_reading
            current_reading_dt
            current_reading
            power_consumption
            energy_cost1
            energy_cost1_rate
            energy_cost2
            energy_cost2_rate
            total_energy_cost
          }
          sub_total
          tax_rate
          tax
          total_charges
        }
      }
    `,
    {
      variables: { invoiceNumber: match.params.id },
    }
  )
  // if (loading) return 'Loading...'
  // if (error) return `Error! ${error.message}`
  if (loading || meLoading || imageLoading) return ''
  if (error || meError) return ''
  let billDetails = data.getBillByInvoiceNumber
  let fixedImageURL = `/img/${billDetails.facility_name.replace(new RegExp(' ', 'g'), '_')}.png`;

  const getPrintButton = () => {
    return (
      <Button color="primary" onClick={async () => await window.print()}>
        Print
      </Button>
    )
  }
  return (
    <PageView trail={['Power Billing', 'Invoices', `${billDetails.invoice_number}`]} titleRight={getPrintButton()}>
      <Card md={12} lg={12} xl={12} xs={12} className={styles.print}>
        <div style={{ display: 'inline-block', overflowX: 'auto', width: '100%' }}>
          <div style={{ minWidth: '1000px' }}>
            <CardBody>
              <Row>
                <Col style={{ display: 'flex', margin: 'auto', justifyContent: 'flex-start', paddingLeft: '30px' }}>
                  <div style={{ width: '80%' }}>
                    <img src={(image == '' && !imageEnabled) ? fixedImageURL : image} alt="logo" />
                  </div>
                </Col>
                <Col style={{ display: 'flex', margin: 'auto', justifyContent: 'flex-end', paddingRight: '30px' }}>
                  <Table striped bordered size="sm" style={{ width: 'max-content', lineHeight: '1' }}>
                    <tbody>
                      <tr>
                        <th>From Date</th>
                        <td>{billDetails.from_date}</td>
                      </tr>
                      <tr>
                        <th>To Date</th>
                        <td>{billDetails.to_date}</td>
                      </tr>
                      <tr>
                        <th>Total Charges ($)</th>
                        <td>{billDetails.total_charges}</td>
                      </tr>
                      <tr>
                        <th>Statement Date</th>
                        <td>{billDetails.creation_date}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row style={{ padding: '10px 30px 10px 30px' }}>
                {/* <Col sm="12" md={{ size: 3, offset: 5 }} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
              Power-bill Statement
            </Col> */}
                <Table striped bordered size="sm" style={{ textAlign: 'center', borderWidth: 'initial', borderColor: 'black' }}>
                  <thead className={styles.tableHeader} style={{ backgroundColor: 'black' }}>
                    <tr>
                      <th style={{ color: 'white' }}>Account Name</th>
                      <th style={{ color: 'white' }}>Service Address</th>
                      <th style={{ color: 'white' }}>Invoice Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{billDetails.client_name}</td>
                      <td>
                        {billDetails.facility_address}
                        {', '}
                        {billDetails.city}
                        {', '}
                        {billDetails.province}
                        {' - '}
                        {billDetails.postal_code}
                      </td>
                      <td>{billDetails.invoice_number}</td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
              <Row style={{ padding: '0px 30px 15px 30px' }} id="Meters">
                <Table striped bordered size="sm" style={{ textAlign: 'center', borderWidth: 'initial', borderColor: 'black' }}>
                  <thead style={{ backgroundColor: 'black' }}>
                    <tr className={styles.tableHeader}>
                      <th style={{ color: 'white' }}>Meter ID</th>
                      <th style={{ color: 'white' }}>Meter Name</th>
                      <th style={{ color: 'white' }}>Previous Date</th>
                      <th style={{ color: 'white' }}>Previous Read (Kwh)</th>
                      <th style={{ color: 'white' }}>Present Date</th>
                      <th style={{ color: 'white' }}>Present Read (Kwh)</th>
                      <th style={{ color: 'white' }}>Power Consumption (Kwh)</th>
                      {/* <th style={{ color: 'white' }}>Energy Cost1 ($)</th>
                  <th style={{ color: 'white' }}>Energy Cost2 ($)</th> */}
                      <th style={{ color: 'white' }}>Energy Cost ($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {billDetails.meters_bill.map(meter => {
                      return (
                        <tr>
                          <td>{meter.meter_id}</td>
                          <td>{meter.meter_name}</td>
                          <td>{meter.prev_reading_dt}</td>
                          <td>{meter.prev_reading}</td>
                          <td>{meter.current_reading_dt}</td>
                          <td>{meter.current_reading}</td>
                          <td>{meter.power_consumption}</td>
                          {/* <td>{meter.energy_cost1}</td>
                      <td>{meter.energy_cost2}</td> */}
                          <td>{meter.total_energy_cost}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Row>
              <Row style={{ padding: '0px 15px 10px 15px' }} id="Bill">
                {/* <Col sm="12" md={{ size: 3, offset: 5 }} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
              Facility Charges
            </Col> */}
                <Col sm="12">
                  <Table striped size="sm">
                    <thead style={{ backgroundColor: 'black' }}>
                      <tr className={styles.tableHeader}>
                        <th style={{ color: 'white' }}>Service Type</th>
                        <th style={{ color: 'white', width: '100px' }}>Rate</th>
                        <th style={{ color: 'white', width: '100px' }}>Charges ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Electricity Energy Cost</td>
                        <td></td>
                        <td>{billDetails.sub_total}</td>
                      </tr>
                      <tr>
                        <td>HST</td>
                        <td>{billDetails.tax_rate * 100}%</td>
                        <td>{billDetails.tax}</td>
                      </tr>
                      <tr>
                        <td>Total Energy cost</td>
                        <td></td>
                        <td>{billDetails.total_charges}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </div>
        </div>
      </Card>
    </PageView>
  )
}
export default Bill
// import React, {useRef} from 'react'
// import { Table, Col, Container, Row, Card, CardBody } from 'reactstrap'
// import { useQuery } from '@apollo/react-hooks'
// import gql from 'graphql-tag'
// import ReactToPrint from 'react-to-print';
// import styles from "./Bill.module.scss"
// const Bill = ({ id }) => {
//   const { data, loading, error } = useQuery(
//     gql`
//       query GetBillByInvoiceNumber($invoiceNumber: String!) {
//         getBillByInvoiceNumber(invoice_number: $invoiceNumber) {
//           invoice_number
//           creation_date
//           location_name
//           facility_name
//           facility_address
//           facility_address_ext
//           city
//           province
//           country
//           postal_code
//           from_date
//           to_date
//           meters_bill {
//             meter_id
//             prev_reading_dt
//             prev_reading
//             current_reading_dt
//             current_reading
//             power_consumption
//             energy_cost1
//             energy_cost1_rate
//             energy_cost2
//             energy_cost2_rate
//             total_energy_cost
//           }
//           sub_total
//           tax_rate
//           tax
//           total_charges
//         }
//       }
//     `,
//     {
//       variables: { invoiceNumber: id },
//     }
//   )
//   if (loading) return 'Loading...'
//   if (error) return `Error! ${error.message}`
//   let billDetails = data.getBillByInvoiceNumber
//   return (
//     <Card md={12} lg={12} xl={12} xs={12} className={styles.print}>
//       <CardBody>
//       <Container>
//         <Row>
//           <Col style={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
//             <div style = {{width:"80%"}}>
//               <img src="/img/cambridge-suites.png" alt="cambridge logo" />
//             </div>
//           </Col>
//           <Col style={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
//             <Row style={{ flexDirection: 'column', textAlign: 'center', fontSize: 'medium', fontWeight: 'bold' }}>
//               <Col>{billDetails.facility_address}</Col>
//               <Col>{billDetails.city}</Col>
//               <Col>
//                 {billDetails.province} {billDetails.postal_code}
//               </Col>
//             </Row>
//           </Col>
//           <Col style={{ display: 'flex', margin: 'auto', justifyContent: 'center' }}>
//             <Table striped bordered size="sm" style={{ width: 'max-content', lineHeight: '1' }}>
//               <tbody>
//                 <tr>
//                   <th>From Date</th>
//                   <td>{billDetails.from_date}</td>
//                 </tr>
//                 <tr>
//                   <th>To Date</th>
//                   <td>{billDetails.to_date}</td>
//                 </tr>
//                 <tr>
//                   <th>Total Charges ($)</th>
//                   <td>{billDetails.total_charges}</td>
//                 </tr>
//                 <tr>
//                   <th>Statement Date</th>
//                   <td>{billDetails.creation_date}</td>
//                 </tr>
//               </tbody>
//             </Table>
//           </Col>
//         </Row>
//         <Row style={{ padding: '10px 30px 10px 30px' }}>
//           <Col sm="12" md={{ size: 3, offset: 5 }} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
//             Power-bill Statement
//           </Col>
//           <Table
//             striped
//             bordered
//             size="sm"
//             style={{ textAlign: 'center', borderWidth: 'initial', borderColor: 'black' }}
//           >
//             <thead className ={styles.tableHeader} style={{ backgroundColor: 'black' }}>
//               <tr>
//                 <th style={{ color: 'white' }}>Account Name</th>
//                 <th style={{ color: 'white' }}>Service Address</th>
//                 <th style={{ color: 'white' }}>Invoice Number</th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>XXXXXXXXX</td>
//                 <td>XXXXXXXXX</td>
//                 <td>{billDetails.invoice_number}</td>
//               </tr>
//             </tbody>
//           </Table>
//         </Row>
//         <Row style={{ padding: '0px 30px 15px 30px' }}>
//           <Table
//             striped
//             bordered
//             size="sm"
//             style={{ textAlign: 'center', borderWidth: 'initial', borderColor: 'black' }}
//           >
//             <thead style={{ backgroundColor: 'black' }}>
//               <tr className ={styles.tableHeader}>
//                 <th style={{ color: 'white' }}>Meter ID</th>
//                 <th style={{ color: 'white' }}>Previous Date</th>
//                 <th style={{ color: 'white' }}>Previous Read (Kwh)</th>
//                 <th style={{ color: 'white' }}>Present Date</th>
//                 <th style={{ color: 'white' }}>Present Read (Kwh)</th>
//                 <th style={{ color: 'white' }}>Power Consumption (Kwh)</th>
//                 <th style={{ color: 'white' }}>Energy Cost1 ($)</th>
//                 <th style={{ color: 'white' }}>Energy Cost2 ($)</th>
//                 <th style={{ color: 'white' }}>Energy Cost ($)</th>
//               </tr>
//             </thead>
//             <tbody>
//               {billDetails.meters_bill.map(meter => {
//                 return (
//                   <tr>
//                     <td>{meter.meter_id}</td>
//                     <td>{meter.prev_reading_dt}</td>
//                     <td>{meter.prev_reading}</td>
//                     <td>{meter.current_reading_dt}</td>
//                     <td>{meter.current_reading}</td>
//                     <td>{meter.power_consumption}</td>
//                     <td>{meter.energy_cost1}</td>
//                     <td>{meter.energy_cost2}</td>
//                     <td>{meter.total_energy_cost}</td>
//                   </tr>
//                 )
//               })}
//             </tbody>
//           </Table>
//         </Row>
//         <Row style={{ padding: '0px 30px 10px 30px' }}>
//           <Col sm="12" md={{ size: 3, offset: 5 }} style={{ fontWeight: 'bold', fontSize: 'medium' }}>
//             Facility Charges
//           </Col>
//           <Table striped size="sm">
//             <thead style={{ backgroundColor: 'black' }}>
//               <tr className ={styles.tableHeader}>
//                 <th class="col" style={{ color: 'white' }}>
//                   Service Type
//                 </th>
//                 <th class="col" style={{ color: 'white' }}>
//                   Rate
//                 </th>
//                 <th class="col" style={{ color: 'white' }}>
//                   Charges ($)
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               <tr>
//                 <td>Electricity Energy Cost</td>
//                 <td></td>
//                 <td>{billDetails.sub_total}</td>
//               </tr>
//               <tr>
//                 <td>HST</td>
//                 <td>{billDetails.tax_rate * 100}%</td>
//                 <td>{billDetails.tax}</td>
//               </tr>
//               <tr>
//                 <td>Total Energy cost</td>
//                 <td></td>
//                 <td>{billDetails.total_charges}</td>
//               </tr>
//             </tbody>
//           </Table>
//         </Row>
//       </Container>
//       </CardBody>
//     </Card>
//   )
// }
// class BillClass extends React.Component{
//   render(){
//     return(
//       <Bill id = {this.props.id}/>
//     )
//   }
// }
// const PrintBill = ({match}) => {
//   const componentRef = useRef();
//   return (
//     <div>
//       <ReactToPrint
//         trigger={() => <button>Print this out!</button>}
//         content={() => componentRef.current}
//         copyStyles = {true}
//       />
//       <BillClass id = {match.params.id} ref={componentRef} />
//     </div>
//   );
// };
// export default PrintBill
