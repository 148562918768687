import gql from 'graphql-tag'

export const GET_ROLES = gql`
query {
    getRoles {
      id,
      role
    }
  }`


export const CREATE_ROLE = gql`
mutation createRole(	
    $role: String!
    ){
    createRole(name: $role){
      role
    }
  }`

export const UPDATE_ROLE = gql`
mutation updateRole(	
    $id: ID!, 
    $role: String
    ){
    updateRole( id: $id, name: $role){
      id
    }
  }`

export const DISABLE_ROLE = gql`
mutation disableRole(	
    $id: ID!
    ){
    disableRole(id: $id){
      id
    }
  }`

