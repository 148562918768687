import React from 'react'
import { useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import Layout from '../Layout/index'
import Dashboard from '../Smartfarming/Dashboard/routes'
import Davis from '../Smartfarming/Davis/routes'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Profile from 'containers/Profile/index.jsx'

export default () => {
  const { data: meResult, loading, error, refetch } = useQuery(
    gql`
      query {
        me {
          account {
            name
          }
          products {
            id
            name
            suite
          }
        }
      }
    `
  )
  let history = useHistory()
  let match = useRouteMatch()

  useEffect(() => {
    if (meResult) {
      let products = [...new Set(meResult.me.products.filter(product => product.suite === 'Smart Farming').map(product => product.name))]
      if (products.includes('Fire Blight')) {
        history.push(`${match.path}/fire-blight`)
      } else if (products.includes('Davis')) {
        history.push(`${match.path}/davis`)
      }
    }
  }, [meResult])

  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Switch>
          <Route path={`${match.path}/davis`} component={Davis} />
          <Route path={`${match.path}/fire-blight`} component={Dashboard} />
          <Route path={`${match.path}/profile`} component={Profile} />
        </Switch>
      </div>
    </div>
  )
}
