import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import gql from 'graphql-tag'
import { NotificationManager } from 'react-notifications';

const ThermostatCustomPolicy = () => {

    const GET_CUSTOM_POLICIES = gql`                    
                                query GET_CUSTOM_POLICIES{
                                    getCustomThermostatPolicies{
                                    id
                                    name
                                    weekday
                                    start_date
                                    end_date
                                    heating_temp_max
                                    cooling_temp_min
                                    status
                                    }
                                }`

    const CREATE_CUSTOM_POLICY = gql`
                                mutation CRE_TP($input: ThermostatCustomPolicyInput!){
                                    createCustomThermostatPolicy(input: $input)
                                }`

    const UPDATE_CUSTOM_POLICY = gql`
    mutation UPD_TP($id: ID!, $input: ThermostatCustomPolicyInput!){
        updateCustomThermostatPolicy(id: $id, input: $input)
    }`

    const DELETE_CUSTOM_POLICY = gql`
    mutation DEL_TP($id: ID!){
        deleteCustomThermostatPolicy(id: $id)
      }
    `

    const WEEKDAY_OBJ = {
        "0": "SUNDAY", "1": "MONDAY", "2": "TUESDAY", "3": "WEDNESDAY", "4": "THURSDAY", "5": "FRIDAY", "6": "SATURDAY", "7": "ALL"
    }
    let columns = [
        { title: 'id', field: 'id', editable: 'never', hidden: true },
        { title: '*Name', field: 'name'},
        { title: '*Weekday', field: 'weekday', editable: 'always', lookup: WEEKDAY_OBJ},
        { title: '*Start date', field: 'start_date', type: "datetime"},
        { title: '*End date', field: 'end_date', type: "datetime"},
        { title: '*Max temperature (°C)', field: 'heating_temp_max'},
        { title: '*Min temperature (°C)', field: 'cooling_temp_min'},
        { title: 'Status', field: 'status', type: "boolean"}
    ]
    const [policies, setPolicies] = useState([]);
    const { data, loading, error, refetch } = useQuery(GET_CUSTOM_POLICIES, { fetchPolicy: "network-only" })
    let [createPolicy, { data: createPolicyResponse, error: createPolicyError, loading: createPolicyLoading }] = useMutation(CREATE_CUSTOM_POLICY);
    let [updatePolicy, { data: updatePolicyResponse, error: updatePolicyError, loading: updatePolicyLoading }] = useMutation(UPDATE_CUSTOM_POLICY);
    let [deletePolicy, { data: deletePolicyResponse, error: deletePolicyError, loading: deletePolicyLoading }] = useMutation(DELETE_CUSTOM_POLICY);

    useEffect(() => {
        if(data){
            let customPolices = data.getCustomThermostatPolicies
            
            customPolices = customPolices.map(customPolicy => {
                let index = Object.values(WEEKDAY_OBJ).indexOf(customPolicy.weekday)
                let key = index == -1 ? customPolicy.weekday : Object.keys(WEEKDAY_OBJ)[index];
                customPolicy.weekday = key
                return customPolicy
            })
            setPolicies(customPolices)
        }

    }, [data])

    useEffect(() => {

        if(createPolicyError){
            NotificationManager.error(modifyErrorMessage(createPolicyError.message), "THERMOSTAT CUSTOM POLICY", 3000)
        }

        if(updatePolicyError){
            NotificationManager.error(modifyErrorMessage(updatePolicyError.message), "THERMOSTAT CUSTOM POLICY", 3000)
        }

    }, [createPolicyError, updatePolicyError])

    const modifyErrorMessage = (message) => {
        message = message.replace(/GraphQL error:/, '')
        return message
    }

    const getDateString = (datetime) => {

        let datetimeStr = datetime
        if(typeof(datetime) !== "string"){
            const pad = '00'
            let month = datetime.getMonth()+1
            month = pad.substring(0, pad.length - month.toString().length) + month
    
            let day = datetime.getDate() 
            day = pad.substring(0, pad.length - day.toString().length) + day
    
            let date = datetime.getFullYear() + "-" + month + "-" + day
            let time = datetime.getHours() + ":" + datetime.getMinutes()
    
            datetimeStr = date + " " + time
        }
    
        return datetimeStr
    }

    if (loading) return 'Loading'
    if (error) return 'error'

    return (
        <React.Fragment>
            <MaterialTable
                    options={{
                        addRowPosition: "first"
                    }}
                    title = "Thermostat Custom Policies"
                    validationError = {error}
                    columns={columns}
                    data={policies}
                    editable={{
                        onRowAdd : async(newData) =>{
                            let data = {
                                name: newData.name,
                                weekday: WEEKDAY_OBJ[newData.weekday],
                                start_date: getDateString(newData.start_date),
                                end_date: getDateString(newData.end_date),
                                heating_temp_max: parseFloat(newData.heating_temp_max),
                                cooling_temp_min: parseFloat(newData.cooling_temp_min),
                                status: newData.status
                            }
                            await createPolicy({
                                variables: {
                                    input: data
                                }
                            })
                            refetch()
                        },
                        onRowUpdate: async(newData, oldData) => {
                            let data = {
                                name: newData.name,
                                weekday: WEEKDAY_OBJ[newData.weekday],
                                start_date: getDateString(newData.start_date),
                                end_date: getDateString(newData.end_date),
                                heating_temp_max: parseFloat(newData.heating_temp_max),
                                cooling_temp_min: parseFloat(newData.cooling_temp_min),
                                status: newData.status
                            }
                            await updatePolicy({
                                variables: {
                                    id: oldData.id,
                                    input: data
                                }
                            })
                            refetch()
                        },
                        onRowDelete: async(oldData) => {
                            await deletePolicy({
                                variables: {
                                    id: oldData.id
                                }
                            })
                            refetch()
                        } 
                    }}
                >

                </MaterialTable>
        </React.Fragment>
    )
}

export default ThermostatCustomPolicy