import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import * as Actions from 'containers/Smartbuilding/Apollo State/PowerBilling.js'
import { resolvers, typeDefs } from '../../containers/Smartbuilding/PowerBilling/Billing/resolvers'
import introspectionQueryResultData from '../../fragmentTypes.json'

const getApiUrl = () => {
  let baseUrl = window.location.host
  let domainArray = baseUrl.split('.')
  if (baseUrl.startsWith('localhost')) {
    return process.env.GRAPHQL_URL
  } else if (domainArray.length > 3) {
    return 'https://api.onesmartview.com/'
  } else {
    if (domainArray.length > 2) {
      return `https://api${domainArray[0].endsWith('-dev') ? '-dev' : ''}.${domainArray[1]}.${domainArray[2]}`
    } else {
      return `https://api.${baseUrl}/`
    }
  }
}

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const httpLink = createHttpLink({
  uri: getApiUrl(),
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const cache = new InMemoryCache({ fragmentMatcher })
cache.writeData({
  data: Actions.defaults,
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ fragmentMatcher }),
  typeDefs,
  resolvers,
})
