import gql from 'graphql-tag'

export const GET_IMAGES_BY_ZONE = gql`query getImagesByZone($id: ID!, $startDate: String!, $endDate: String!) {
    getImagesByZone(zone_id:$id,startDate:$startDate, endDate:$endDate){
  timestamp,
  mimeType,
  image
} 
}`

export const GET_DATES_BY_ZONE = gql`query getImagesByZone($id: ID!, $startDate: String!, $endDate: String!) {
  getImagesByZone(zone_id:$id,startDate:$startDate, endDate:$endDate){
timestamp
} 
}`

export const GET_CURRENTCONDITIONS_BY_ZONE = gql`query getCurrentConditionsByZone($id: ID!, $startDate: String!, $endDate: String!) {
    getCurrentConditionsByZone(zone_id:$id,startDate:$startDate, endDate:$endDate){
  timestamp,
  lsid,
  hum,
  temp,
  txid,
  rx_state,
  uv_index,
  wet_bulb,
  dew_point,
  rain_size,
  solar_rad,
  thw_index,
  heat_index,
  rain_storm
  thsw_index,
  wind_chill,
  rain_rate_hi,
  rainfall_year,
  wind_dir_last,
  rain_rate_last,
  rainfall_daily,
  rain_storm_last,
  rainfall_monthly,
  trans_battery_flag,
  data_structure_type,
  rain_storm_start_at,
  rainfall_last_24_hr,
  rainfall_last_15_min,
  rainfall_last_60_min,
  rain_storm_last_end_at,
rain_rate_hi_last_15_min,
rain_storm_last_start_at,
wind_speed_hi_last_2_min,
  wind_speed_avg_last_1_min,
wind_speed_avg_last_2_min,
wind_speed_hi_last_10_min,
wind_speed_avg_last_10_min,
wind_dir_scalar_avg_last_1_min,
wind_dir_scalar_avg_last_2_min,
wind_dir_at_hi_speed_last_2_min,
wind_dir_scalar_avg_last_10_min,
wind_dir_at_hi_speed_last_10_min,
} 
}`

export const GET_TIMEZONE = gql`
query{
  getFacilities{
    timezone
  }
}`