import gql from 'graphql-tag'

export const GET_FACILITIES = gql`
query {
  getFacilities {
    location_id
    id,
    name,
    city,
    province,
    country,
    postal_code,
    address,
    address_ext,
    timezone,
    active,
  }
}`

export const GET_LOCATIONS = gql`
query {
    getLocations {
      id,
      name
    }
  }`


export const CREATE_FACILITY = gql`
mutation createFacility(	
  $input: FacilityInput!
  ){
    createFacility(input: $input)
}`

export const UPDATE_FACILITY = gql`
mutation updateFacility(	
  $id:ID!
    $input: FacilityInput!
    ){
      updateFacility(id:$id, input: $input)
  }`

export const DISABLE_FACILITY = gql`
mutation disableFacility(	
    $id: ID!
    ){
      disableFacility(id: $id)
  }`

