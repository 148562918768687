import gql from 'graphql-tag'

export const GET_USERS = gql`
query {
    getUsers {
      id,
      firstName,
      lastName,
      username,
      email,
      address,
      city,
      country,
      postal_code,
      phone_number,
      province,
      website,
      active,
      account{
        id,
        name
      }
    }
  }`

export const GET_ACCOUNTS = gql`
query {
    getAccounts {
      id,
      name
    }
  }`


// export const CREATE_USER = gql`
// mutation createUser(	
//     $account_id:ID,
//     $email:String,
//     $firstName:String!,
//     $lastName:String,
//     $username:String!,
//     $password:String!
//     ){
//     createUser(account_id: $account_id,email:$email, firstName:$firstName,
//     lastName:$lastName, username:$username, password:$password){
//       id
//     }
//   }`

export const CREATE_USER = gql`
mutation createUser(	
  $input: UserInput!
  ){
    createUser(input: $input)
}`

// export const UPDATE_USER = gql`
// mutation updateUser(	
//     $id: ID!,
//     $account_id:ID,
//     $email:String,
//     $firstName:String,
//     $lastName:String,
//     $username:String,
//     $password:String
//     $active: Boolean
//     ){
//     updateUser( id: $id, account_id: $account_id,email:$email, firstName:$firstName,
//     lastName:$lastName, username:$username, password:$password, active: $active){
//       id
//     }
//   }`

export const UPDATE_USER = gql`
  mutation updateUser(	
    $id:ID!
      $input: UserInput!
      ){
        updateUser(id:$id, input: $input){
          id
        }
    }`
  

export const DISABLE_USER = gql`
mutation disableUser(	
    $id: ID!
    ){
    disableUser(id: $id){
      id
    }
  }`

