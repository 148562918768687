import React from 'react'
const { compose, withProps, withHandlers, withStateHandlers } = require('recompose')
const { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, Circle } = require('react-google-maps')

const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer')
const MapWithAMakredInfoWindow = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDv_DaU0LLpt4UlveX2lVmSXeqHveGWnS8&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => markerClusterer => {
      const clickedMarkers = markerClusterer.getMarkers()
    },
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
      infoIndex: null,
    }),
    {
      onToggleOpen: ({ isOpen, infoIndex }) => index => ({
        isOpen: !isOpen,
        infoIndex: index,
      }),
    }
  ),

  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: parseFloat(props.markers[0].latitude), lng: parseFloat(props.markers[0].longitude) }}
      mapTypeId="satellite"
    >
      <MarkerClusterer onClick={props.onMarkerClustererClick} averageCenter enableRetinaIcons gridSize={60}>
        {props.markers.map(marker => (
          <Marker
            key={marker.locID}
            position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
            onClick={() => props.onToggleOpen(marker.locID)}
          >
            {props.isOpen && props.infoIndex == marker.locID && (
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <p>
                  <p>
                    <b>Name: {marker.locName}</b>
                  </p>
                  {/* <p>Address: {marker.address}</p> */}
                  <small>Latitude: {marker.latitude}</small>
                  {'  '}
                  <small>Longitude: {marker.longitude}</small>
                  <br />
                  <small>Battery Level: {Math.round(((marker.batteryLevel - 3.5) / 0.72) * 100)}%</small>
                  <br />
                  <small>Pressure: {parseFloat(marker.barometricPressure).toFixed(1)} kPa </small>
                  <br />
                  <small>
                    Temperature: {parseFloat(marker.temperature).toFixed(1)}
                    <sup>o</sup>F
                  </small>
                  <br />
                  <small>Humidity: {parseFloat(marker.humidity).toFixed(1)}%</small>
                  <br />
                </p>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </MarkerClusterer>
      {props.markers.map(
        marker =>
          marker.circle && (
            <Circle
              defaultCenter={{
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude),
              }}
              radius={marker.circle.radius}
              options={marker.circle.options}
            />
          )
      )}
    </GoogleMap>
  )
})

const Map = ({ Mapdata, checkError }) => {
  let plotMapData
  try {
    plotMapData = configureMapData(Mapdata)
  } catch {
    checkError(true)
  }
  return plotMapData && plotMapData.length != 0 ? <MapWithAMakredInfoWindow markers={plotMapData} /> : ''
}

const configureMapData = data => {
  let mapdata = []
  data.map((device, i) => {
    let mapprops = {}
    if (device.devices.bewhere.length > 0) {
      mapprops['locID'] = i
      mapprops['locName'] = device.devices.bewhere[0].name
      mapprops['latitude'] = device.devices.bewhere[0].latitude
      mapprops['longitude'] = device.devices.bewhere[0].longitude
      mapprops['batteryLevel'] = device.devices.bewhere[0].data[0].battery_level
      mapprops['temperature'] = device.devices.bewhere[0].data[0].temperature
      mapprops['humidity'] = device.devices.bewhere[0].data[0].humidity
      mapprops['barometricPressure'] = device.devices.bewhere[0].data[0].pressure
      mapprops['circle'] = {
        radius: 1150,
        options: {
          strokeColor: getRiskColor(device.fireBlight.risk[0].level),
        },
      }
      mapdata.push(mapprops)
    }
  })
  return mapdata
}

const getRiskColor = risk => {
  return risk == 'Infection'
    ? '#ff0000'
    : risk == 'High'
      ? '#ff8247'
      : risk == 'Medium'
        ? '#d1b500'
        : risk == 'Low'
          ? '#09ba00'
          : '#FFFFFF'
}

export default Map
