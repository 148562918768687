import React from 'react'
import { Container, Row } from 'reactstrap'
import Configuration from './ConfigurationCards'
import SmartFarmingCards from './SmartFarmingCards'
import SmartBuildingCards from './SmartBuildingCards'
import Divider from './Divider'

const Hanatech = () => {
    return (
        <Container className="dashboard">
            <Row>
                <Configuration />
            </Row>
            <Row>
                <Divider />
            </Row>
            <Row>
                <SmartFarmingCards />
            </Row>
            <Row>
                <Divider />
            </Row>
            <Row>
                <SmartBuildingCards />
            </Row>
        </Container>
    )
}

export default Hanatech