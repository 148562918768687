import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'reactstrap'
import DoorClosedIcon from 'mdi-react/DoorClosedIcon'
import DoorOpenIcon from 'mdi-react/DoorOpenIcon'
import styles from './Dashboard.module.scss'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useRouteMatch } from 'react-router-dom'
import * as Actions from './UnitDashboard.graphql'
import { isEqual } from 'lodash'
import { ScaleLoader } from "react-spinners";
import Thermostat from './Thermostat'


const Lock = ({ device, screensize, refetch, smartThingsRefetch }) => {
    const name = device.name;
    const status = device.status == 'true';
    const serial = device.id;
    const [mouseDown, setMouseDown] = useState(false);
    const [toLoad, setToLoad] = useState(false);
    const [lockUnLockDoor] = useMutation(Actions.LOCK_ON_OFF);


    const handleOnClick = async (serial, status) => {
        if (serial) {
            setToLoad(true);
            await lockUnLockDoor({ variables: { smartId: serial, command: status ? 'unlock' : 'lock' } })
            await refetch();
            await smartThingsRefetch();
            setToLoad(false);
        }
    }

    const getDevice = (status) => {
        let compareDevice = status ? '1' : '0'
        switch (compareDevice) {
            case '1':
                return [<DoorClosedIcon size={60} color='#ffffff' />, 'Locked']
            case '0':
                return [<DoorOpenIcon size={60} color='#00000' />, 'Open']
            default:
                return ['', '']
        }
    }


    let displayDevice = getDevice(status);
    return (
        <div
            className={[screensize.width > 400 ? styles['box'] : styles['box_mobile'], status ? styles['deviceOn'] : '', mouseDown ? styles['mouseDown'] : ''].join(' ')}
            onMouseDown={() => {
                setMouseDown(true)
            }}
            onMouseUp={() => setMouseDown(false)}
            onMouseLeave={() => setMouseDown(false)}
            onClick={() => handleOnClick(serial, status)}>
            {
                toLoad ? <div style={{ padding: "40px" }}>
                    <ScaleLoader
                        height={40}
                        color={"#004B8D"}
                        width={7}
                        margin={3}
                        loading={toLoad}
                    />
                </div> : <div>
                        <div className="card__title">
                            <h5 style={{ paddingTop: '5px', color: status ? '#ffffff' : '#000000' }} className={[styles['bold-text'], styles['flex-justify-center']].join(' ')}>
                                {name}
                            </h5>
                        </div>
                        <div className={["dashboard__health-chart", styles['flex-justify-center']].join(" ")}>
                            {
                                displayDevice[0]
                            }
                        </div>
                    </div>

            }
            <div>
                <span style={{ display: "flex", justifyContent: 'center' }}>{displayDevice[1]}</span>
            </div>
        </div>
    )
}

export default Lock