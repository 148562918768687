import React from 'react';
// import {
//     AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
// } from 'recharts';
import moment from 'moment'
import { Line } from 'react-chartjs-2';

// const InfectionsChart = ({ chartData }) => {
//     return (
//             <ResponsiveContainer height={250} className="dashboard__area">
//                 <AreaChart data={chartData} margin={{ top: 20, left: -15, bottom: 20 }}>
//                     <XAxis dataKey="date" tick={{ fontSize: 10 }} tickFormatter={(unixTime) => moment(unixTime).utc().format('L')} />
//                     <YAxis tickLine={true} domain={[-10, 200]} />
//                     <Tooltip />
//                     <Legend />
//                     <CartesianGrid />
//                     <Area name="BBS" type="monotone" dataKey="BBS" fill="#709be0" stroke="#709be0" fillOpacity={0.3} />
//                     <Area name="CBS" type="monotone" dataKey="CBS" fill="#70bbfd" stroke="#70bbfd" fillOpacity={0.3} />
//                     <Area name="SBS" type="monotone" dataKey="SBS" fill="#70dce0" stroke="#70dce0" fillOpacity={0.3} />
//                     <Area name="TBS" type="monotone" dataKey="TBS" fill="#e0bd70" stroke="#e0bd70" fillOpacity={0.3} />
//                 </AreaChart>
//             </ResponsiveContainer>
//     )
// }

const InfectionsChart = ({ data, Min, Max }) => {
  const chartData = {
    labels: data.map(d => d.date),
    datasets: [{
      label: "BBS",
      data: data.map(d => d.BBS),
      fill: 'true',
      pointRadius: 2,
      borderColor: '#0000ff',
      borderWidth: 1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      // lineTension: 0.1
    },
    {
      label: "CBS",
      data: data.map(d => d.CBS),
      fill: 'true',
      pointRadius: 2,
      borderColor: '#64b579',
      borderWidth: 1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      // lineTension: 0.1
    },
    {
      label: "SBS",
      data: data.map(d => d.SBS),
      fill: 'true',
      pointRadius: 2,
      borderColor: '#c7b45f',
      borderWidth: 1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      // lineTension: 0.1
    },
    {
      label: "TBS",
      data: data.map(d => d.TBS),
      fill: 'true',
      pointRadius: 2,
      borderColor: '#f0e91ds',
      borderWidth: 1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      // lineTension: 0.1
    }]
  }

  const options = {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    },
    scales: {
      yAxes: [{
        gridLines: {
          display: true,
        },
        scaleLabel: {
          display: true,
        }
      }],
      xAxes: [{
        type: 'time',
        time: {
          unit: 'day',
          // tooltipFormat: 'ddd, MMMDD YYYY'
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = ''
          label += Math.round(tooltipItem.yLabel * 100) / 100;
          return label;
        }
      }
    }
  };
  return (
    <Line data={chartData} options={options} height="100vh" />
  )
}
export default InfectionsChart
