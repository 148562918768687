import gql from 'graphql-tag'

export const GET_ACCOUNTS = gql`
query {
    getAccounts {
      id,
      name,
      img_url,
      white_label_enabled,
      active
    }
  }`


export const CREATE_ACCOUNT = gql`
mutation createAccount(	
  $input: AccountInput!
  ){
  createAccount(input: $input)
}`

export const UPDATE_ACCOUNT = gql`
mutation updateAccount(	
  $id:ID!
    $input: AccountInput!
    ){
    updateAccount(id:$id, input: $input)
  }`

export const DISABLE_ACCOUNT = gql`
mutation disableAccount(	
  $id: ID!
  ){
  disableAccount(id: $id)
}`

export const UPLOAD_PICTURE = gql`
mutation uploadWhiteLabelPicture( $id:ID!, $input: FileInput!) {
  uploadWhiteLabelPicture(id:$id,input: $input)
}`

