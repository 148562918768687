import React, { useState, useEffect } from 'react'
import { Table } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useRouteMatch, Link } from 'react-router-dom'

const Bills = () => {
    let match = useRouteMatch()
    let [bills, setBills] = useState([])
    let selectedUnit = parseInt(match.params.UnitId);
    const { data, loading, error } = useQuery(
        gql`
        query getBillByClient($clientId: Int!){
            getBillsByClient(client_id:$clientId){
                              client_id
                      invoice_number
                      from_date
                      to_date
                      creation_date
                      total_charges
            }
          }
        `,
        {
            variables: { clientId: selectedUnit }
        }
    )

    useEffect(() => {
        if (data) {
            let clientBills = data.getBillsByClient;
            if (clientBills) {
                setBills(clientBills)
            }
        }
    }, [data])

    if (loading) return 'Loading...'
    if (error) return `Error! ${error.message}`

    return (
        <div>
            <Table
                striped
                bordered
                size="sm"
                style={{ textAlign: 'center', borderWidth: 'initial', borderColor: 'black' }}
            >
                <thead style={{ backgroundColor: 'black' }}>
                    <tr>
                        <th style={{ color: 'white' }}>Invoice Number</th>
                        <th style={{ color: 'white' }}>From Date</th>
                        <th style={{ color: 'white' }}>To Date</th>
                        <th style={{ color: 'white' }}>Creation Date</th>
                        <th style={{ color: 'white' }}>Total charges</th>
                        <th style={{ color: 'white' }}>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {bills.map(bill => {
                        if (bill.client_id) {
                            return (
                                <tr>
                                    <td>{bill.invoice_number}</td>
                                    <td>{bill.from_date}</td>
                                    <td>{bill.to_date}</td>
                                    <td>{bill.creation_date}</td>
                                    <td>{bill.total_charges}</td>
                                    <td><Link to={`${match.url}` + `/bill/${bill.invoice_number}`}>View</Link></td>
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </Table>
        </div>
    )
}

export default Bills