import React, { useState, useEffect } from 'react';
import Panel from '../../../../shared/components/Panel';
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import MinMaxTempChart from '../../../../shared/components/Dashboard/MaxMinTempChart'
import * as utls from 'shared/utls/index.js'

const MinMaxTemp = ({ facilityId, temperatureUnit, filterDays }) => {
  const [temperatureData, settemperatureData] = useState([]);
  const [min, setMin] = useState(Number.MIN_VALUE)
  const [max, setMax] = useState(Number.MAX_VALUE)
  const [errorUI, seterrorUI] = useState(false);
  const { data, loading, error } = useQuery(gql`
  query getFacility($id: ID!) {
      getFacility(id: $id) {
          fireBlight {
            riskHistory {
                  date
                  minTemp
                  maxTemp
              }
              risk {
                date
                minTemp
                maxTemp
            }
          }
      }
  }`, {
    variables: { id: facilityId },
  })


  if (error) console.log(error.message);

  useEffect(() => {
    try {
      if (data && data.getFacility) {
        let { risk, riskHistory } = data.getFacility.fireBlight;
        settemperatureData(generateChartData([risk[0], ...riskHistory], temperatureUnit, filterDays))
      }
    } catch {
      seterrorUI(true)
    }
  }, [data, filterDays, temperatureUnit])

  const generateChartData = (temperatureData, tempUnit, filterDays) => {
    let chartdata = [];
    let dateFrom = new Date(new Date().setDate(new Date().getDate() - filterDays));
    temperatureData.filter(eachdate => {
      return new Date(eachdate.date) > dateFrom
    }).map((d, i) => {
      chartdata.push({
        date: d.date,
        min: tempConversion(parseFloat(d.minTemp)).toFixed(1),
        max: tempConversion(parseFloat(d.maxTemp)).toFixed(1)
      });
    })
    setMin(Math.min.apply(Math, chartdata.map(each => parseFloat(each.min)).filter(each => !isNaN(each))) - 5);
    setMax(Math.max.apply(Math, chartdata.map(each => parseFloat(each.max)).filter(each => !isNaN(each))) + 5);
    return utls.sortByDate(chartdata, false);
  }

  const tempConversion = (temp) => {
    return temperatureUnit == "F" ? temp : ((temp - 32)*5)/9 
  }

  return (
    <Panel md={6} lg={6} xl={6} xs={12} title="High & Low Temperature" loading={loading || error || errorUI}>
      <MinMaxTempChart MinMaxData={temperatureData} Unit={temperatureUnit} Min={min} Max={max} />
    </Panel>
  )
}


export default MinMaxTemp
