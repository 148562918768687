import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import { capitalCase } from "change-case";
import * as Actions from './Davis.graphql'

const WeatherTable = ({ startDate, endDate }) => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [noData, setNoData] = useState(false);

    const { data, loading, error, refetch } = useQuery(Actions.GET_CURRENTCONDITIONS_BY_ZONE, {
        variables: { id: 15, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') },
    })

    useEffect(() => {
        try {
            if (data && data.getCurrentConditionsByZone) {
                if (data.getCurrentConditionsByZone.length == 0) {
                    setNoData(true);
                }
                else {
                    setNoData(false);
                    setTable({ columns: parseColumns(), data: data.getCurrentConditionsByZone });
                }
            }
        }
        catch{
            seterrorUI(true);
        }
    }, [data])

    useEffect(() => {
        try {
            if (startDate && endDate) {
                refetch();
            }
        }
        catch{
            seterrorUI(true);
        }
    }, [startDate, endDate])




    const parseColumns = () => {
        let columns = []
        let objKeys = data.getCurrentConditionsByZone[0];
        Object.keys(objKeys).forEach(eachKey => {
            columns.push({ title: capitalCase(eachKey), field: eachKey, editable: "never", sorting: false });
        })
        return columns;
    }
    return (
        <Panel title="Weather conditions" md={12} lg={12} xl={12} xs={12} displayCollapse={false} loading={loading || error || errorUI}>
            <div style={{ overflowY: "auto", width: "100%", height: "400px" }}>
                {
                    noData ? <div align='center'> No Data</div> :
                        <MaterialTable
                            options={{
                                search: false,
                                padding: "default",
                                headerStyle: { backgroundColor: '#ada9a8', padding: '10px', minWidth: "100px" },
                                exportButton: true,
                                exportAllData: true,
                                paginationType: "stepped",
                                paging: false,
                                showTitle: false,
                                showEmptyDataSourceMessage: true
                            }}
                            columns={table['columns']}
                            data={table['data']}
                        />
                }
            </div>
        </Panel>
    )
}

export default WeatherTable
