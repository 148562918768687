import React, { useState } from 'react'
import PageView from 'shared/components/PageView'
import BuildingsList from './Components/BuildingsList'

const Buildings = () => {
  return (
    <PageView trail={['Smart Things', 'Buildings']}>
      <BuildingsList />
    </PageView>
  )
}
export default Buildings
