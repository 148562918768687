import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import PageView from 'shared/components/PageView'
import CardLink from 'shared/components/CardLink'

const SmartBuildingCards = () => {
    return (
        <PageView trail={['Smart Building']}>
            <Row>
                <Col xl={3} md={3} xs={12}>
                    <CardLink title={'Power-Billing'} url={'/smartbuilding/powerbilling'}>
                    </CardLink>
                </Col>
            </Row>
        </PageView>
    )
}
export default SmartBuildingCards
