import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import FacilityList from './components/FacilityList'

const Facilities = () => {
  return (
    <Container className="dashboard">
      <Row>
        <FacilityList />
      </Row>
    </Container>
  )
}

export default Facilities