import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'reactstrap'
import { withSize } from 'react-sizeme'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useRouteMatch } from 'react-router-dom'
import * as Actions from './UnitDashboard.graphql'
import { isEqual } from 'lodash'
import Thermostat from './Thermostat'
import Bulb from './Bulb'
import Lock from './Lock'
import Relay from './Relay'

const SmartDevices = ({ size }) => {
    let match = useRouteMatch();
    let zoneID = match.params.UnitId;
    const [devices, setDevices] = useState([]);
    const [smartThings, setSmartThings] = useState([]);
    const [errorUI, setErrorUI] = useState(false);
    const { data, loading, error, refetch } = useQuery(Actions.GET_ZONE, {
        variables: { id: zoneID },
        fetchPolicy: 'no-cache',
        pollInterval: 60000
    });

    const { data: smartThingsData, loading: smartThingsLoading, error: smartThingsError, refetch: smartThingsRefetch } = useQuery(Actions.GET_ALL_SMARTTHINGS,
        { fetchPolicy: 'no-cache', pollInterval: 60000 });


    function useDeepEffect(fn, deps) {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) =>
                isEqual(obj, deps[index])
            );

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        }, deps);
    }


    useDeepEffect(() => {
        try {
            if (data && data.getZone.devices.smartthings) {
                setDevices(data.getZone.devices.smartthings.map(device => device.serial));
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [data])

    useDeepEffect(() => {
        try {
            if (smartThingsData && smartThingsData.getAllSmartThings && smartThingsData.getAllSmartThings.length > 0) {
                setSmartThings(smartThingsData.getAllSmartThings);
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [smartThingsData])




    return (
        errorUI || error || loading || smartThingsError || smartThingsLoading ? '' :
            <Row>
                {
                    smartThings.map((device, i) => {
                        return (
                            devices.includes(device.id) ?
                                <React.Fragment>
                                    <Col md={6} xl={3} lg={3} sm={6} xs={6}>
                                        {
                                            device.type == 'switch' ?
                                                <Bulb device={device} screensize={size} refetch={refetch} smartThingsRefetch={smartThingsRefetch} /> :
                                                device.type == 'lock' ?
                                                    <Lock device={device} screensize={size} refetch={refetch} smartThingsRefetch={smartThingsRefetch} /> :
                                                    device.type == 'relay' ?
                                                        <Relay device={device} screensize={size} refetch={refetch} smartThingsRefetch={smartThingsRefetch} /> :
                                                        device.type == 'thermostat' ?
                                                            <React.Fragment>
                                                                <Thermostat id={i} device={device} screensize={size} refetch={refetch} smartThingsRefetch={smartThingsRefetch} />
                                                            </React.Fragment> : ''
                                        }

                                    </Col>
                                </React.Fragment> : ''
                        )
                    })
                }
            </Row>
    )
}


export default withSize()(SmartDevices)