import React, { useState, useEffect } from 'react'
import Panel from '../../../../shared/components/Panel'
import { useQuery } from '@apollo/react-hooks'
import RISK_HISTORY_QUERY from './RiskHistory.graphql'
import RiskHistoryChart from '../../../../shared/components/Dashboard/RiskHistoryChart'

const RiskHistory = ({ facilityId }) => {
  const [riskHistoryData, setriskHistoryData] = useState([]);
  const [errorUI, seterrorUI] = useState(false);
  const { data, loading, error } = useQuery(RISK_HISTORY_QUERY, {
    variables: { id: facilityId },
  })
  if (error) console.log(error.message);

  useEffect(() => {
    try {
        if (data && data.getFacility) {
          setriskHistoryData(data.getFacility.fireBlight.riskHistory)
        }
    } catch {
        seterrorUI(true)
    }
}, [data])


  return (
    <Panel md={6} lg={6} xl={5} title={'Risk History'} loading={loading || error || errorUI}>
      <RiskHistoryChart chartData={riskHistoryData} />
    </Panel>
  )
}
export default RiskHistory
