import React, { useState, useEffect } from 'react'
import Panel from '../../../../shared/components/Panel'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Map from '../../../../shared/components/Dashboard/Map'

const MapEquipment = ({ facilityId }) => {
  const [MapData, setMapData] = useState([])
  const [errorUI, seterrorUI] = useState(false)
  const { data, loading, error } = useQuery(
    gql`
      query getFacility($id: ID!) {
        getFacility(id: $id) {
          zones {
            devices {
              bewhere {
                data{
                  temperature
                  pressure
                  humidity
                  battery_level
                }
                name
                latitude
                longitude
                imsi
                sim
                active
              }
            }
            fireBlight {
              risk {
                level
              }
            }
          }
        }
      }
    `,
    {
      variables: { id: facilityId },
    }
  )
  if (error) console.log(error.message)
  useEffect(() => {
    try {
      if (data && data.getFacility) {
        setMapData(data.getFacility.zones)
      }
    } catch {
      seterrorUI(true)
    }
  }, [data])

  return (
    <Panel md={6} lg={6} xl={6} xs={12} title="Device Locations" loading={loading || error || errorUI}>
      <Map Mapdata={MapData} checkError={e => seterrorUI(e)} />
    </Panel>
  )
}

export default MapEquipment
