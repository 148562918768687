import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import gql from 'graphql-tag'
import { NotificationManager } from 'react-notifications';

const ThermostatDefaultPolicy = () => {

    const GET_DEFAULT_POLICIES = gql`                    
                                query GET_DEFAULT_POLICIES{
                                    getDefaultThermostatPolicies{
                                    id
                                    name
                                    heating_temp_max
                                    cooling_temp_min
                                    }
                                }`

    const CREATE_DEFAULT_POLICY = gql`
                                mutation CRE_DTP($input: ThermostatDefaultPolicyInput!){
                                    createDefaultThermostatPolicy(input: $input)
                                }`

    const UPDATE_DEFAULT_POLICY = gql`
    mutation UPD_DTP($id: ID!, $input: ThermostatDefaultPolicyInput!){
        updateDefaultThermostatPolicy(id: $id, input: $input)
    }`

    const DELETE_DEFAULT_POLICY = gql`
    mutation DEL_DTP($id: ID!){
        deleteDefaultThermostatPolicy(id: $id)
      }
    `

    let columns = [
        { title: 'id', field: 'id', editable: 'never', hidden: true },
        { title: '*Name', field: 'name'},
        { title: '*Max temperature (°C)', field: 'heating_temp_max'},
        { title: '*Min temperature (°C)', field: 'cooling_temp_min'}
    ]
    const [policies, setPolicies] = useState([]);
    const { data, loading, error, refetch } = useQuery(GET_DEFAULT_POLICIES, { fetchPolicy: "network-only" })
    let [createPolicy, { data: createPolicyResponse, error: createPolicyError, loading: createPolicyLoading }] = useMutation(CREATE_DEFAULT_POLICY);
    let [updatePolicy, { data: updatePolicyResponse, error: updatePolicyError, loading: updatePolicyLoading }] = useMutation(UPDATE_DEFAULT_POLICY);
    let [deletePolicy, { data: deletePolicyResponse, error: deletePolicyError, loading: deletePolicyLoading }] = useMutation(DELETE_DEFAULT_POLICY);

    useEffect(() => {
        if(data){
            let defaultPolices = data.getDefaultThermostatPolicies
            setPolicies(defaultPolices)
        }

    }, [data])

    useEffect(() => {

        if(createPolicyError){
            NotificationManager.error(modifyErrorMessage(createPolicyError.message), "THERMOSTAT DEFAULT POLICY", 3000)
        }

        if(updatePolicyError){
            NotificationManager.error(modifyErrorMessage(updatePolicyError.message), "THERMOSTAT DEFAULT POLICY", 3000)
        }

    }, [createPolicyError, updatePolicyError])

    const modifyErrorMessage = (message) => {
        message = message.replace(/GraphQL error:/, '')
        return message
    }

    if (loading) return 'Loading'
    if (error) return 'error'

    return (
        <React.Fragment>
            <MaterialTable
                    options={{
                        addRowPosition: "first"
                    }}
                    title = "Thermostat Default Policies"
                    columns={columns}
                    data={policies}
                    editable={{
                        onRowAdd : async(newData) =>{
                            let data = {
                                name: newData.name,
                                heating_temp_max: parseFloat(newData.heating_temp_max),
                                cooling_temp_min: parseFloat(newData.cooling_temp_min)
                            }
                            await createPolicy({
                                variables: {
                                    input: data
                                }
                            })
                            refetch()
                        },
                        onRowUpdate: async(newData, oldData) => {
                            let data = {
                                name: newData.name,
                                heating_temp_max: parseFloat(newData.heating_temp_max),
                                cooling_temp_min: parseFloat(newData.cooling_temp_min)
                            }
                            await updatePolicy({
                                variables: {
                                    id: oldData.id,
                                    input: data
                                }
                            })
                            refetch()
                        },
                        onRowDelete: async(oldData) => {
                            await deletePolicy({
                                variables: {
                                    id: oldData.id
                                }
                            })
                            refetch()
                        } 
                    }}
                >

                </MaterialTable>
        </React.Fragment>
    )
}

export default ThermostatDefaultPolicy