import React, { useState, useEffect, useRef } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import classnames from 'classnames'
import EnableNotification from './EnableNotification'
import { isEqual } from 'lodash'

const AssignedClients = ({ clients, ZoneDescription, dragStyle, callback }) => {
    const [activeTab, setActiveTab] = useState(null);
    const [email, setEmail] = useState('');
    const [clientId, setClientId] = useState(null);
    const [status, setStatus] = useState(false);
    const [enabled, setEnabled] = useState(false);
    const [errorUI, setErrorUI] = useState(false);

    function useDeepEffect(fn, deps) {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) =>
                isEqual(obj, deps[index])
            );

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        }, deps);
    }

    useDeepEffect(() => {
        try {
            if (clients.length > 0 && clients[0].id && !isActiveTabValid()) {
                setActiveTab(clients[0].id)
            }
        } catch {
            setErrorUI(true);
        }
    }, [clients])

    useDeepEffect(() => {
        try {
            if (activeTab && clients.length > 0) {
                let activeClient = clients.filter(client => client.id == activeTab)[0] || clients[0];
                setEmail(activeClient.email);
                setEnabled(activeClient.enabled);
                setClientId(activeClient.id);
                setStatus(activeClient.active);
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [activeTab, clients])

    const isActiveTabValid = () => {
        return clients.find(client => {
            return client.id === activeTab;
        })
    }


    if (errorUI) {
        return ''
    }

    return (
        <React.Fragment>
            <div className="tabs tabs--justify tabs--bordered-bottom">
                <div className="tabs__wrap">
                    <Nav tabs>
                        {clients.map(client => {
                            return (
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === client['id'] })}
                                        onClick={() => {
                                            setActiveTab(client['id'])
                                        }}
                                    >
                                        {client['name']}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>
                    <div style={{ justifyContent: "center", display: "flex", marginTop: "5px" }}>
                        <EnableNotification clientId={clientId} email={email} checked={enabled} status={status} setEmailProp={setEmail} setCheckedProp={setEnabled} callback={callback} />
                    </div>
                    <TabContent activeTab={activeTab}>
                        {clients.map(client => {
                            return (
                                <TabPane tabId={client['id']}>
                                    <div
                                        style={{
                                            backgroundColor: 'lightgrey',
                                            padding: '20px',
                                            width: '600px',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    >
                                        <Droppable droppableId={client['id']}>
                                            {provided => (
                                                <div style={{ minHeight: '50px' }} ref={provided.innerRef} {...provided.droppableProps}>
                                                    {client.zones.map((zone, index) => {
                                                        return (
                                                            <Draggable key={zone.id} draggableId={zone.id} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={dragStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                    >
                                                                        <ZoneDescription zone={zone} />
                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                </TabPane>
                            )
                        })}
                    </TabContent>
                </div>
            </div>
        </React.Fragment>
    )
}

export default AssignedClients