import React from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'

//vvv hex to filter values vvv: https://codepen.io/sosuke/pen/Pjoqqp
const Icon = ({ icon }) => {
  if (icon.endsWith('.svg')) {
    return (
      <div
        style={{
          position: 'relative',
          top: '-2px',
          height: '15px',
          width: '15px',
          alignItems: 'center',
          filter: 'invert(42%) sepia(16%) saturate(1660%) hue-rotate(153deg) brightness(90%) contrast(91%)',
        }}
      >
        <SVG height="15px" width="15px" src={`img/${icon}`} />
      </div>
    )
  } else if (icon) {
    return <span className={`sidebar__link-icon lnr lnr-${icon}`} />
  } else {
    return ''
  }
}

const SidebarLink = ({ title, icon, newLink, route, onClick }) => (
  <NavLink to={route} onClick={onClick} activeClassName="sidebar__link-active">
    <li className="sidebar__link">
      <Icon icon={icon} />
      <p className="sidebar__link-title">
        {title}
        {newLink ? (
          <Badge className="sidebar__link-badge">
            <span>New</span>
          </Badge>
        ) : (
          ''
        )}
      </p>
    </li>
  </NavLink>
)

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
}

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
}

export default SidebarLink
