import React, { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonToolbar, ButtonGroup, Button } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { withSize } from 'react-sizeme'
import { useApolloClient } from "@apollo/react-hooks";


const FacilityFilter = ({ size, selectedFacility }) => {
    const appoloClient = useApolloClient();
    const [alignMobile, setalignMobile] = useState('flex-end')
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState([null, ""]);
    const { data, loading, error } = useQuery(gql`
    query {
        getLocations {
          facilities{
            id,
            name
          }
        }
      }`)
    useEffect(() => {
        if (data && data.getLocations) {
            setFacilities(data.getLocations[0].facilities)
        }
    }, [data])

    useEffect(() => {
        if (facilities.length > 0) {
            setFacility([facilities[0].id, facilities[0].name])
            selectedFacility(facilities[0].id);
        }
    }, [facilities])


    useEffect(() => {
        if (size.width <= 450) {
            setalignMobile('flex-start')
        } else {
            setalignMobile('flex-end')
        }
    }, [size])

    return (
        error || loading ? '' :
            facilities.length > 0 ?
                <ButtonToolbar style={{ justifyContent: 'center' }}>
                    <UncontrolledDropdown>
                        <ButtonGroup>
                            <Button color="primary" outline>
                                {facility[1]}{' '}
                            </Button>
                            <DropdownToggle color="primary" className="icon icon--right">
                                <ChevronDownIcon />
                            </DropdownToggle>
                        </ButtonGroup>
                        <DropdownMenu right className="dropdown__menu">
                            {facilities.map(facility => {
                                return (
                                    <DropdownItem id={facility.id} key={facility.id} onClick={(e) => {
                                        setFacility([e.currentTarget.getAttribute("id"), e.currentTarget.textContent])
                                    }}>
                                        {facility.name}{' '}
                                    </DropdownItem>
                                )
                            })}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </ButtonToolbar>
                : ''
    );
}

export default withSize()(FacilityFilter)

