import React from 'react'
import { useEffect } from 'react'
import JSMpeg from 'jsmpeg';
import PageView from 'shared/components/PageView'
import Panel from 'shared/components/Panel'

const Video = () => {
    var canvas = document.getElementById('video-canvas');
    var url = new WebSocket('ws://localhost:9999');
    var player = new JSMpeg(url, { canvas: canvas, audio: true, autoplay: true });


    return (
        <PageView trail={['Car Count Camera']}>
            <Panel md={12} lg={12} xl={12} xs={12} >
                <canvas id="video-canvas" />
            </Panel>
        </PageView>
    )
}
export default Video
