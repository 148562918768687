import React, { useEffect, useState } from 'react';
import Panel from '../../../../shared/components/Panel'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { Row, Col } from 'reactstrap'
import GaugeChart from 'react-gauge-chart'
import moment from 'moment'

const TodayRisk = ({ facilityId, temperatureUnit }) => {
    const [timeStamp, setTimeStamp] = useState(null)
    const [minTemp, setMinTemp] = useState(null)
    const [maxTemp, setMaxTemp] = useState(null)
    const [risklevel, setRiskLevel] = useState(null)
    const [errorUI, setErrorUI] = useState(false)
    const { data, loading, error } = useQuery(gql`
    query getFacility($id: ID!) {
        getFacility(id: $id) {
            fireBlight {
                risk {
                    date
                    dayOfWeek
                    icon
                    minTemp
                    maxTemp
                    level
                }
            }
            zones{
                devices{
                    bewhere{
                        data{
                            temperature,
                            timestamp
                        }
                }
            }
              }
        }
    }`, {
        variables: { id: facilityId },
    })
    useEffect(() => {
        try {
            if (data && data.getFacility) {
                setMinTemp(data.getFacility.fireBlight.risk[0].minTemp);
                setMaxTemp(data.getFacility.fireBlight.risk[0].maxTemp);
                setTimeStamp(moment(data.getFacility.zones[0].devices.bewhere[0].data[0].timestamp + "000", "x").format("DD MMM YYYY hh:mm a"))
                setRiskLevel(generateChartData(data.getFacility.fireBlight.risk[0].level));
            }
        } catch {
            setErrorUI(true)
        }
    }, [data])

    const tempConversion = (temp) => {
        return temperatureUnit == "F" ? temp : ((temp - 32)*5)/9 
    }
    
    return (
        <Panel className="dashboard__align_text_center" md={12} xl={2} lg={6} sm={12} xs={12}
            title={`Today Risk Forecast`} loading={loading || error || errorUI}>
            <React.Fragment>
                <div style={{ marginTop: "44px" }}>
                    <GaugeChart
                        hideText={true}
                        id={"gauge-chart_todayrisk"}
                        nrOfLevels={5} arcWidth={0.3}
                        colors={['#8BC85C', '#5BA318', '#DEE300', '#FABC04', '#EC3F0A']}
                        percent={risklevel} />
                    <Row >
                        <Col style={{ padding: "11px", color: '#ff0000', display: 'flex', justifyContent: "center", fontSize: "larger", fontWeight: "initial" }}>
                            {tempConversion(parseFloat(maxTemp)).toFixed(1)}{`°${temperatureUnit}`}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ padding: "11px", color: '#0000ff', display: 'flex', justifyContent: "center", fontSize: "larger" }}>
                            {tempConversion(parseFloat(minTemp)).toFixed(1)}{`°${temperatureUnit}`}
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        </Panel>
    )
}

const generateChartData = (element) => {
    if (element == 'Low')
        return 0.3;
    else if (element == 'Medium')
        return 0.5;
    else if (element == 'High')
        return 0.7;
    else if (element == 'Infection')
        return 0.9;
    else
        return 0.1;
}

export default TodayRisk
