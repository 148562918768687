import React, { useState, useEffect } from 'react'
import LogInForm from './Components/LogInForm'
import { client } from 'shared/graphql/authentication'
import styles from './login.module.scss'

const LogIn = () => {
  useEffect(() => {
    client.resetStore()
  }, [])

  const [isShake, shakeCard] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('')

  useEffect(() => {
    client.clearStore()
  }, [])

  const handleLoginErrMsg = (error) => {
    let message = error.message;
    if (message.includes('Account status is Inactive')) {
      setLoginErrorMsg('Main account is inactive. Please contact administrator')
    }
    if (message.includes('User status is Inactive')) {
      setLoginErrorMsg('Your Account is inactive. Please contact administrator')
    }
  }

  return (
    <div className={styles.account}>
      <div className={styles.account__wrapper}>
        <div className={styles.account__pattrern}>
          <img src="/img/loginpattern.svg"></img>
        </div>
        <div className={isShake ? styles.invalid__login : ''}>
          <div className={styles.account__card}>
            <div className={styles.account__head}>
              <h3 className={styles.account__title}>
                Welcome to One Smart View
                <span className={styles.topbar__logo}></span>
              </h3>
            </div>
            <LogInForm
              loginError={(error) => {
                handleLoginErrMsg(error)
                shakeCard(true)
                setTimeout(() => {
                  shakeCard(false)
                }, 300)
              }}
            />
          </div>
        </div>
        {
          loginErrorMsg ? <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
            <p style={{ color: "red", fontSize: "larger", fontWeight: "400", backgroundColor: "white" }}>{loginErrorMsg}</p>
          </div> : ''
        }
      </div>
    </div>
  )
}

export default LogIn
