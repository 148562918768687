import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import styles from './BuildingsList.module.scss'
import PowerPlugIcon from 'mdi-react/PowerPlugIcon'
import CctvIcon from 'mdi-react/CctvIcon'
import SecurityIcon from 'mdi-react/SecurityIcon'
import AlarmLightIcon from 'mdi-react/AlarmLightIcon'
import FlashIcon from 'mdi-react/FlashIcon'
import NoteIcon from 'mdi-react/NoteIcon'
import CardLink from 'shared/components/CardLink'
import * as Actions from './BuildingsList.graphql'
import Panel from 'shared/components/Panel.jsx'

const Buildings = () => {
    const [errorUI, setErrorUI] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const { data, loading, error } = useQuery(Actions.GET_FACILITIES);

    useEffect(() => {
        try {
            if (data && data.getFacilities) {
                setFacilities(data.getFacilities);
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [data])
    return (
        (error || errorUI || loading) ? '' :
            <React.Fragment>
                <Row>
                    {
                        facilities.map(facility => {
                            return <FacilityComponenet facility={facility} />
                        })
                    }
                </Row>
            </React.Fragment>
    )
}

const FacilityComponenet = ({ facility }) => {
    return (
        <Col md={12} xl={6} lg={12} xs={12}>
            <CardLink url={'/' + facility.id} title={facility.name}>
                <hr />
                <Row>
                    <Col md={12} xl={6} lg={12} xs={12}>
                        {' '}
                        <h3>{facility.address} </h3>
                    </Col>
                    <Col md={12} xl={6} lg={12} xs={12}>
                        {' '}
                        {
                            facility.products.map(product => {
                                return (
                                    <React.Fragment>
                                        <PowerPlugIcon className={styles['dashboard_icon']} />
                                        {product.name} <br />
                                    </React.Fragment>
                                )
                            })
                        }
                    </Col>
                </Row>
            </CardLink>
        </Col>
    )
}

export default Buildings
