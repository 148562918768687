import React from 'react';
// import {
//     AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
// } from 'recharts';
import moment from 'moment'

import { Line } from 'react-chartjs-2';

// const EIPChart = ({ chartData }) => {
//     return (
//             <ResponsiveContainer height={250} className="dashboard__area">
//                 <AreaChart data={chartData} margin={{ top: 20, left: -15, bottom: 20 }}>
//                     <XAxis dataKey="date" tick={{ fontSize: 10 }} tickFormatter={(unixTime) => moment(unixTime).utc().format('L')} />
//                     <YAxis tickLine={true} domain={[-10, 200]} />
//                     <Tooltip />
//                     <Legend />
//                     <CartesianGrid />
//                     <Area name="EIP" type="monotone" dataKey="EIP" fill="#ff3c00" stroke="#ff3c00" fillOpacity={0.3} />
//                 </AreaChart>
//             </ResponsiveContainer>
//     )
// }

const EIPChart = ({ eipData, Min, Max }) => {
  const chartData = {
    labels: eipData.map(d => moment(d.date).format('MMM DD')),
    datasets: [{
      label: "EIP",
      data: eipData.map(d => d.EIP),
      fill: 'none',
      borderColor: '#0000ff',
      borderWidth: 1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      // lineTension: 0.1
    }]
  }

  const options = {
    legend: {
      display: true,
      position: 'bottom'
    },
    scales: {
      yAxes: [{
        gridLines: {
          display: true,
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = ''
          label += Math.round(tooltipItem.yLabel * 100) / 100;
          return label;
        }
      }
    }
  };

  return (
    <Line data={chartData} options={options} height="100vh" />
  )
}
export default EIPChart
