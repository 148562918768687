import gql from 'graphql-tag'

export const GET_FACILITIES = gql`
    query {
        getFacilities {
          id,
          name,
          address
          products{
            id,
            name,
            suite,
          }
        }
    }`
