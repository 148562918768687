import React from 'react'
import Panel from '../../../../../shared/components/Panel'
import { useRouteMatch } from 'react-router-dom'
import Bills from './Bills'
import SmartDevices from './SmartDevices'
import { SizeMe } from 'react-sizeme'
import PageView from 'shared/components/PageView'
import ThermostatZonePolicies from './ThermostatZonePolicies'


const Dashboard = () => {
    let match = useRouteMatch()
    let urlSplit = match.url.split('/')
    let buildingId = urlSplit[urlSplit.length - 2]
    return (
        <PageView trail={['Smart Things', 'Buildings', 'Units', 'Devices']}>
            <React.Fragment>
            {
                buildingId == 2 ?
                    (
                        <Panel md={12} xl={12} lg={12} xs={12}>
                            <div className="card__title">
                                <h5 className="bold-text">Billing</h5>
                            </div>
                            <Bills />
                        </Panel>
                    ) : (
                        <Panel md={12} xl={12} lg={12} xs={12}>
                            <SizeMe>
                                {({ size }) => <SmartDevices />}
                            </SizeMe>
                        </Panel>
                    )
            }
            </React.Fragment>
            <React.Fragment>
                <Panel md={12} xl={12} lg={12} xs={12}>
                  <ThermostatZonePolicies/>
                </Panel>
            </React.Fragment>
        </PageView>
    )
}

export default Dashboard