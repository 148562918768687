import React from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useHistory, useRouteMatch } from 'react-router-dom'
import CardLink from '../../../../../shared/components/CardLink'
import { List } from 'react-content-loader'

const LocationList = () => {
    const { data, loading, error } = useQuery(gql`
    query {
        getLocations {
          id,
          name,
          city,
          province,
          latitude,
          longitude
          facilities{
            name
          }
        }
      }`)
    if (error) console.log(error);
    if (loading) return <List /> 
    let locationsData;
    if (data) {
        locationsData = data.getLocations;
    }
    let history = useHistory()
    let match = useRouteMatch()
    return (
        <Row>
            {
                locationsData && locationsData.length > 0 ?
                    locationsData.length > 1 ?
                        locationsData.map(location => {
                            return <Col md={12} xl={4} lg={6} xs={12}>
                                <CardLink title={location.name} url={'/' + location.id}>
                                    <ul >
                                        <li>{location.city}</li>
                                        <li>{location.province}</li>
                                    </ul>
                                </CardLink>
                            </Col>
                        }) : history.push(`${match.path}${'/' + locationsData[0].id}`) : <div style={{ marginTop: "30px", marginLeft: "30px", fontSize: "30px" }}>
                        No Locations Registered
                            </div>
            }
        </Row>
    )
}

export default LocationList
