import React, { useState, useEffect } from 'react'
import PowerConsumptionInterval from './components/PowerConsumptionInterval'
import DailyPowerConsumption from './components/DailyPowerConsumption'
import { Row } from 'reactstrap'
import PageView from 'shared/components/PageView'
import Filter from './components/Filter'

const PowerUsage = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [clientId, setclientId] = useState();

  const getStartDate = (date) => {
    setStartDate(date)
  }
  const getEndDate = (date) => {
    setEndDate(date)
  }

  const getClient = (days) => {
    setclientId(days)
  }


  return (
    <PageView trail={['Power Billing', 'Power Usage']}>
      <Filter callbackStartDate={getStartDate} callbackEndDate={getEndDate} clientSelected={getClient} />
      <Row>
        <DailyPowerConsumption clientId={clientId} startDate={startDate} endDate={endDate} />
      </Row>
      <Row>
        <PowerConsumptionInterval clientId={clientId} />
      </Row>
    </PageView>
  )
}
export default PowerUsage
