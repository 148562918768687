import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from '../../../../shared/components/Panel'
import * as Actions from "./InputTable.graphql"
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as utls from 'shared/utls/index.js'
import moment from 'moment'

const InputTable = ({ facilityId, temperatureUnit }) => {
  const [table, setTable] = useState([]);
  const [zone, setZone] = useState("");
  const [errorUI, seterrorUI] = useState(false);
  const columns = [
    { title: 'Date', field: 'date', editable: 'never' },
    { title: `Max & Min Temp (°${temperatureUnit})`, field: 'temp', editable: 'never' },
    { title: 'Wetness', field: 'wetness', editable: 'never' },
    { title: 'Risk', field: 'level', editable: 'never' },
    { title: 'EIP', field: 'EIP', editable: 'never' },
    { title: 'BBS', field: 'BBS', editable: 'never' },
    { title: 'CBS', field: 'CBS', editable: 'never' },
    { title: 'SBS', field: 'SBS', editable: 'never' },
    { title: 'TBS', field: 'TBS', editable: 'never' },
    {
      title: 'Phenology', field: 'phenology',
      lookup: {
        "-": "-", "D": "Dormant", "ST": "Silver tip", "AG": "AG", "PG": "PG", "GT": "Green Tip", "TC": "Tight cluster", "OC": "Open cluster",
        "P": "Pink", "B": "Bloom", "PF": "Petal fall", "PB": "Post bloom", "1C": "1C", "2C": "2C"
      },
    },
    {
      title: 'Trauma', field: 'trauma',
      lookup: {
        "-": "-", "H": "Hail", "W": "High Wind", "F": "Frost"
      },
    },
    {
      title: 'Spray', field: 'spray',
      lookup: {
        "yes": "Yes", "no": "No"
      }
    }
  ]

  const { data: fb_data, loading: fb_loading, error: fb_error, refetch } = useQuery(Actions.FIREBLIGHT_QUERY, {
    variables: { id: facilityId }, fetchPolicy: "no-cache"
  })

  const { data: zones_data, loading: zones_loading, error: zones_error } = useQuery(Actions.GET_ZONES, {
    variables: { id: facilityId }, fetchPolicy: "no-cache"
  })

  const [createPhenology, { createPhenologyData }] = useMutation(Actions.CREATE_PHENOLOGY_EVENT);
  const [editPhenology, { editPhenologyData }] = useMutation(Actions.EDIT_PHENOLOGY_EVENT);
  const [deletePhenology, { deletePhenologyData }] = useMutation(Actions.DELETE_PHENOLOGY_EVENT);
  const [createTrauma, { createTraumaData }] = useMutation(Actions.CREATE_TRAUMA_EVENT);
  const [editTrauma, { editTraumaData }] = useMutation(Actions.EDIT_TRAUMA_EVENT);
  const [deleteTrauma, { deleteTraumaData }] = useMutation(Actions.DELETE_TRAUMA_EVENT);
  const [deleteSpray, { editSprayData }] = useMutation(Actions.DELETE_SPRAY_EVENT);
  const [createSpray, { createSprayData }] = useMutation(Actions.CREATE_SPRAY_EVENT);



  useEffect(() => {
    try {
      if (fb_data) {
        let { risk, riskHistory, events } = fb_data.getFireBlightByFacility;
        setTable(getTableData(risk, riskHistory, events));
      }
    }
    catch (e) {
      if (!errorUI) {
        seterrorUI(true);
      }
      console.log(e);
    }
  }, [fb_data, temperatureUnit])


  useEffect(() => {
    try {
      if (zones_data) {
        setZone(zones_data.getFacility.zones[0].id);
      }
    }
    catch{
      if (!errorUI) {
        seterrorUI(true);
      }
    }
  }, [zones_data])

  if (fb_error) console.log(fb_error);


  const handleSubmitRow = async (newData, oldData) => {
    try {
      if (newData.phenology != oldData.phenology) {
        let inputs = {
          zoneId: zone,
          phenology: newData.phenology,
          date: newData.date
        }
        if (oldData.phenology == "-") {
          await createPhenology({ variables: inputs })
          refetch();
        }
        else if (newData.phenology == "-") {
          let rowFound = table.find(tableRow => {
            return tableRow.date == newData.date
          })
          let phenId = { id: parseInt(rowFound.phenologyId) }
          await deletePhenology({ variables: phenId })
          refetch();
        }
        else {
          let rowFound = table.find(tableRow => {
            return tableRow.date == newData.date
          })
          inputs["id"] = rowFound.phenologyId;
          await editPhenology({ variables: inputs })
          refetch();
        }
      }
      if (newData.trauma != oldData.trauma) {
        let inputs = {
          zoneId: zone,
          trauma: newData.trauma,
          date: newData.date
        }
        if (oldData.trauma == "-") {
          await createTrauma({ variables: inputs })
          refetch();
        }
        else if (newData.trauma == "-") {
          let rowFound = table.find(tableRow => {
            return tableRow.date == newData.date
          })
          let traumaId = { id: parseInt(rowFound.traumaId) }
          await deleteTrauma({ variables: traumaId })
          refetch();
        }
        else {
          let rowFound = table.find(tableRow => {
            return tableRow.date == newData.date
          })
          inputs["id"] = rowFound.traumaId;
          await editTrauma({ variables: inputs })
          refetch();
        }
      }
      if (newData.spray != oldData.spray) {
        let inputs = {
          zoneId: zone,
          spray: newData.spray,
          date: newData.date
        }
        if (oldData.spray == "no") {
          await createSpray({ variables: inputs })
          refetch();
        }
        else {
          let rowFound = table.find(tableRow => {
            return tableRow.date == newData.date
          })
          let sprayId = { id: parseInt(rowFound.sprayId) }
          await deleteSpray({ variables: sprayId })
          refetch();
        }
      }
    }
    catch{
      seterrorUI(true);
    }
  }

  const getTableData = (risk, riskHistory, events) => {
    try {
      let tableData = [], combinedRiskData = [];
      if (risk.length > 0 && riskHistory.length > 0) {
        combinedRiskData = ([risk[0], ...riskHistory]);
        getLastMonthsDates(3).forEach(date => {
          const { phenology, phenologyId } = events.phenology.reduce((returnPhen, currphen) => {
            if (currphen.date == date) {
              returnPhen = { phenology: currphen.value, phenologyId: currphen.id };
            }
            return returnPhen;
          }, { phenology: "-", phenologyId: "-" })
          const { spray, sprayId } = events.spray.reduce((returnSpray, currspray) => {
            if (currspray.date == date) {
              debugger;
              returnSpray = { spray: currspray.value == "YES" || "-" ? "yes" : "", sprayId: currspray.id };
            }
            return returnSpray;
          }, { spray: "no", sprayId: "-" })
          const { trauma, traumaId } = events.trauma.reduce((returnTrauma, currtrauma) => {
            if (currtrauma.date == date) {
              returnTrauma = { trauma: currtrauma.value, traumaId: currtrauma.id };
            }
            return returnTrauma;
          }, { trauma: "-", traumaId: "-" })
          let combinedRiskDataMap = combinedRiskData.reduce((riskMap, currRisk) => {
            return riskMap.set(currRisk.date, currRisk)
          }, new Map())
          let matchedRiskDate = combinedRiskDataMap.get(date) || {};
          tableData.push({
            date: date,
            wetness: matchedRiskDate.wetness || '-',
            level: matchedRiskDate.level || '-',
            EIP: matchedRiskDate.EIP || '-',
            BBS: matchedRiskDate.BBS || '-',
            SBS: matchedRiskDate.SBS || '-',
            TBS: matchedRiskDate.TBS || '-',
            CBS: matchedRiskDate.CBS || '-',
            temp: `${tempConversion(matchedRiskDate.maxTemp).toFixed(1) || '-'} / ${tempConversion(matchedRiskDate.minTemp).toFixed(1) || '-'}`,
            phenology: phenology,
            phenologyId: phenologyId,
            trauma: trauma,
            traumaId: traumaId,
            spray: spray,
            sprayId: sprayId
          })
        })
        return utls.sortByDate(tableData)
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const tempConversion = (temp) => {
    return temperatureUnit == "F" ? parseFloat(temp) : ((temp - 32)*5)/9 
  }

  const getLastMonthsDates = (months) => {
    let currentDate = moment();
    let lastDate = currentDate.clone().subtract(months, "months")
    let dates = [];
    let now = currentDate.clone();
    while (now.isSameOrAfter(lastDate)) {
      dates.push(now.format("YYYY-MM-DD"));
      now.subtract(1, 'days');
    }
    return dates;
  }



  return (
    <Panel md={12} lg={12} xl={12} xs={12} loading={fb_loading || fb_error || errorUI}>
      <MaterialTable
        options={{
          padding: "dense",
          headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
          exportButton: true,
          exportAllData: true,
          pageSize: 20
        }}
        title="Input Table"
        columns={columns}
        data={table}
        editable={{
          onRowUpdate: async (newData, oldData) => {
            await handleSubmitRow(newData, oldData);
            table[table.indexOf(oldData)] = newData
            setTable(table);
          }
        }}
      />
    </Panel>
  )
}

export default InputTable