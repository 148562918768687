import React, { useState, useEffect } from 'react'
import { Table, Col, Container, Row } from 'reactstrap'
import { useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import styles from './UnitsList.module.scss'
import NoteIcon from 'mdi-react/NoteIcon'
import PowerPlugIcon from 'mdi-react/PowerPlugIcon'
import LockOpenOutlineIcon from 'mdi-react/LockOpenOutlineIcon'
import LockOutlineIcon from 'mdi-react/LockOutlineIcon'
import SecurityIcon from 'mdi-react/SecurityIcon'
import CircleIcon from 'mdi-react/CircleIcon'
import CardLink from 'shared/components/CardLink'
import AlarmLightIcon from 'mdi-react/AlarmLightIcon'
import * as Actions from './UnitsList.graphql'
import PageView from 'shared/components/PageView'

const UnitsList = () => {
  let match = useRouteMatch()
  let selectedBuilding = match.params.BuildingId
  const [buildingName, setBuildingName] = useState([]);
  const [units, setUnits] = useState([]);
  const { data, loading, error } = useQuery(Actions.GET_FACILITY, {
    variables: { id: selectedBuilding },
  });

  useEffect(() => {
    if (data && data.getFacility.zones) {
      setBuildingName(data.getFacility.name)
      setUnits(data.getFacility.zones);
    }
  }, [data])

  if (loading) return 'Loading...'
  if (error) return error
  return (
    <PageView trail={['Smart Things','Buildings', 'Units']}>
      <Row>
        {
          units.map(unit => {
            return <UnitComponent unit={unit} facilityID={selectedBuilding} facName = {buildingName} />
          })
        }
      </Row>
    </PageView>
  )
}

const UnitComponent = ({ unit, facilityID, facName }) => {
  const devices = [...new Set(unit.devices.smartthings.map(device => device.type))];
  return (
    <Col md={12} xl={6} lg={6} xs={12}>
      <CardLink absolurl={`${facilityID}${`/${unit.id}`}`}>
        <Row>
          <Col md={12} xl={6} lg={12} xs={12}>
            {' '}
            <h4>Unit #{' '} </h4>
            <h3>{unit.name}</h3>{' '}
          </Col>
          <Col md={12} xl={6} lg={12} xs={12}>
            {' '}
            {
              devices.map(device => {
                if (device.includes('LOCK')) {
                  return (
                    <React.Fragment>
                      <LockOutlineIcon className={styles['dashboard_icon']} />
                      Doors<br />
                    </React.Fragment>
                  )
                }
                if (device.includes('SWITCH')) {
                  return (
                    <React.Fragment>
                      <AlarmLightIcon className={styles['dashboard_icon']} />
                      Lights<br />
                    </React.Fragment>
                  )
                }
              })
            }
          </Col>
        </Row>
      </CardLink>
    </Col>
  )
}

export default UnitsList