import gql from 'graphql-tag'

export const GET_METERS_FACILITY = gql`
query {
    getFacilities {
      id,
      name,
      city,
      province,
      country,
      latitude,
      longitude,
      active,
      powerBilling{
        clients{
          id,
          name,
          meters{
            id,
            name,
            status,
            daily_consumption{
              date,
              consumption
            }
          }
        }
      }
    }
  }`

export const GET_LOCATION = gql`
query {
  getLocations {
    id,
    name,
    city,
    province,
    latitude,
    longitude,
    facilities{
      postal_code,
      address,
      address_ext
      powerBilling{
        clients{
          id,
          name,
          zones{
            id,
            name,
            devices{
              ekm{
                serial,
                name,
                status{
                  status
                  last_updated_time
                }
                daily_consumption{
              		date,
              		consumption
            }
              }
            }
          }
        }
      }
    }
  }
}`