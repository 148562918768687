import React, { useState, useEffect, useCallback } from 'react'
import {
    Table,
    Col,
    FormGroup,
    Label,
    Input,
    Form,
    Card,
    CardBody,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody
}
    from 'reactstrap'
import DatePicker from "react-datepicker";
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import PageView from 'shared/components/PageView'
import CheckMark from 'shared/components/animated-checkmark/animated-checkmark'
import * as utils from 'shared/utls/index.js'
import { useApolloClient } from "@apollo/react-hooks";


const moment = require('moment')

const Bills = props => {

    let history = useHistory()
    let match = useRouteMatch()
    const client = useApolloClient();

    let pbQuery = gql`
                    query GetPowerBillingOfFacility($facilityId : Int!){
                        getPowerBillingByFacility(facility_id: $facilityId){
                            clients{
                                id
                                name
                                bills{
                                    invoice_number
                                    from_date
                                    to_date
                                    creation_date
                                    total_charges
                                }
                            }
                        }
                    }
                    `
    let facQuery = gql`
                    query GetFacilities{
                        getFacilities{
                            id
                            name
                        }
                    }
                    `
    let DELETE_INVOICE = gql`
                        mutation DeleteInvoice($invoiceNumber: String!){
                            deleteBillByInvoiceNumber(invoice_number: $invoiceNumber)
                        }
                        `
    let CREATE_INVOICE = gql`
                        mutation CreateInvoice($clientId: Int!, $startDate: String!, $endDate: String!){
                            createBillByClient(clientId: $clientId, startDate:$startDate, endDate:$endDate){
                                invoice_number
                            }
                        }
                        `
    let GET_LOCALDATA = gql`
                        query GetLocalData{
                            facilityId @client
                            clientId @client
                        }
    `

    let [facilities, setFacilities] = useState([])
    let [clients, setClients] = useState([])
    let [deleteInvoice, { data: deleteInvoiceResponse, error: deleteInvoiceError }] = useMutation(DELETE_INVOICE);
    let [createInvoice, { data: createInvoiceResponse, error: createInvoiceError, loading: createInvoiceLoading }] = useMutation(CREATE_INVOICE);
    let [startDate, setStartDate] = useState(new Date())
    let [endDate, setEndDate] = useState(new Date())
    let [modalOpen, setModalOpen] = useState(false)
    let [createResolve, setCreateResolve] = useState(false)
    let [deleteInvoiceReq, setDeleteInvoiceReq] = useState('')

    const { data: localData, refetch: localDataRefetch } = useQuery(GET_LOCALDATA)

    const { data: facs, loading: facLoading, error: facError, refetch: facRefetch } = useQuery(
        facQuery
    )
    const variables = { facilityId: parseInt(localData.facilityId) === 0 ? (facs && facs.getFacilities.length > 0) ? parseInt(facs.getFacilities[0].id) : 0 : parseInt(localData.facilityId) }
    const skip = variables.facilityId === 0

    const { data: powerBilling, loading: pbLoading, error: pbError, refetch: pbRefetch } = useQuery(
        pbQuery,
        {
            variables: variables,
            skip
        }
    )



    useEffect(() => {

        if (powerBilling) {
            let facClients = utils.sortByName(powerBilling.getPowerBillingByFacility.clients)
            if (facClients && facClients.length > 0 && localData.clientId == 0) {
                client.writeData({ data: { clientId: facClients[0].id } })
                localDataRefetch()
            }
            setClients(facClients)
        }

    }, [powerBilling])

    useEffect(() => {

        if (facs) {

            let facilities = utils.sortByName(facs.getFacilities);
            if (facilities && facilities.length > 0 && localData.facilityId == 0) {
                client.writeData({ data: { facilityId: facilities[0].id } })
                localDataRefetch()

            }
            setFacilities(facilities)
        }

    }, [facs])

    useEffect(() => {

        if (createInvoiceResponse || createInvoiceError) {
            pbRefetch()
            setCreateResolve(true)
            setTimeout(() => {
                setModalOpen(false)
                setCreateResolve(false)

            }, 3000)
        }

    }, [createInvoiceResponse, createInvoiceError])

    useEffect(() => {
        if (deleteInvoiceResponse) {

            console.log("useeffect", deleteInvoiceResponse)
            pbRefetch()
        }
    }, [deleteInvoiceResponse])

    // if (pbLoading || facLoading) return 'Loading...'
    // if (pbError) return `Error! ${pbError.message}`
    // if (facError) return `Error! ${facError.message}`
    if (pbLoading || facLoading) return ''
    if (pbError) return ''
    if (facError) return ''

    const handleFacilityChange = (facilityId) => {
        //setFacilityId(parseInt(facilityId))
        client.writeData({ data: { facilityId: facilityId } })
        localDataRefetch()
        pbRefetch()
    }

    const handleClientChange = (clientId) => {
        client.writeData({ data: { clientId: clientId } })
        localDataRefetch()
    }

    const handleDeleteInvoice = async (invoiceNumber) => {
        deleteInvoice({ variables: { invoiceNumber: invoiceNumber } })
    }

    const handleModalState = () => {
        setModalOpen(modalOpen => !modalOpen)
    }

    const handleSubmitInvoice = async () => {

        let sDate = moment(startDate).format("YYYY-MM-DD")
        let eDate = moment(endDate).format("YYYY-MM-DD")

        createInvoice({
            variables: {
                clientId: parseInt(localData.clientId),//parseInt(clientId),
                startDate: sDate,
                endDate: eDate
            }
        })
    }

    return (
        <PageView trail={['Power Billing', 'Invoices']}>
            <Card md={12} lg={12} xl={12} xs={12}>
                <CardBody>
                    <Form>
                        <FormGroup row>
                            <Label sm={2} for="facility">Facility:</Label>
                            <Col sm={2}>
                                <Input type="select" name="facilityName" id="facilityName" value={localData.facilityId} onChange={e => handleFacilityChange(e.target.value)}>
                                    {facilities.map(facility => {
                                        return <option value={facility.id}>{facility.name}</option>
                                    })}
                                </Input>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2} for="client">Client</Label>
                            <Col sm={2}>
                                <Input type="select" name="clientName" id="clientName" value={localData.clientId} onChange={e => handleClientChange(e.target.value)}>
                                    {clients.map(client => {
                                        return <option value={client.id}>{client.name}</option>
                                    })}
                                </Input>
                            </Col>
                        </FormGroup>
                    </Form>
                    <Button color="danger" onClick={handleModalState}>+</Button>
                    <Modal isOpen={modalOpen}>
                        <ModalHeader>ADD INVOICE</ModalHeader>
                        <ModalBody>
                            {
                                !createResolve ?
                                    <Form>
                                        <FormGroup row>
                                            <Label sm={4} for="startDate">From Date</Label>
                                            <Col sm={2}>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={startDate}
                                                    onChange={date => setStartDate(date)}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label sm={4} for="endDate">To Date</Label>
                                            <Col sm={2}>
                                                <DatePicker
                                                    dateFormat="yyyy-MM-dd"
                                                    selected={endDate}
                                                    minDate={startDate}
                                                    onChange={date => setEndDate(date)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Form>
                                    :
                                    <div width="100px" height="100px">
                                        <CheckMark success={createInvoiceError ? false : (createInvoiceResponse ? true : null)} />
                                        {/* <CheckMark success = {createInvoiceError ? false: true}/> */}
                                    </div>
                            }
                        </ModalBody>
                        {
                            !createResolve ?
                                <ModalFooter>
                                    <Button onClick={handleSubmitInvoice} color="primary">Submit</Button>
                                    <Button onClick={handleModalState} color="primary">Cancel</Button>
                                </ModalFooter>
                                : ""
                        }
                    </Modal>
                    <div style={{ display: "inline-block", overflowX: "auto", width: "100%" }}>
                        <Table
                            striped
                            bordered
                            size="sm"
                            style={{ textAlign: 'center', borderWidth: 'initial', borderColor: 'black' }}
                        >
                            <thead style={{ backgroundColor: 'black' }}>
                                <tr>
                                    <th style={{ color: 'white' }}>Invoice Number</th>
                                    <th style={{ color: 'white' }}>From Date</th>
                                    <th style={{ color: 'white' }}>To Date</th>
                                    <th style={{ color: 'white' }}>Creation Date</th>
                                    <th style={{ color: 'white' }}>Total charges</th>
                                    <th style={{ color: 'white' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clients.filter(client => client.id === localData.clientId).length > 0 ? clients.filter(client => client.id === localData.clientId)[0].bills.map(bill => {
                                    return (
                                        <tr>
                                            <td>{bill.invoice_number}</td>
                                            <td>{bill.from_date}</td>
                                            <td>{bill.to_date}</td>
                                            <td>{bill.creation_date}</td>
                                            <td>{bill.total_charges}</td>
                                            {/* <td>    
                                                <Link to={`${match.path}` + `/bill/${bill.invoice_number}`}>View</Link>{" | "}
                                                <Link to="#" onClick={e =>  handleDeleteInvoice(bill.invoice_number) }>Delete</Link>
                                            </td> */}
                                            <td>
                                                <Link to={`${match.path}` + `/bill/${bill.invoice_number}`}>View</Link> {" | "}
                                                <Link to="#" id="PopoverLegacy" onClick={() => { setDeleteInvoiceReq(bill.invoice_number) }}>Delete</Link>
                                                <UncontrolledPopover trigger="legacy" placement="bottom" target="PopoverLegacy">
                                                    <PopoverHeader>Confirm</PopoverHeader>
                                                    <PopoverBody>
                                                        <Button color="primary" size="sm" block onClick={e => { handleDeleteInvoice(deleteInvoiceReq) }}>Delete</Button>
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            </td>
                                        </tr>
                                    )
                                }) : ""}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </PageView>
    )
}

export default Bills