import gql from 'graphql-tag'

export const GET_ACCOUNTS = gql`
query {
    getAccounts {
      id,
      name,
      facilities{
        id,
        name,
        products{
          name
        }
      }
    }
  }`

export const GET_GATEWAYS_AND_ZONES_BY_FACILITY = gql`
query($id:ID!) {
  getFacility(id:$id) {
    id,
    name,
    gateways{
      id,
      name,
      serial,
      devices{
        davis{
          id
        }
        camera{
				id
        }
      }
    }
    zones{
      id
      name
      devices{
        davis{
          id
          serial,
          schedule
          host
        }
        camera{
          id,
          name,
          schedule,
          host
        }
      }
    }
  }
}`

export const CREATE_GATEWAY = gql`mutation createGateway($facility_id:ID!$name:String,$serial:String){
  createGateway( facility_id:$facility_id,name:$name,serial:$serial)
}`

export const CREATE_CAMERA = gql`mutation createCameraDevice($zone_id:ID,$gateway_id:ID,$name:String,$host:String, $schedule: String){
  createCameraDevice( zone_id:$zone_id, gateway_id:$gateway_id,name:$name, host:$host,schedule:$schedule)
}`

export const CREATE_DAVIS = gql`mutation createDavisDevice($zone_id:ID,$gateway_id:ID,$serial:String,$host:String, $schedule: String){
  createDavisDevice(zone_id:$zone_id, gateway_id:$gateway_id,serial:$serial, host:$host,schedule:$schedule)
}`
export const EDIT_GATEWAY = gql`mutation editGateway($id: ID! $facility_id:ID!$name:String,$serial:String){
  editGateway( id:$id, facility_id:$facility_id,name:$name,serial:$serial)
}`

export const EDIT_CAMERA = gql`mutation editCameraDevice($id:ID!,$zone_id:ID,$gateway_id:ID,$name:String,$host:String, $schedule: String){
  editCameraDevice(id: $id, zone_id:$zone_id, gateway_id:$gateway_id,name:$name, host:$host,schedule:$schedule)
}`

export const EDIT_DAVIS = gql`mutation editDavisDevice($id:ID!,$zone_id:ID,$gateway_id:ID,$serial:String,$host:String, $schedule: String){
  editDavisDevice(id: $id, zone_id:$zone_id, gateway_id:$gateway_id,serial:$serial, host:$host,schedule:$schedule)
}`