import gql from 'graphql-tag'

export const GET_PRODUCTS = gql`
query {
  getProducts {
    id,
    name
  }
}`


export const CREATE_PRODUCT = gql`
mutation createProduct(	
    $name: String!
    ){
      createProduct(name: $name){
      name
    }
  }`

export const UPDATE_PRODUCT = gql`
mutation updateProduct(	
    $id: ID!, 
    $name: String
    ){
      updateProduct( id: $id, name: $name){
      id
    }
  }`

export const DISABLE_PRODUCT = gql`
mutation disableProduct(	
    $id: ID!
    ){
      disableProduct(id: $id){
      id
    }
  }`

