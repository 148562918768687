import React from 'react'
import { Route } from 'react-router-dom'
import { Switch, useRouteMatch, Redirect } from 'react-router-dom'
import Zones from './index'


export default () => {
    let match = useRouteMatch()
    return (
        <Switch>
            <Route path={`${match.path}`} component={Zones} />
        </Switch>
    )
}


