import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Mapequipment from './components/MapEquipment.jsx'
import ForecastWeather from './components/ForecastWeather'
import EIP from './components/EIP'
import MinmaxTemp from './components/MinMaxTemp'
import RiskHistory from './components/RiskHistory'
import FacilityFilter from './components/FacilityFilter'
import InputTable from './components/InputTable'
import Infections from './components/Infections'
import DaysFilter from './components/DaysFilter'
import TemperatureUnitFilter from './components/TemperatureUnitFilter'
import TodayRisk from './components/TodayRisk2'
import CurrentTemperature from './components/CurrentTemperature'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { SizeMe } from 'react-sizeme'

const fireBlight = () => {
  let match = useRouteMatch()
  const [facilityId, setFacilityId] = useState(match.params.FacilityId)
  const [locationId, setLocationId] = useState(match.url.split('/').slice(-2, -1)[0])
  const [days, setDays] = useState(30)
  const [temperatureUnit, setTemperatureUnit] = useState(localStorage.getItem('temperatureUnit') || "F")
  return (
    <Container className="dashboard">
      <Row>
        <Col style={{ marginBottom: '10px' }}>
          <h3 className="page-title" style={{ display: 'inline' }}>
            FireBlight -{' '}
          </h3>
          <FacilityFilter locationId={locationId} />
        </Col>
        <Col md={12} lg={12} xl={3} xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <CurrentTemperature facilityId={facilityId} locationId={locationId} temperatureUnit={temperatureUnit}/>
        </Col>
        <Col>
          <TemperatureUnitFilter unitsCallback={setTemperatureUnit}/>
        </Col>
        <Col>
          <DaysFilter daysCallback={setDays} />
        </Col>
      </Row>
      <Row>
        <TodayRisk facilityId={facilityId} temperatureUnit={temperatureUnit}/>
        <ForecastWeather facilityId={facilityId}/>
        <RiskHistory facilityId={facilityId} />
      </Row>
      <Row>
        <MinmaxTemp facilityId={facilityId} temperatureUnit={temperatureUnit} filterDays={days} />
        <EIP facilityId={facilityId} filterDays={days} />
      </Row>
      <Row>
        <Mapequipment facilityId={facilityId} />
        <Infections facilityId={facilityId} filterDays={days} />
      </Row>
      <Row>
        <InputTable facilityId={facilityId} temperatureUnit={temperatureUnit} />
      </Row>
    </Container>
  )
}

export default fireBlight
