import React from 'react'
import ProfileForm from './Components/ProfileFormFile'

const Profile = () => {

    return (
        <ProfileForm />
    )
}

export default Profile
