import React, { useState, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'


const FacilityFilter = ({ locationId }) => {
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState([null, ""]);
    const { data, loading, error } = useQuery(gql`
    query getLocation($id: ID!) {
        getLocation(id: $id) {
          facilities{
            id,
            name
          }
        }
    }`, {
        variables: { id: locationId },
    })
    useEffect(() => {
        if (data && data.getLocation) {
            setFacilities(data.getLocation.facilities)
        }
    }, [data])
    useEffect(() => {
        if (facilities.length > 0) {
            setFacility([facilities[0].id, facilities[0].name])
        }
    }, [facilities])

    return (
        error || loading ? '' :
            facilities.length > 1 ?
                <UncontrolledDropdown style={{ display: "inline" }}>
                    <DropdownToggle color="#F3F4F7" className="icon icon--right" >
                        <h3 style={{ fontWeight: 500, fontSize: "20px" }}>{facility[1]} <ChevronDownIcon /></h3>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown__menu">
                        {facilities.map(facility => {
                            return <DropdownItem id={facility.id} key={facility.id} onClick={(e) => {
                                setFacility([e.currentTarget.getAttribute("id"), e.currentTarget.textContent])
                            }}>{facility.name} </DropdownItem>
                        })}
                    </DropdownMenu>
                </UncontrolledDropdown>
                : <h3 style={{ display: "inline" }} className="page-title" >{facility[1]}</h3>
    );
}

export default FacilityFilter

