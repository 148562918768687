import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import * as Actions from "./Devices.graphql"
import PageView from 'shared/components/PageView'
import _ from 'lodash'
import { NotificationManager } from 'react-notifications';

const BeWhereList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [zonesTable, setZonesTable] = useState([]);
    const [columns, setColumns] = useState([
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Account*', field: 'accountId' },
        { title: 'Zone*', field: 'zoneId' },
        { title: 'Name*', field: 'name' },
        { title: 'Serial*', field: 'serial', editable: 'onAdd' },
        { title: 'Latitude*', field: 'latitude' },
        { title: 'Longitude*', field: 'longitude' },
        { title: 'IMSI', field: 'imsi' },
        { title: 'SIM', field: 'sim' },
        {
            title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
                1: "Active", 0: "Inactive"
            }
        },
    ]);

    const { data: zonesData, loading: zonesLoading, error: zonesError, refetch: zonesRefetch } = useQuery(Actions.GET_ZONES, { fetchPolicy: "network-only" })
    const [loadDevices, { data, loading, error, refetch }] = useLazyQuery(Actions.GET_DEVICES, { fetchPolicy: "network-only" })
    const [createBeWhere] = useMutation(Actions.CREATE_BEWHERE);
    const [updateBeWhere] = useMutation(Actions.UPDATE_BEWHERE);

    useEffect(() => {
        try {
            if (zonesData) {
                let zones = zonesData.getZones;
                setZonesTable(zones);
                loadDevices();
            }
        }
        catch (e) {
            if (!errorUI) {
                console.log(e);
                seterrorUI(true);
            }
        }
    }, [zonesData])



    useEffect(() => {
        try {
            if (zonesTable.length > 0 && data && data.getAccounts) {
                let table = data.getAccounts;
                setTable(parseData(table, zonesTable));
            }
        }
        catch (e) {
            if (!errorUI) {
                console.log(e);
                seterrorUI(true);
            }
        }
    }, [data])

    const parseData = (data, zonesTable) => {
        setColumns(makeLookups(data, zonesTable));
        let beWhere = []
        data.forEach(account => {
            account.facilities.forEach(facility => {
                facility.zones.forEach(eachZone => {
                    eachZone.devices.bewhere.forEach(bew => {
                        beWhere.push({
                            id: bew.id, accountId: account.id, zoneId: eachZone.id, name: bew.name, serial: bew.serial,
                            latitude: bew.latitude, longitude: bew.longitude, imsi: bew.imsi, sim: bew.sim, status: bew.active ? 1 : 0
                        });
                    })
                })
            })
        })
        return beWhere;
    }

    const makeLookups = (data, zonesTable) => {
        let lookupZones = {}, lookupAccounts = {};
        data.forEach(account => {
            lookupAccounts[(account.id).toString()] = account.name
        })
        zonesTable.forEach(zone => {
            lookupZones[(zone.id).toString()] = zone.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'zoneId') {
                eachCol['lookup'] = lookupZones
            }
            if (eachCol.field == 'accountId') {
                eachCol['lookup'] = lookupAccounts
            }
        })
        return columns;
    }

    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData))) {
                if (newData.accountId && newData.zoneId && newData.name && newData.serial && newData.latitude && newData.longitude) {
                    let inputs = {
                        account_id: newData.accountId,
                        zone_id: newData.zoneId,
                        name: newData.name,
                        serial: newData.serial,
                        latitude: newData.latitude,
                        longitude: newData.longitude,
                        imsi: newData.imsi,
                        sim: newData.sim,
                        active: newData.status == 1
                    }
                    let result = await updateBeWhere({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in BeWhere updation', 'BeWhere Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'BeWhere Information', 2000);
                    }
                    await refetch();
                    await zonesRefetch();
                }
                else {
                    NotificationManager.error('Mandatory fields are not provided', 'BeWhere Information', 3000)
                }
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        try {
            if (newData.accountId && newData.name && newData.zoneId && newData.serial && newData.latitude && newData.longitude) {
                let inputs = {
                    account_id: newData.accountId,
                    zone_id: newData.zoneId,
                    name: newData.name,
                    serial: newData.serial,
                    latitude: newData.latitude,
                    longitude: newData.longitude,
                    imsi: newData.imsi,
                    sim: newData.sim
                }
                let result = await createBeWhere({ variables: { input: inputs } }).catch(e => {
                    NotificationManager.error('Error in BeWhere creation', 'BeWhere Information', 3000)
                })
                if (result) {
                    NotificationManager.success('Successfully created', 'BeWhere Information', 2000);
                }
                await refetch();
                await zonesRefetch();
            }
            else {
                NotificationManager.error('Mandatory fields are not provided', 'BeWhere Information', 3000)
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const isLoading = () => {
        return loading || error || errorUI || zonesLoading || zonesError
    }

    return (
        <PageView trail={['Configuration', 'Devices']}>
            <Panel md={12} lg={12} xl={12} xs={12} loading={isLoading()}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 15,
                        addRowPosition: "first"
                    }}
                    title="Bewhere Devices"
                    columns={columns}
                    data={table}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleAddRow(newData);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitRow(newData, oldData);
                        }
                    }}
                />
            </Panel>
        </PageView>
    )
}

export default BeWhereList