import React from 'react'
import { Route } from 'react-router-dom'
import { Switch, useRouteMatch } from 'react-router-dom'
import Buildings from './'
import UnitDashboard from '../UnitDashboard/'
import Units from '../Units/'

export default () => {
    let match = useRouteMatch()
    return (
        <div>
            <Switch>
                 <Route path={`${match.path}/:BuildingId/:UnitId`} component={UnitDashboard} />
                 <Route path={`${match.path}/:BuildingId`} component={Units} />
                 <Route path={`${match.path}`} component={Buildings} />
            </Switch>
        </div>
    )
}
