import React, { useState, useRef, useEffect } from 'react'
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom'
import LogIn from '../../Login/index'
import Register from '../../Register/index'
import MainWrapper from '../MainWrapper'
import HanatechRoutes from '../../Hanatech/routes'
import SmartfarmingRoutes from '../../Smartfarming/routes'
import SmartbuildingRoutes from '../../Smartbuilding/routes'
import { PrivateRoute } from './PrivateRoute'
// import IdleTimer from 'react-idle-timer'
import { useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Idle from './idle'
import TrafficanalysisRoutes from '../../Trafficanalysis/routes'

const Router = () => {
  const timeout = 1000 * 60 * 15 // 15min
  const [getMe, { data: meResult, loading, error, refetch }] = useLazyQuery(
    gql`
      query {
        me {
          account {
            name
          }
          products {
            id
            name
            suite
          }
        }
      }
    `
  )

  let history = useHistory()
  let location = useLocation()
  const [isIdle, setIdle] = useState(false)
  // const ref = useRef(null)

  useEffect(() => {
    if (isIdle) {
      history.push('/login')
      setIdle(false)
    }
  }, [isIdle])

  // if (loading) return 'loading'

  useEffect(() => {
    if (location.pathname !== '/login' && !meResult) {
      getMe()
    }

    if (meResult && location.pathname === '/login') {
      refetch()
    }
  }, [location.pathname])

  useEffect(() => {
    if (meResult && location.pathname === '/home') {
      let account = meResult.me.account.name
      let productSuites = [...new Set(meResult.me.products.map(product => product.suite))]
      if (account === 'Hanatech') {
        history.push('/hanatech')
      } else if (productSuites.includes('Smart Farming')) {
        history.push('/smartfarming')
      } else if (productSuites.includes('Smart Building')) {
        history.push('/smartbuilding')
      } else if (productSuites.includes('Traffic Analysis')) {
        history.push('/trafficanalysis')
      } else {
        history.push('/login')
        setIdle(false)
      }
    }
  }, [meResult, location.pathname])

  // useEffect(() => {
  //   if (error && location.pathname !== '/login') {
  //     console.log(error, location.pathname)
  //     history.push('/login')
  //     setIdle(false)
  //   }
  // }, [error])

  return (
    <div>
      <Idle onIdle={() => setIdle(true)} timeout={timeout} />
      {/* <IdleTimer ref={ref} element={document} onIdle={() => setIdle(true)} timeout={timeout} /> */}
      <MainWrapper>
        <main>
          <div>
            <Switch>
              {/* <Redirect exact from="/home" to="/smartfarming" /> */}
              <Route path="/login" component={LogIn} />
              <Route path="/register" component={Register} />
              <PrivateRoute path="/hanatech" component={HanatechRoutes} />
              <PrivateRoute path="/smartfarming" component={SmartfarmingRoutes} />
              <PrivateRoute path="/smartbuilding" component={SmartbuildingRoutes} />
              <PrivateRoute path="/trafficanalysis" component={TrafficanalysisRoutes} />
              <Redirect exact from="/" to="/login" />
            </Switch>
          </div>
        </main>
      </MainWrapper>
    </div>
  )
}
export default Router
