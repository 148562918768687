import React, { useState, useEffect } from 'react';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useQuery } from '@apollo/react-hooks'
import * as Actions from './Davis.graphql'
import moment from 'moment-timezone';


const DaysFilter = ({ selectedStartDate, selectedEndDate }) => {
    const [timezone, setTimezone] = useState('America/Halifax');
    const [startDate, setstartDate] = useState(moment().tz(timezone).subtract(3, 'd').toDate());
    const [endDate, setendDate] = useState(moment().tz(timezone).toDate());
    const [minStartDate, setMinStartDate] = useState(null);
    const [maxStartDate, setMaxStartDate] = useState(moment().tz(timezone).toDate());
    const [minEndDate, setMinEndDate] = useState(moment().tz(timezone).subtract(3, 'd').toDate());
    const [maxEndDate, setMaxEndDate] = useState(moment().tz(timezone).toDate());
    const [errorUI, setErrorUI] = useState(false)

    const { data, loading, error } = useQuery(Actions.GET_TIMEZONE)

    useEffect(() => {
        try {
            if (data && data.getFacilities[0].timezone) {
                setTimezone(data.getFacilities[0].timezone);
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [data])


    const handleStartChange = (date) => {
        setstartDate(date);
        selectedStartDate(date);
    }

    const handleEndChange = (date) => {
        setendDate(date);
        selectedEndDate(date);
    }

    useEffect(() => {
        if (startDate && endDate) {
            setMaxStartDate(endDate);
            setMinEndDate(startDate);
        }
    }, [startDate, endDate])

    if (loading || errorUI || error) {
        return ''
    }

    return (
        <React.Fragment>
            <Col md={12} lg={12} xl={4} xs={12}>
                <span className="form__form-group-label">From Date</span>
                <div className="date-picker">
                    <DatePicker
                        className="form__form-group-datepicker"
                        selected={startDate}
                        onChange={handleStartChange}
                        dropDownMode="select"
                        maxDate={maxStartDate}
                        minDate={minStartDate}
                    />
                </div>
            </Col>
            <Col md={12} lg={12} xl={4} xs={12}>
                <span className="form__form-group-label">To Date</span>
                <div className="date-picker">
                    <DatePicker
                        className="form__form-group-datepicker"
                        selected={endDate}
                        onChange={handleEndChange}
                        dropDownMode="select"
                        maxDate={maxEndDate}
                        minDate={minEndDate}
                    />
                </div>
            </Col>
        </React.Fragment>
    )
}

export default DaysFilter
