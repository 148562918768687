import React, { useState, useEffect } from 'react';
import { ButtonToolbar, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { useQuery } from '@apollo/react-hooks'
import * as Actions from './Davis.graphql'


const Filter = ({ callbackFacilityID, callbackFacilities }) => {
    const [errorUI, setErrorUI] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState([null, ""]);
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState([null, ""]);

    const handleAccount = (e) => {
        setAccount([e.currentTarget.getAttribute("id"), e.currentTarget.textContent]);
    }

    const handleFacility = (e) => {
        setFacility([e.currentTarget.getAttribute("id"), e.currentTarget.textContent]);
    }

    const { data: accountsData, loading: accountsLoading, error: accountsError } = useQuery(Actions.GET_ACCOUNTS);


    useEffect(() => {
        try {
            if (accountsData && accountsData.getAccounts) {
                let filteredAccounts = accountsData.getAccounts
                filteredAccounts.forEach(account => {
                    account.facilities.forEach(facility => {
                        facility.products = facility.products.filter(product => product.name == "Davis")
                    })
                    account.facilities = account.facilities.filter(facility => facility.products.length > 0)
                })
                filteredAccounts = filteredAccounts.filter(account => account.facilities.length > 0 && account.name != "Hanatech")
                setAccounts(filteredAccounts);
                let { id, name } = filteredAccounts[0];
                setAccount([id, name]);
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [accountsData])

    useEffect(() => {
        try {
            if (accounts.length > 0) {
                setAccount([accounts[0].id, accounts[0].name]);
                setFacilities(getFacilityInfo(accounts[0].id));
                callbackFacilities(getFacilityInfo(accounts[0].id))
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [accounts])
    useEffect(() => {
        try {
            if (account[0]) {
                setFacilities(getFacilityInfo(account[0]));
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [account])

    useEffect(() => {
        try {
            if (facilities.length > 0) {
                setFacility([facilities[0].id, facilities[0].name])
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [facilities])

    useEffect(() => {
        try {
            if (facility[0]) {
                callbackFacilityID(facility[0]);
            }
        }
        catch{
            setErrorUI(true);
        }

    }, [facility])

    const getFacilityInfo = (id) => {
        try {
            if (accounts.length > 0) {
                let account = accounts.filter(account => account.id == id);
                return account[0].facilities;
            }
        }
        catch{
            setErrorUI(true);
        }
    }


    if (accountsLoading) return ''
    if (accountsError || errorUI) return `Error`

    return (
        <div>
            <Row>
                <Col md={12} lg={12} xl={3} xs={12}>
                    <ButtonToolbar style={{ justifyContent: "center" }}>
                        <UncontrolledDropdown>
                            <DropdownToggle color="primary" className="icon icon--right">
                                <p>{account[1]} <ChevronDownIcon /></p>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                                {accounts.map(account => {
                                    return <DropdownItem id={account.id} key={account.id} onClick={(e) => handleAccount(e)}>{account.name} </DropdownItem>
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonToolbar>
                </Col>
                <Col md={12} lg={12} xl={3} xs={12}>
                    <ButtonToolbar style={{ justifyContent: "center" }}>
                        <UncontrolledDropdown>
                            <DropdownToggle color="primary" className="icon icon--right">
                                <p>{facility[1]} <ChevronDownIcon /></p>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                                {facilities.map(facility => {
                                    return <DropdownItem id={facility.id} key={facility.id} onClick={(e) => handleFacility(e)}>{facility.name} </DropdownItem>
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonToolbar>
                </Col>
            </Row>
        </div >
    )
}

export default Filter
