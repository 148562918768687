import React from 'react'
import { Route } from 'react-router-dom'
import { Switch, useRouteMatch, Redirect } from 'react-router-dom'
import Locations from './Locations'
import Facilities from './Facilities'
import FireBlight from '../FireBlight/routes'

export default () => {
    let match = useRouteMatch()
    return (
        <Switch>
            <Route path={`${match.path}/:LocationId/:FacilityId`} component={FireBlight} />
            <Route path={`${match.path}/:LocationId`} component={Facilities} />
            <Route path={match.path} component={Locations} />
        </Switch>
    )
}
