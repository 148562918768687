import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TopbarProfile from './TopbarProfile'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

const Topbar = () => {
  let fixedImageURL = `/img/hanatech-logo.svg`;
  const [image, setImage] = useState('');
  const [imageEnabled, setImageEnabled] = useState(false);
  const [errorUI, setErrorUI] = useState(false);
  const [loadImage, { data, loading, error, refetch }] = useLazyQuery(gql`
  query{
    getWhiteLabelPicture
    }
  `, { fetchPolicy: 'no-cache' });

  const { data: meData, loading: meLoading, error: meError } = useQuery(gql`
    query getMe {
      me{
        account{
          id
          white_label_enabled
        }
      }
  }`, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    try {
      if (meData && meData.me && meData.me.account) {
        if (meData.me.account.white_label_enabled && meData.me.account.id != 1) {
          setImageEnabled(meData.me.account.white_label_enabled);
          loadImage();
        }
      }
    }
    catch{
      setErrorUI(true)
    }
  }, [meData])


  useEffect(() => {
    try {
      if (data && data.getWhiteLabelPicture) {
        setImage(data.getWhiteLabelPicture);
      }
    }
    catch{
      setErrorUI(true)
    }
  }, [data])

  useEffect(() => {
    try {
      if (error) {
        setImage('');
      }
    }
    catch{
      setErrorUI(true)
    }
  }, [error])

  const parseImage = (imgData) => {
    if (imgData.filename && imgData.encoding) {
      let img = `data:${imgData.filename};base64,${imgData.encoding}`;
      return img;
    }
  }


  const isLoading = () => {
    return loading || errorUI || meError || meLoading;
  }


  return (
    isLoading() ? '' :
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            {/* <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            /> */}
            <div style={{ width: '25px' }}></div>
            <Link className="topbar__logo" style={{ backgroundImage: `url('${(image == '' || !imageEnabled) ? fixedImageURL : image}')` }} to="/home" />
          </div>
          <div className="topbar__right">
            <TopbarProfile />
          </div>
        </div>
      </div>
  )
}

export default Topbar
