import React, { useState, useEffect } from 'react'

import { Table, Row, Col } from 'reactstrap'
import GaugeChart from 'react-gauge-chart'
import { withSize } from 'react-sizeme'
// import {
//   WiCloudy,
//   WiRainMix,
//   WiDayCloudy,
//   WiDaySunny,
//   WiNightSnowWind,
//   WiDayWindy,
//   WiDayFog,
// } from 'weather-icons-react'
import moment from 'moment'
import CircleIcon from 'mdi-react/CircleIcon'
import styles from './RiskForecastWidget.module.scss'

const RiskComponent = ({ dayRisk, count }) => {

  const tempConversion = (temp) => {
    return (localStorage.getItem('temperatureUnit') || "F") == "F" ? parseFloat(temp).toFixed(1)+"°F" : parseFloat(((temp - 32)*5)/9).toFixed(1)+"°C"
  }

  return (
    dayRisk.minTemp != "NaN" && dayRisk.minTemp != "NaN" ?
      <Row style={{ flexDirection: "column", height: "200px" }} >
        <Col className={styles.flex_items_center}>
          <div>{dayRisk.dayOfWeek}</div>
        </Col>
        <Col>
          {
            <div className={styles.risk_meter}>
              <GaugeChart
                id={'gauge-chart1' + count}
                nrOfLevels={5}
                colors={['#8BC85C', '#5BA318', '#DEE300', '#FABC04', '#EC3F0A']}
                arcWidth={0.3}
                percent={dayRisk.level}
                hideText={true}
              />
            </div>
          }
        </Col>
        <Col className={styles.flex_items_center}>
          {
            <p style={{ color: '#ff0000' }}>{tempConversion(dayRisk.maxTemp)}</p>
          }
        </Col>
        <Col className={styles.flex_items_center}>
          {
            <p style={{ color: '#0000ff' }}>{tempConversion(dayRisk.minTemp)}</p>
          }
        </Col>
      </Row>
      : <div className={styles.no_data}><p>No Data</p></div>
  )
}

const RiskForcastWidget = ({ size, ForecastData, displayforecast }) => {
  const [displayedRiskData, setDisplayedRiskData] = useState([])
  // const [tableCss, setTableCss] = useState({})

  // useEffect(() => {
  //   if (size.width <= 650) {
  //     setTableCss({
  //       display: 'inline-block',
  //       overflowX: 'auto',
  //       legendHighStyle: 'center',
  //       legendLowStyle: 'center',
  //     })
  //   } else {
  //     setTableCss({
  //       display: '',
  //       overflowX: '',
  //       legendHighStyle: 'right',
  //       legendLowStyle: '',
  //     })
  //   }
  // }, [size])

  useEffect(() => {
    if (size.width <= 400) {
      setDisplayedRiskData(ForecastData.slice(0, -1))
    } else {
      setDisplayedRiskData(ForecastData)
    }
  }, [size, ForecastData])

  return displayedRiskData.length > 0 ? (
    <React.Fragment>
      <Row md={12} lg={12} xl={12} xs={12} style={{ height: "200px" }}>
        {
          displayedRiskData.map((eachRisk, i) => {
            let totalRisk = [];
            if (i < 3) {
              let dayOfWeek = i == 0 ? (displayforecast ? "Tomorrow" : "Yesterday") : moment(eachRisk.date).utc().format('MMM DD');
              let level = eachRisk.level == '-' || eachRisk.level == 'No Risk' || eachRisk.level == ''
                ? 0.1
                : eachRisk.level == 'Low'
                  ? 0.3
                  : eachRisk.level == 'Medium'
                    ? 0.5
                    : eachRisk.level == 'High'
                      ? 0.7
                      : 0.9
              let maxTemp = parseFloat(eachRisk.maxTemp).toFixed(1);
              let minTemp = parseFloat(eachRisk.minTemp).toFixed(1);
              totalRisk.push({ dayOfWeek: dayOfWeek, level: level, maxTemp: maxTemp, minTemp: minTemp })
              return (
                <Col md={4} lg={4} xl={4} xs={6}>
                  <RiskComponent dayRisk={totalRisk[0]} count={i} />
                </Col>
              )
            }
          })
        }
      </Row>
      <Row md={12} lg={12} xl={12} xs={12}>
        <Col sm={6} xs={12} className={styles.high_temp_legend}>
          <CircleIcon className="dashboard_forecast_widgeticon" color="#ff0000" /> High Temperature
        </Col>
        <Col sm={6} xs={12} className={styles.low_temp_legend}>
          <CircleIcon className="dashboard_forecast_widgeticon" color="#0000ff" /> Low Temperature
        </Col>
      </Row>
    </React.Fragment>
  ) : (
      ''
    )
}
export default withSize()(RiskForcastWidget)
