import React from 'react'
import PageView from 'shared/components/PageView'

const FireBlight = () => {
    return (
        <PageView trail={['Smart Farming', 'FireBlight']}>

        </PageView>
    )
}
export default FireBlight
