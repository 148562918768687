import React, { useState } from 'react'
import { Col } from 'reactstrap'
import { Droppable, Draggable } from 'react-beautiful-dnd'


const UnassignedMeters = ({ meters, MeterDescription, dragStyle, zoneID = '', callback }) => {
    return (
        <Col style={{ textAlign: 'center' }}>
            <div
                style={{
                    backgroundColor: 'lightgrey',
                    padding: '20px',
                    width: '500px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <Droppable droppableId="unassigned">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps} style={{ minHeight: '50px' }}>
                            {meters.map((meter, index) => {
                                return (
                                    <Draggable key={meter.serial} draggableId={meter.serial} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={dragStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <MeterDescription meter={meter} zoneID={zoneID} callback={callback}></MeterDescription>
                                            </div>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </Col>
    )
}

export default UnassignedMeters