import React, { useState, useEffect } from 'react';
import Panel from 'shared/components/Panel';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Line } from 'react-chartjs-2';
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import * as utls from 'shared/utls/index.js'
import moment from 'moment'

const ConsumptionChart = ({ zone }) => {
    const getDataSetObjects = (zone) => {
        let fixedPro = {
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderDash: [],
            borderWidth: 1.5,
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 2,
            pointHoverRadius: 3,
            pointHoverBorderWidth: 2,
            pointRadius: 2,
            pointHitRadius: 10,
        }
        let datasetObject = [];
        let colors = ["#fc0303", "#fc5603", "#fc8c03", "#fcc603", "#39fc03", "#00aaff", "#006aff", "#000dff", "#2600ff", "#8400ff", "#9500ff", "#ff008c"];
        zone.forEach((meter, i) => {
            datasetObject.push({
                label: meter.name,
                data: meter.data.map(d => d.consumption),
                borderColor: colors[i],
                ...fixedPro
            })
        })
        return datasetObject;
    }

    const getRandomColor = () => {
        return "#" + ((1 << 24) * Math.random() | 0).toString(16);
        // let colors = ["#fc0303", "#fc5603", "#fc8c03", "#fcc603", "#39fc03", "#00aaff", "#006aff", "#000dff", "#2600ff", "#8400ff", "#9500ff", "#ff008c"];
        // return colors[Math.floor(Math.random() * colors.length)]
    }
    let date = zone[0].data.map(d => moment(d.date, 'YYYY-MM-DD').format('MMM DD, YYYY'))
    const chartData = {
        labels: date,
        datasets: getDataSetObjects(zone)
    }
    const options = {
        legend: {
            display: true,
            position: 'bottom'
        },
        scales: {
            legend: {
                display: false,
                position: 'bottom'
            },
            yAxes: [{
                gridLines: {
                    display: true,
                },
                scaleLabel: {
                    display: true,
                    labelString: 'kWh'
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    let label = ''
                    label += Math.round(tooltipItem.yLabel * 100) / 100;
                    label += ' kWh'
                    return label;
                }
            }
        }
    }
    return (
        <Line data={chartData} options={options} height="50vh" />
    )
}


const DailyPowerConsumption = ({ clientId, startDate, endDate }) => {
    const [errorUI, seterrorUI] = useState(false);
    const [consumptionData, setconsumptionData] = useState([]);
    const { data, loading, error, refetch } = useQuery(gql`
    query getPowerBillingByClient($id: Int!) {
        getPowerBillingByClient(client_id: $id) {
          clients{
          zones{
            id,
            name
            devices{
              ekm{
                serial,
                name
                daily_consumption{
                      date,
                      consumption
                    }
              }
            }
          }
        }
      }
    }`, {
        variables: { id: clientId }, fetchPolicy: "network-only"
    })
    if (error) console.log(error);


    useEffect(() => {
        try {
            if (data && data.getPowerBillingByClient && data.getPowerBillingByClient.clients[0].zones) {
                setconsumptionData(generateChartData(data.getPowerBillingByClient.clients[0].zones, startDate, endDate))
            }
        } catch {
            seterrorUI(true)
        }
    }, [data, startDate, endDate])

    const getParsedMeterData = (dailyConsumptionData, startDate, endDate) => {
        let chartdata = [];
        dailyConsumptionData.filter(eachdate => {
            return new Date(eachdate.date) >= startDate && new Date(eachdate.date) <= endDate
        }).map((d, i) => {
            chartdata.push({
                date: d.date,
                consumption: d.consumption == "-" ? "0" : d.consumption.toString()
            });
        })
        return utls.sortByDate(chartdata, false);
    }

    const generateChartData = (totalConsumptionData, startDate, endDate) => {
        let zonesData = []
        totalConsumptionData.forEach(zone => {
            let consumption = [];
            zone.devices.ekm.forEach(eachMeter => {
                consumption.push({ name: eachMeter.name, data: getParsedMeterData(eachMeter.daily_consumption, startDate, endDate) })
            })
            zonesData.push({ id: zone.id, name: zone.name, consumptionData: consumption });
        })
        return zonesData;
    }

    return (
        <Panel md={12} lg={12} xl={12} xs={12} title="Power Consumption" loading={loading || error || errorUI}>
            <div style={{ display: "inline-block", overflowX: "auto", width: "100%" }}>
                {
                    consumptionData.map(zone => {
                        if (zone.name && zone.consumptionData.length > 0) {
                            return (
                                <React.Fragment>
                                    <div style={{ minWidth: "600px" }}>
                                        <h4>{zone.name}</h4>
                                        <ConsumptionChart zone={zone.consumptionData} />
                                    </div>
                                    <br />
                                </React.Fragment>
                            )
                        }
                    })
                }
            </div>
        </Panel>
    )
}


export default DailyPowerConsumption

