import gql from 'graphql-tag'

export const GET_ME = gql`
query{
    me{
      id,
      firstName,
      lastName,
      username,
      email
      phone_number
      address,
      province
      country,
      city,
      postal_code,
      website,
      active
    }
  }
`


// export const UPDATE_USER = gql`
// mutation updateUser(	
//     $id: ID!,
//     $email:String,
//     $firstName:String,
//     $lastName:String,
//     $username:String,
//     $password:String
//   	$phone_number:String,
//   	$website: String,
//   	$province:String,
//   	$city:String,
//   	$country:String,
//     $address:String,
//     $postal_code:String
//     $active: Boolean
//     ){
//     updateUser( id: $id, email:$email, firstName:$firstName,
//     lastName:$lastName, username:$username, password:$password,
//       province:$province, city:$city, country:$country, address:$address,
//       phone_number:$phone_number,website:$website,postal_code:$postal_code, active: $active
//     ){
//       id
//     }
//   }`

  export const UPDATE_USER = gql`
  mutation updateUser(	
    $id:ID!
      $input: UserInput!
      ){
        updateUser(id:$id, input: $input){
          id
        }
    }`

export const UPLOAD_PICTURE = gql`
mutation uploadProfilePicture( $input: FileInput!) {
  uploadProfilePicture(input: $input)
}`

export const GET_PROFILE_PICTURE = gql`
query{
  getProfilePicture
  }
`
