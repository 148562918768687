import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { useHistory, useRouteMatch } from 'react-router-dom'

const CardLink = ({ title, url, absolurl, children }) => {
  let history = useHistory()
  let match = useRouteMatch()
  return (
    <Card>
      <CardBody className="card_hover" onClick={() => history.push(url ? `${match.path}${url}` : absolurl ? absolurl : '')}>
        <div className="card__title">
          <h5 className="bold-text">{title}</h5>
        </div>
        {children}
      </CardBody>
    </Card>
  )
}
export default CardLink
