import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidebarLink from './SidebarLink'
import SidebarCategory from './SidebarCategory'

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  hideSidebar = () => {
    this.props.onClick()
  }

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarCategory title="Hanatech" icon="layers">
            <SidebarLink title="Products" route="/hanatech/products" onClick={this.hideSidebar} />
            <SidebarLink title="Accounts" route="/hanatech/accounts" onClick={this.hideSidebar} />
            <SidebarLink title="Users" route="/hanatech/users" onClick={this.hideSidebar} />
            <SidebarLink title="Roles" route="/hanatech/roles" onClick={this.hideSidebar} />
            <SidebarLink title="Locations" route="/hanatech/locations" onClick={this.hideSidebar} />
            <SidebarLink title="Facilities" route="/hanatech/facilities" onClick={this.hideSidebar} />
            <SidebarLink title="Zones" route="/hanatech/zones" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarCategory title="Smart Farming" icon="layers">
            <SidebarLink title="FireBlight " route="/smartfarming" onClick={this.hideSidebar} />
            <SidebarLink title="Davis" route="/smartfarming/davis" onClick={this.hideSidebar} />
          </SidebarCategory>
          <SidebarLink title="Power Billing" icon="lightning.svg" route="/smartbuilding/power-billing" onClick={this.hideSidebar} />
        </ul>
      </div>
    )
  }
}

export default SidebarContent
