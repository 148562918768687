import React from 'react'
import { Link } from 'react-router-dom'
import RegisterForm from './Components/RegisterForm'

const Register = () => (
  <div className="account">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">
            Welcome
            <span className="account__logo">
              {' '}
              Hanatech
              <span className="account__logo-accent">IOT</span>
            </span>
          </h3>
          <h4 className="account__subhead subhead">Create an account</h4>
        </div>
        <RegisterForm onSubmit />
        <div className="account__have-account">
          <p>
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default Register
