import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import * as Actions from "./Devices.graphql"
import PageView from 'shared/components/PageView'
import _ from 'lodash'
import { NotificationManager } from 'react-notifications';

const EKMList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [zonesTable, setZonesTable] = useState([]);
    const [columns, setColumns] = useState([
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Account*', field: 'accountId' },
        { title: 'Zone*', field: 'zoneId' },
        { title: 'Name*', field: 'name' },
        { title: 'Serial*', field: 'serial', editable: 'onAdd' },
        {
            title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
                1: "Active", 0: "Inactive"
            }
        },
    ]);

    const { data: zonesData, loading: zonesLoading, error: zonesError, refetch: zonesRefetch } = useQuery(Actions.GET_ZONES, { fetchPolicy: "network-only" })
    const [loadDevices, { data, loading, error, refetch }] = useLazyQuery(Actions.GET_DEVICES, { fetchPolicy: "network-only" })
    const [createEKM] = useMutation(Actions.CREATE_EKM);
    const [updateEKM] = useMutation(Actions.UPDATE_EKM);

    useEffect(() => {
        try {
            if (zonesData) {
                let zones = zonesData.getZones;
                setZonesTable(zones);
                loadDevices();
            }
        }
        catch (e) {
            if (!errorUI) {
                console.log(e);
                seterrorUI(true);
            }
        }
    }, [zonesData])



    useEffect(() => {
        try {
            if (zonesTable && data && data.getAccounts) {
                let table = data.getAccounts;
                setTable(parseData(table, zonesTable));
            }
        }
        catch (e) {
            if (!errorUI) {
                console.log(e);
                seterrorUI(true);
            }
        }
    }, [zonesData, data])

    const parseData = (data, zonesTable) => {
        setColumns(makeLookups(data, zonesTable));
        let ekm = []
        data.forEach(account => {
            account.facilities.forEach(facility => {
                facility.zones.forEach(eachZone => {
                    eachZone.devices.ekm.forEach(ek => {
                        ekm.push({
                            id: ek.id, accountId: account.id, zoneId: eachZone.id, name: ek.name, serial: ek.serial, status: ek.active ? 1 : 0
                        });
                    })
                })
            })
        })
        return ekm;
    }

    const makeLookups = (data, zonesTable) => {
        let lookupZones = {}, lookupAccounts = {};
        data.forEach(account => {
            lookupAccounts[(account.id).toString()] = account.name
        })
        zonesTable.forEach(zone => {
            lookupZones[(zone.id).toString()] = zone.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'zoneId') {
                eachCol['lookup'] = lookupZones
            }
            if (eachCol.field == 'accountId') {
                eachCol['lookup'] = lookupAccounts
            }
        })
        return columns;
    }

    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData))) {
                if (newData.accountId && newData.name
                    && newData.serial && newData.zoneId) {
                    let inputs = {
                        account_id: newData.accountId,
                        zone_id: newData.zoneId,
                        name: newData.name,
                        serial: newData.serial,
                        active: newData.status == 1
                    }
                    let result = await updateEKM({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in EKM updation', 'EKM Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'EKM Information', 2000);
                    }
                    await refetch();
                    await zonesRefetch();
                }
                else {
                    NotificationManager.error('Mandatory fields are not provided', 'EKM Information', 3000)
                }
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        if (newData.accountId && newData.name
            && newData.serial && newData.zoneId) {
            let inputs = {
                account_id: newData.accountId,
                zone_id: newData.zoneId,
                name: newData.name,
                serial: newData.serial
            }
            let result = await createEKM({ variables: { input: inputs } }).catch(e => {
                NotificationManager.error('Error in EKM creation', 'EKM Information', 3000)
            })
            if (result) {
                NotificationManager.success('Successfully created', 'EKM Information', 2000);
            }
            await refetch();
            await zonesRefetch();
        }
        else {
            NotificationManager.error('Mandatory fields are not provided', 'EKM Information', 3000)
        }
    }

    const isLoading = () => {
        return loading || error || errorUI || zonesLoading || zonesError
    }

    return (
        <Panel md={12} lg={12} xl={12} xs={12} loading={isLoading()}>
            <MaterialTable
                options={{
                    padding: "dense",
                    headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                    exportButton: true,
                    exportAllData: true,
                    pageSize: 15,
                    addRowPosition: "first"
                }}
                title="Meter Devices"
                columns={columns}
                data={table}
                editable={{
                    onRowAdd: async (newData) => {
                        await handleAddRow(newData);
                    },
                    onRowUpdate: async (newData, oldData) => {
                        await handleSubmitRow(newData, oldData);

                    }
                }}
            />
        </Panel>
    )
}

export default EKMList