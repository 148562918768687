import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from "./Accounts.graphql"
import PageView from 'shared/components/PageView'
import { NotificationManager } from 'react-notifications';

const AccountsList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const columns = [
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Name*', field: 'name' },
        {
            title: 'White label image', field: 'img_url', editable: 'onUpdate',
            editComponent: props => {
                return (
                    <input
                        type="file"
                        value={props.filename}
                        name={props.filename}
                        onChange={async (e) => {
                            await handleUpload(props.rowData.id, e);
                            debugger;
                            table[table.indexOf(props.rowData)] = props.rowData;
                            setTable(table);
                        }}
                    />
                )
            }
        },
        // {
        //     title: 'Image', field: 'image', editable: 'never',
        //     editComponent: props => {
        //         return (
        //             <img
        //                 width="200"
        //                 height="200"
        //                 src="/img/meter-icon.png"
        //             />
        //         )
        //     }
        // },
        {
            title: 'White Label Enabled', field: 'white_label_enabled', editable: 'onUpdate', lookup: {
                1: "Yes", 0: "No"
            }
        },
        {
            title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
                1: "Active", 0: "Inactive"
            }
        }
    ]

    const { data, loading, error, refetch } = useQuery(Actions.GET_ACCOUNTS, { fetchPolicy: "network-only" })
    const [uploadPicture] = useMutation(Actions.UPLOAD_PICTURE);

    const [createAccount] = useMutation(Actions.CREATE_ACCOUNT);
    const [updateAccount] = useMutation(Actions.UPDATE_ACCOUNT);



    useEffect(() => {
        try {
            if (data) {
                let table = data.getAccounts;
                setTable(parseAccountsData(table));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [data])

    const parseAccountsData = (data) => {
        let accounts = []
        data.forEach(account => {
            accounts.push({
                id: account.id, name: account.name, status: account.active ? 1 : 0,
                white_label_enabled: account.white_label_enabled ? 1 : 0, img_url: account.img_url
            });
        })
        return accounts;
    }

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }

    const handleUpload = async (id, e) => {
        try {
            let inputFile = e.target.files[0];
            if (inputFile.type.includes('image')) {
                if (inputFile.size < 32000000) {
                    let binaryStr = await readFileAsync(inputFile)
                    let inputs = {
                        filename: inputFile.name,
                        encoding: binaryStr
                    }
                    let result = await uploadPicture({ variables: { id: id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in uploading white label', 'Image Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully uploaded', 'Image Information', 2000);
                    }
                }
            }
            else {
                NotificationManager.error('Not an Image', 'Image Information', 3000)
            }
            refetch();
        }
        catch{
            seterrorUI(true);
        }
    }



    const handleSubmitRow = async (newData, oldData) => {
        try {
            if ((newData.name != oldData.name) || (newData.status != oldData.status) ||
                (newData.white_label_enabled != oldData.white_label_enabled)) {
                if (newData.name) {
                    let inputs = {
                        name: newData.name,
                        active: newData.status == 1,
                        white_label_enabled: newData.white_label_enabled == 1
                    }
                    let result = await updateAccount({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in Account updation', 'Account Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'Account Information', 2000);
                    }
                }
                else {
                    NotificationManager.error('Mandatory fields are not provided', 'Account Information', 3000)
                }
            }
            await refetch();
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        if (newData.name) {
            let result = await createAccount({
                variables: {
                    input: {
                        name: newData.name,
                    }
                }
            }).catch(e => {
                NotificationManager.error('Error in Account creation', 'Account Information', 3000)
            })
            if (result) {
                NotificationManager.success('Successfully created', 'Account Information', 2000);
            }
        }
        else {
            NotificationManager.error('Mandatory fields are not provided', 'Account Information', 3000)
        }
        refetch();
    }


    return (
        <PageView trail={['Configuration', 'Accounts']}>
            <Panel md={12} lg={12} xl={12} xs={12} loading={loading || error || errorUI}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 30,
                        addRowPosition: "first"
                    }}
                    title="Accounts"
                    columns={columns}
                    data={table}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleAddRow(newData);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitRow(newData, oldData);
                        }
                    }}
                />
            </Panel>
        </PageView>
    )
}

export default AccountsList
