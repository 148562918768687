import React, { useState, useEffect, useRef } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from "./Gateway.graphql"
import PageView from 'shared/components/PageView'
import { isEqual } from 'lodash'
import { NotificationManager } from 'react-notifications';

const GatewaysList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [columns, setColumns] = useState([
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Facility*', field: 'facilityId' },
        { title: 'Name*', field: 'name' },
        { title: 'Serial*', field: 'serial', editable: 'onAdd' },
        {
            title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
                1: "Active", 0: "Inactive"
            }, editable: 'onEdit'
        }
    ]);


    const { data: facilitiesData, loading: facilitiesLoading, error: facilitiesError, refetch: facilitiesRefetch } = useQuery(Actions.GET_FACILITIES, { fetchPolicy: "network-only" })
    const { data, loading, error, refetch } = useQuery(Actions.GET_DEVICES, { fetchPolicy: "network-only" })

    const [createGateway] = useMutation(Actions.CREATE_GATEWAY);
    const [updateGateway] = useMutation(Actions.UPDATE_GATEWAY);

    function useDeepEffect(fn, deps) {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) =>
                isEqual(obj, deps[index])
            );

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        }, deps);
    }


    useDeepEffect(() => {
        try {
            if (data) {
                let table = data.getFacilities;
                setTable(parseData(table));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [data])

    useEffect(() => {
        try {
            if (facilitiesData) {
                let facilities = facilitiesData.getFacilities
                setColumns(makeFacilityLookup(facilities));
            }
        }
        catch{
            if (!errorUI) {
                seterrorUI(true);
            }
        }
    }, [facilitiesData])


    const parseData = (data) => {
        setColumns(makeFacilityLookup(data));
        let gatewayTable = []
        data.forEach(fac => {
            fac.gateways.forEach(gat => {
                gatewayTable.push({
                    id: gat.id, facilityId: fac.id, name: gat.name, serial: gat.serial, status: gat.active ? 1 : 0
                });
            })
        })
        return gatewayTable;
    }

    const makeFacilityLookup = (data) => {
        let lookupFacility = {};
        data.forEach(facility => {
            lookupFacility[(facility.id).toString()] = facility.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'facilityId') {
                eachCol['lookup'] = lookupFacility
            }
        })
        return columns;
    }

    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (!(isEqual(newData, oldData))) {
                if (newData.facilityId && newData.name && newData.serial) {
                    let inputs = {
                        facility_id: newData.facilityId,
                        name: newData.name,
                        serial: newData.serial,
                        active: newData.status == 1
                    }
                    let result = await updateGateway({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in Gateway updation', 'Gateways Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'Gateways Information', 2000);
                    }
                    await refetch();
                }
            }
            else {
                NotificationManager.error('Mandatory fields are not provided', 'Gateways Information', 3000)
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        console.log(newData);
        if (newData.facilityId && newData.name && newData.serial) {
            let inputs = {
                facility_id: newData.facilityId,
                name: newData.name,
                serial: newData.serial,
                active: true
            }
            let result = await createGateway({ variables: { input: inputs } }).catch(e => {
                NotificationManager.error('Error in Gateway creation', 'Gateways Information', 3000)
            })
            if (result) {
                NotificationManager.success('Successfully created', 'Gateways Information', 2000);
            }
            await refetch();
        }
        else {
            NotificationManager.error('Mandatory fields are not provided', 'Gateways Information', 3000)
        }
    }


    return (
        <PageView trail={['Configuration', 'Gateways']}>
            <Panel md={12} lg={12} xl={12} xs={12} loading={loading || error || errorUI}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 15,
                        addRowPosition: "first"
                    }}
                    title="Gateways"
                    columns={columns}
                    data={table}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleAddRow(newData);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitRow(newData, oldData);
                        }
                    }}
                />
            </Panel>
        </PageView>
    )
}

export default GatewaysList
