import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'
import Panel from 'shared/components/Panel'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from './Zones.graphql'
import PageView from 'shared/components/PageView'
import _ from 'lodash'
import { NotificationManager } from 'react-notifications';


const ZonesList = () => {
  const [table, setTable] = useState([])
  const [errorUI, seterrorUI] = useState(false)
  const [columns, setColumns] = useState([
    { title: 'ID', field: 'id', editable: 'never', hidden: true },
    { title: 'Facility*', field: 'facility_id' },
    { title: 'Name*', field: 'name' },
    {
      title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
        1: "Active", 0: "Inactive"
      }
    }
  ])
  const { data: facilityData, loading: facilityLoading, error: facilityError, refetch: facilityRefetch } = useQuery(
    Actions.GET_FACILITIES,
    { fetchPolicy: 'network-only' }
  )
  const { data, loading, error, refetch } = useQuery(Actions.GET_ZONES, { fetchPolicy: 'network-only' })
  const [createZone] = useMutation(Actions.CREATE_ZONE)
  const [updateZone] = useMutation(Actions.UPDATE_ZONE)
  useEffect(() => {
    try {
      if (data && facilityData) {
        let table = data.getZones
        setTable(parseZonesData(table))
      }
    } catch (e) {
      if (!errorUI) {
        seterrorUI(true)
      }
      console.log(e)
    }
  }, [data])


  useEffect(() => {
    try {
      if (facilityData) {
        let facilities = facilityData.getFacilities
        setColumns(makeFacilitiesLookup(facilities))
      }
    } catch (e) {
      if (!errorUI) {
        seterrorUI(true)
      }
      console.log(e)
    }
  }, [facilityData])

  const parseZonesData = (data) => {
    let zones = []
    data.forEach(zone => {
      zones.push({ id: zone.id, name: zone.name, facility_id: zone.facility_id, status: zone.active ? 1 : 0 });
    })
    return zones;
  }


  const makeFacilitiesLookup = facilities => {
    let lookupFacility = {}
    facilities.forEach(eachFacility => {
      lookupFacility[eachFacility.id.toString()] = eachFacility.name
    })
    columns.map(eachCol => {
      if (eachCol.field == 'facility_id') {
        eachCol['lookup'] = lookupFacility
      }
    })
    return columns
  }
  const handleSubmitRow = async (newData, oldData) => {
    try {
      if (!(_.isEqual(newData, oldData))) {
        if (newData.facility_id && newData.name) {
          let inputs = {
            facility_id: newData.facility_id,
            name: newData.name,
            parent_zone_id: null,
            active: newData.status == 1
          }
          let result = await updateZone({ variables: { id: newData.id, input: inputs } }).catch(e => {
            NotificationManager.error('Error in zone updation', 'Zone Information', 3000)
          })
          if (result) {
            NotificationManager.success('Successfully updates', 'Zone Information', 2000);
          }
          refetch()
        }
        else {
          NotificationManager.error('Mandatory fields are not provided', 'Zone Information', 3000)
        }
      }
    } catch (e) {
      console.log(e)
      seterrorUI(true)
    }
  }
  const handleAddRow = async newData => {
    if (newData.facility_id && newData.name) {
      let inputs = {
        facility_id: newData.facility_id,
        name: newData.name,
        parent_zone_id: null,
      }
      let result = await createZone({ variables: { input: inputs } }).catch(e => {
        NotificationManager.error('Error in zone creation', 'Zone Information', 3000)
      })
      if (result) {
        NotificationManager.success('Successfully created', 'Zone Information', 2000);
      }
      refetch()
    }
    else {
      NotificationManager.error('Mandatory fields are not provided', 'Zone Information', 3000)
    }
  }
  const isLoading = () => {
    return loading || error || errorUI || facilityError || facilityLoading
  }
  return (
    <PageView trail={['Configuration', 'Zones']}>
      <Panel md={12} lg={12} xl={12} xs={12} loading={isLoading()}>
        <MaterialTable
          options={{
            padding: 'dense',
            headerStyle: { backgroundColor: '#ADA9A8', padding: '12px' },
            exportButton: true,
            exportAllData: true,
            pageSize: 20,
            addRowPosition: "first"
          }}
          title="Zones"
          columns={columns}
          data={table}
          editable={{
            onRowAdd: async newData => {
              await handleAddRow(newData)
            },
            onRowUpdate: async (newData, oldData) => {
              await handleSubmitRow(newData, oldData)
            },
          }}
        />
      </Panel>
    </PageView>
  )
}
export default ZonesList
