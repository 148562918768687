import React, { useState, useEffect } from 'react'
import { Input, Button } from 'reactstrap'
import EyeIcon from 'mdi-react/EyeIcon'
import KeyVariantIcon from 'mdi-react/KeyVariantIcon'
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon'
import gql from 'graphql-tag'
import styles from './LogInForm.module.scss'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

const LogInForm = props => {
  localStorage.removeItem('token')
  let history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [login, { data, loading, error }] = useMutation(
    gql`
      mutation Login($username: String!, $password: String!) {
        login(username: $username, password: $password)
      }
    `,
    {
      variables: { username: username, password: password },
    }
  )

  useEffect(() => {
    if (error) {
      props.loginError(error)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      localStorage.setItem('token', data.login)
      history.push('/home')
    }
  }, [data])

  return (
    <div className="form">
      <div className="form__form-group">
        <span className="form__form-group-label">Username</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Input value={username} onChange={e => setUsername(e.target.value)} name="username" type="text" placeholder="Name" />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Input
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyUp={e => {
              if (e.keyCode === 13) {
                login(username, password)
              }
            }}
            name="password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
          />
          <button
            id="password"
            type="button"
            className={`form__form-group-button${showPassword ? ' active' : ''}`}
            onClick={e => setShowPassword(!showPassword)}
          >
            <EyeIcon />
          </button>
        </div>
      </div>
      <div className={styles['account__btns']}>
        <button onClick={() => login(username, password)} className={['btn', 'btn-primary', styles['account__btn']].join(' ')}>
          Login
        </button>
      </div>
    </div>
  )
}
export default LogInForm
