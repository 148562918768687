import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import * as Actions from "./Devices.graphql"
import _ from 'lodash'
import { NotificationManager } from 'react-notifications';

const CamerasList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [zonesTable, setZonesTable] = useState([]);
    const [gatewaysTable, setGatewaysTable] = useState([]);
    const [columns, setColumns] = useState([
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Account*', field: 'accountId' },
        { title: 'Zone*', field: 'zoneId' },
        { title: 'Gateway*', field: 'gatewayId' },
        { title: 'Name*', field: 'name' },
        { title: 'Serial*', field: 'serial', editable: 'onAdd' },
        { title: 'Host', field: 'host' },
        { title: 'Schedule', field: 'schedule' },
        {
            title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
                1: "Active", 0: "Inactive"
            }
        },
    ]);

    const { data: zonesData, loading: zonesLoading, error: zonesError, refetch: zonesRefetch } = useQuery(Actions.GET_ZONES, { fetchPolicy: "network-only" })
    const [loadGateways, { data: gatewaysData, loading: gatewaysLoading, error: gatewaysError, refetch: gatewaysRefetch }] = useLazyQuery(Actions.GET_GATEWAYS, { fetchPolicy: "network-only" })
    const [loadDevices, { data, loading, error, refetch }] = useLazyQuery(Actions.GET_DEVICES, { fetchPolicy: "network-only" })
    const [createCamera] = useMutation(Actions.CREATE_CAMERA);
    const [updateCamera] = useMutation(Actions.UPDATE_CAMERA);



    useEffect(() => {
        try {
            if (zonesData) {
                let zones = zonesData.getZones;
                setZonesTable(zones);
                loadGateways();
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [zonesData])

    useEffect(() => {
        try {
            if (gatewaysData) {
                let gateways = gatewaysData.getGateways;
                setGatewaysTable(gateways);
                loadDevices();
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [gatewaysData])



    useEffect(() => {
        try {
            if (zonesTable && gatewaysTable && data && data.getAccounts) {
                let table = data.getAccounts;
                setTable(parseData(table, zonesTable, gatewaysTable));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [zonesData, data])

    const parseData = (data, zonesTable, gatewaysTable) => {
        setColumns(makeLookups(data, zonesTable, gatewaysTable));
        let cameras = []
        data.forEach(account => {
            account.facilities.forEach(facility => {
                facility.zones.forEach(eachZone => {
                    eachZone.devices.camera.forEach(cam => {
                        cameras.push({
                            id: cam.id, accountId: account.id, zoneId: eachZone.id, gatewayId: getGatewayIdFromDevice(facility.gateways, cam.id, 'camera'), name: cam.name, serial: cam.serial,
                            host: cam.host, schedule: cam.schedule, status: cam.active ? 1 : 0
                        });
                    })
                })
            })
        })
        return cameras;
    }

    const getGatewayIdFromDevice = (gateways, deviceId, type) => {
        return gateways.filter(g => g.devices[type].some(each => each.id == deviceId))[0].id
    }

    const makeLookups = (data, zonesTable, gatewaysTable) => {
        let lookupZones = {}, lookupAccounts = {}, lookupGateways = {};
        data.forEach(account => {
            lookupAccounts[(account.id).toString()] = account.name
        })
        zonesTable.forEach(zone => {
            lookupZones[(zone.id).toString()] = zone.name
        })
        gatewaysTable.forEach(gateway => {
            lookupGateways[(gateway.id).toString()] = gateway.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'zoneId') {
                eachCol['lookup'] = lookupZones
            }
            if (eachCol.field == 'accountId') {
                eachCol['lookup'] = lookupAccounts
            }
            if (eachCol.field == 'gatewayId') {
                eachCol['lookup'] = lookupGateways
            }
        })
        return columns;
    }

    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData))) {
                if (newData.accountId && newData.gatewayId && newData.name
                    && newData.serial && newData.zoneId) {
                    let inputs = {
                        account_id: newData.accountId,
                        zone_id: newData.zoneId,
                        gateway_id: newData.gatewayId,
                        name: newData.name,
                        serial: newData.serial,
                        schedule: newData.schedule,
                        host: newData.host,
                        active: newData.status == 1
                    }
                    let result = await updateCamera({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in Camera updation', 'Camera Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'Camera Information', 2000);
                    }
                    await refetch();
                    await zonesRefetch();
                    await gatewaysRefetch();
                }
                else {
                    NotificationManager.error('Mandatory fields are not provided', 'Camera Information', 3000)
                }
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        try {
            if (newData.accountId && newData.gatewayId && newData.name
                && newData.serial && newData.zoneId) {
                let inputs = {
                    account_id: newData.accountId,
                    zone_id: newData.zoneId,
                    gateway_id: newData.gatewayId,
                    name: newData.name,
                    serial: newData.serial,
                    schedule: newData.schedule,
                    host: newData.host
                }
                let result = await createCamera({ variables: { input: inputs } }).catch(e => {
                    NotificationManager.error('Error in Camera creation', 'Camera Information', 3000)
                })
                if (result) {
                    NotificationManager.success('Successfully created', 'Camera Information', 2000);
                }
                await refetch();
                await zonesRefetch();
                await gatewaysRefetch();
            }
            else {
                NotificationManager.error('Mandatory fields are not provided', 'Camera Information', 3000)
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const isLoading = () => {
        return loading || error || errorUI || zonesLoading || zonesError || gatewaysError || gatewaysLoading
    }


    return (
        <Panel md={12} lg={12} xl={12} xs={12} loading={isLoading()}>
            <MaterialTable
                options={{
                    padding: "dense",
                    headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                    exportButton: true,
                    exportAllData: true,
                    pageSize: 5,
                    addRowPosition: "first"
                }}
                title="Cameras"
                columns={columns}
                data={table}
                editable={{
                    onRowAdd: async (newData) => {
                        await handleAddRow(newData);
                    },
                    onRowUpdate: async (newData, oldData) => {
                        await handleSubmitRow(newData, oldData);
                    }
                }}
            />
        </Panel>
    )
}

export default CamerasList
