import React from 'react'
import { Row, Col } from 'reactstrap'

const ZoneDescription = ({ zone }) => {
    return (
        <Row>
            <Col xl={2}><img src="/img/unit-icon.png" alt="" /></Col>
            <Col xl={6} style={{
                color: 'black',
                fontSize: '20px',
            }}>
                <div style={{ display: "flex" }}>
                    <div>{zone.name}</div>
                </div>
            </Col>
        </Row>
    )
}

export default ZoneDescription
