import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Unit from './Components/UnitsList'

const Units = () => {
  return (
    <Container className="dashboard">
      <Unit />
    </Container>
  )
}
export default Units
