import gql from 'graphql-tag'

export const GET_METERS_ACCOUNT = gql`
query {
  getAccounts {
    id,
    name,
    active,
    devices{
      ekm{
        id
        serial,
        name,
      }
    }
    facilities{
      zones{
        id,
        name,
        devices{
          ekm{
            id
            serial,
            name
          }
        }
      }
    }
  }
}`


export const UPDATE_METER = gql`
mutation updateEKM(	
  $id:ID!,
    $input: EKMInput!
    ){
      updateEKM( id:$id,input: $input)
  }`
