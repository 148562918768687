import React, { useState, useEffect } from 'react';
import Panel from '../../../../shared/components/Panel';
import { useQuery } from '@apollo/react-hooks'
import { Col, Row, Button, Card, CardBody } from 'reactstrap';
import gql from 'graphql-tag'
import DatesStack from './DatesStack'
import DownloadIcon from 'mdi-react/DownloadIcon';
import * as Actions from './Davis.graphql'
import moment from 'moment'

const Image = ({ startDate, endDate }) => {
    const noImg = `${process.env.PUBLIC_URL}/img/No_image.png`;
    const [errorUI, seterrorUI] = useState(false);
    const [img, setImg] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);

    const { data, loading, error, refetch } = useQuery(Actions.GET_IMAGES_BY_ZONE, {
        variables: { id: 15, startDate: moment(selectedDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'), endDate: moment(selectedDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') },
    }, { fetchPolicy: "no-cache" })

    const parseImage = (imgData) => {
        let img = {};
        img['src'] = `data:${imgData.mimeType};base64,${imgData.image}`;
        img['timestamp'] = imgData.timestamp;
        img['mimeType'] = imgData.mimeType
        return img;
    }

    useEffect(() => {
        try {
            if (data && data.getImagesByZone[0]) {
                let imageData = data.getImagesByZone.filter(each => each.timestamp == selectedDate);
                setImg(parseImage(imageData[0]));
            }
            else {
                setImg({ src: noImg })
            }
        }
        catch{
            seterrorUI(true);
        }
    }, [data])

    useEffect(() => {
        try {
            if (selectedDate && selectedDate != '') {
                let imageData = data.getImagesByZone.filter(each => each.timestamp == selectedDate);
                if (imageData.length > 0) {
                    setImg(parseImage(imageData[0]));
                }
                else {
                    refetch();
                }
            }
            else {
                setImg({ src: noImg })
            }
        }
        catch{
            seterrorUI(true);
        }
    }, [selectedDate])

    return (
        // <Col md={12} lg={12} xl={12} xs={12}>
        //     <Card>
        //         <CardBody>
        //             <div>
        //                 <div style={{ display: "flex" }}>
        //                     Image
        //                     <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //                         <a href={one} download><DownloadIcon size={25} color='#6C6C6C' /></a>
        //                     </div>
        //                 </div>
        //             </div>
        //             <Row md={12} lg={12} xl={12} xs={12}>
        //                 <DatesStack startDate={startDate} endDate={endDate} selectedDate={(date) => setSelectedDate(date)} />
        //                 <Col md={10} lg={10} xl={10} xs={10}>
        //                     {
        //                         selectedDate == '31/Dec/19' ?
        //                             <img width="auto" height="300px" src={img.src} alt="new" /> :
        //                             <img width="auto" height="250px" src={two} alt="new" />
        //                     }
        //                 </Col>
        //             </Row>
        //         </CardBody>
        //     </Card>
        // </Col>
        <Panel md={12} lg={12} xl={12} xs={12} title="Image" displayCollapse={true} loading={loading || error || errorUI}>
            <Row md={12} lg={12} xl={12} xs={12}>
                <DatesStack startDate={startDate} endDate={endDate} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                <Col md={10} lg={10} xl={10} xs={10}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <a href={img.src} download><DownloadIcon size={25} color='#6C6C6C' /></a>
                    </div>
                    <img width="auto" height="300px" src={img.src} alt="new" />
                </Col>
            </Row>
        </Panel>
    )
}


export default Image
