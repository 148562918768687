import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Switch, useRouteMatch } from 'react-router-dom'
import Home from './'
import ConfigurationRoutes from './Configuration/routes'
import Buildings from './Buildings/routes'

export default () => {
  let match = useRouteMatch()
  return (
    <div>
      <Switch>
        <Route path={`${match.path}/configuration`} component={ConfigurationRoutes} />
        <Route path={`${match.path}/buildings`} component={Buildings} />
        <Route path={`${match.path}/`} component={Home} />
      </Switch>
    </div>
  )
}
