import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import BreadCrumbs from 'shared/components/BreadCrumbs'

const PageView = ({ trail, children, titleRight = '' }) => {
  return (
    <Container className="dashboard">
      <Row style={{ paddingLeft: '15px'}}>
        <Col>
          <BreadCrumbs trail={trail} />
        </Col>
        <Col ><div style={{display:"flex", justifyContent:"flex-end"}}>{titleRight}</div></Col>
      </Row>
      {children}
    </Container>
  )
}
export default PageView
