import gql from 'graphql-tag'

export const GET_FACILITIES = gql`
query {
    getFacilities{
      id,
      zones{
        id,
        name
      }
    }
  }`

export const GET_CLIENTS_ZONES = gql`
query($facilityId:Int!){
    getPowerBillingByFacility(facility_id:$facilityId){
      clients{
        id,
        name,
        email,
        email_enabled,
        active
        zones{
          id
          name
        }
      }
    }
  }`


export const UPDATE_ZONE = gql`
mutation updateZoneToClient($client_id:ID!
    $zone_id: ID!
    ){
    updateZoneToClient(client_id:$client_id,zone_id: $zone_id)
  }`

export const UPDATE_CLIENT = gql`
mutation updateClient(	$id:ID!
    $input: ClientInput!
    ){
    updateClient(id:$id,input: $input)
  }`

export const CREATE_CLIENT = gql`
mutation createClient(
  $input: ClientInput!
  ){
  createClient(input: $input)
}`

export const DELETE_CLIENT = gql`
mutation deleteClient($id:ID!
  ){
    deleteClient(id:$id)
}`
