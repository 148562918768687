import React from 'react'
import { useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import Layout from '../Layout/index'
import Video from './Video/Video'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Profile from 'containers/Profile/index.jsx'

export default () => {
    const { data: meResult, loading, error, refetch } = useQuery(
        gql`
          query {
            me {
              account {
                name
              }
              products {
                id
                name
                suite
              }
            }
          }
        `
    )
    let history = useHistory()
    let match = useRouteMatch()

    useEffect(() => {
        if (meResult) {
            let products = [...new Set(meResult.me.products.filter(product => product.suite === 'Traffic Analysis').map(product => product.name))]
            if (products.includes('Vehicle Counting')) {
                history.push(`${match.path}/video`)
            }
        }
    }, [meResult])
    return (
        <div>
            <Layout />
            <div className="container__wrap">
                <Switch>
                    <Route path={`${match.path}/video`} component={Video} />
                    <Route path={`${match.path}/profile`} component={Profile} />
                </Switch>
            </div>
        </div>
    )
}
