import React from 'react';
// import {
//   LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
// } from 'recharts';
import moment from 'moment'
import { Line } from 'react-chartjs-2';


// const MinMaxTempChart = ({ MinMaxData, Min, Max }) => {
//   debugger;
//   return (
//     <ResponsiveContainer height={250} className="dashboard__area">
//       <LineChart data={MinMaxData} margin={{ top: 20, left: -15, bottom: 20 }}>
//         <XAxis dataKey="date" tick={{ fontSize: 10 }} tickFormatter={(unixTime) => moment(unixTime).utc().format('L')} />
//         <YAxis type="number" tickLine={true} domain={[Min, Max]} />
//         <Tooltip />
//         <Legend />
//         <CartesianGrid />
//         <Line name="High" dataKey="max" stroke="#ff0000" />
//         <Line name="Low" dataKey="min" stroke="#0000ff" />
//       </LineChart>
//     </ResponsiveContainer>
//   )
// }

const MinMaxTempChart = ({ MinMaxData, Unit, Min, Max }) => {
  const chartData = {
    labels: MinMaxData.map(d => moment(d.date).format('MMM DD')),
    datasets: [{
      label: "Min",
      data: MinMaxData.map(d => d.min),
      fill: 'none',
      borderColor: '#0000ff',
      borderWidth: 1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      // lineTension: 0.1
    }, {
      label: "Max",
      data: MinMaxData.map(d => d.max),
      fill: 'none',
      borderColor: '#ff0000',
      borderWidth: 1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 3,
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      pointHitRadius: 10,
      // lineTension: 0.1
    }
    ]
  }

  const options = {
    legend: {
      display: true,
      position: 'bottom'
    },
    scales: {
      yAxes: [{
        gridLines: {
          display: true,
        },
        scaleLabel: {
          display: true,
          labelString: `°${Unit}` 
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          let label = ''
          label += Math.round(tooltipItem.yLabel * 100) / 100;
          label += `°${Unit}`
          return label;
        }
      }
    }
  }
    ;
  return (
    <Line data={chartData} options={options} height="100vh" />
  )
}

export default MinMaxTempChart
