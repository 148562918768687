import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import Dashboard from './Components/Dashboard'

const UnitsDashboard = () => {
  return (
    <Container className="dashboard">
      <Dashboard />
    </Container>
  )
}
export default UnitsDashboard