import React, { useEffect } from 'react'
import { Table, Col, Container, Row, Button } from 'reactstrap'
import Bill from './Bill'
import Bills from './Bills'
import { client } from 'shared/graphql/authentication'
import BreadCrumbs from 'shared/components/BreadCrumbs'

const Billing = () => {
  useEffect(() => {
    //client.clearStore()
  }, [])

  return <Bills />
}
export default Billing
