import gql from 'graphql-tag'

const RISK_HISTORY_QUERY = gql`
  query getFacility($id: ID!) {
    getFacility(id: $id) {
      fireBlight {
        riskHistory {
          date
          level
        }
      }
    }
  }
`

export default RISK_HISTORY_QUERY
