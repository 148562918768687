import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import {
  WiCloudy,
  WiRainMix,
  WiDayCloudy,
  WiDaySunny,
  WiNightSnowWind,
  WiDayWindy,
  WiDayFog,
} from 'weather-icons-react'
import gql from 'graphql-tag'
import moment from 'moment-timezone'

const CurrentTemperature = ({ facilityId, locationId, temperatureUnit }) => {
  const [timezone, setTimezone] = useState('')
  const [temperature, setTemperature] = useState(null)
  const [temperatureIcon, setTemperatureIcon] = useState(null)
  const [time, setTime] = useState(null);
  const [errorTime, setErrorTime] = useState(false)
  const [errorIcon, setErrorIcon] = useState(false)
  const { data, loading, error } = useQuery(
    gql`
      query getFacility($id: ID!) {
        getFacility(id: $id) {
          zones {
            devices {
              bewhere{
                data{
                  temperature
                  timestamp
                }
              }
            }
          }
          fireBlight {
            risk {
              icon
            }
          }
        }
      }
    `,
    {
      variables: { id: facilityId }, fetchPolicy: "no-cache"
    }
  )

  const { data: locationData, loading: locationLoading, error: locationError } = useQuery(
    gql`
      query getLocation($id: ID!) {
        getLocation(id: $id) {
          timezone
        }
      }
    `,
    {
      variables: { id: locationId }, fetchPolicy: "no-cache"
    }
  )

  useEffect(() => {
    try {
      if (data && data.getFacility) {
        setTemperature(data.getFacility.zones[0].devices.bewhere[0].data[0].temperature)
        setTemperatureIcon(data.getFacility.fireBlight.risk[0].icon)
      }
    } catch {
      setErrorIcon(true)
    }
  }, [data, temperatureUnit])


  useEffect(() => {

  }, [])

  useEffect(
    () => {
      if (timezone) {
        const interval = setInterval(() => {
          setTime(moment()
            .tz(timezone)
            .format('MMM DD YYYY hh:mm a'));
        }, 1000);
        return () => {
          clearInterval(interval);
        };
      }
    }, [timezone]);

  useEffect(() => {
    try {
      if (locationData && locationData.getLocation) {
        setTimezone(locationData.getLocation.timezone)
      }
    } catch {
      setErrorTime(true)
    }
  }, [locationData])

  const isLoading = () => {
    return loading || locationLoading
  }
  const isTemperatureError = () => {
    return errorIcon || error != undefined
  }
  const isTimeError = () => {
    return errorTime || locationError != undefined
  }

  const tempConversion = (temp) => {
    return temperatureUnit == "F" ? temp : ((temp - 32)*5)/9 
  }

  return !isLoading() ? (
    <React.Fragment >
        {
          isTemperatureError() ? '' :
            <React.Fragment>
              <div style={{ fontSize: 'larger', marginRight: '2px', marginTop: '4px', color: 'darkgray' }}>
                {
                  temperature == '' ? '' :
                  tempConversion(parseFloat(temperature)).toFixed(1) + `°${temperatureUnit}`
                }
              </div>
              {
                temperatureIcon != null ?
                  <div style={{ marginTop: '-15px' }}>{getWeatherIcon(temperatureIcon)}</div> :
                  <span>&nbsp;&nbsp;&nbsp;</span>
              }
            </React.Fragment>
        }
        {
          isTimeError() ? '' :
            <div style={{ fontSize: 'larger', fontWeight: '400', marginTop: '4px', color: 'darkgray' }}>
              {
                time
              }
            </div>
        }
    </React.Fragment>
  ) : (
      <React.Fragment>
        <Col md={12} lg={12} xl={3} xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}></Col>
      </React.Fragment>
    )
}

const getWeatherIcon = icon => {
  return icon == 'cloudy' ? (
    <WiCloudy size={60} color="#045D8D" />
  ) : icon == 'rain' ? (
    <WiRainMix size={60} color="#045D8D" />
  ) : icon == 'partly-cloudy-day' ? (
    <WiDayCloudy size={60} color="#045D8D" />
  ) : icon == 'clear-day' ? (
    <WiDaySunny size={60} color="#045D8D" />
  ) : icon == 'snow' ? (
    <WiNightSnowWind size={60} color="#045D8D" />
  ) : icon == 'clear-night' ? (
    <WiDayWindy size={60} color="#045D8D" />
  ) : (
                <WiDayFog size={60} color="#045D8D" />
              )
}

export default CurrentTemperature
