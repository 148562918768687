import gql from 'graphql-tag'

export const GET_LOCATIONS = gql`
query {
    getLocations {
      id,
      name,
      city,
      province,
      country,
      timezone,
      latitude,
      longitude,
      active
    }
  }`


export const CREATE_LOCATION = gql`
mutation createLocation(	
  $input: LocationInput!
  ){
    createLocation(input: $input)
}`

export const UPDATE_LOCATION = gql`
mutation updateLocation(	
  $id:ID!
    $input: LocationInput!
    ){
      updateLocation(id:$id, input: $input){
        id
      }
  }`

export const DISABLE_LOCATION = gql`
mutation disableLocation(	
    $id: ID!
    ){
      disableLocation(id: $id){
      id
    }
  }`

