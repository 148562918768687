import React, { useState, useRef, useEffect } from 'react'
import { Row, Button, Input, Col } from 'reactstrap'
import { useMutation } from '@apollo/react-hooks'
import * as Actions from './MeterConfiguration.graphql'

const MeterDescription = ({ meter, callback }) => {
  const [saveMeterReq, setSaveMeterReq] = useState(false);
  const [displayMeterName] = useState('');
  const [renameMeterClick, setRenameMeterClick] = useState(null)
  const [meterID, setMeterID] = useState(meter.id)
  const [meterName, setMeterName] = useState(meter.name)
  const [updateMeterName] = useMutation(Actions.UPDATE_METER)
  const node = useRef()
  const saveNode = useRef()

  const handleClick = e => {
    if (node.current && (node.current.contains(e.target) || saveNode.current.contains(e.target))) {
      return
    }
    //setSaveMeterReq(true)
  }

  useEffect(() => {
    if (saveMeterReq) {
      saveMeter(meterName, meterID)
      setSaveMeterReq(false)
    }
  }, [saveMeterReq])

  useEffect(() => {
    if (renameMeterClick) {
      document.addEventListener('mousedown', handleClick)
    } else {
      document.removeEventListener('mousedown', handleClick)
    }
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [renameMeterClick])

  const saveMeter = async (name, id) => {
    await updateMeterName({
      variables: { id: id, input: { name: name } },
    })
    setRenameMeterClick(false)
    callback()
  }

  return (
    <Row>
      <Col xl={2}><img src="/img/meter-icon.png" alt="" /></Col>
      <Col xl={6} style={{
        color: 'black',
        fontSize: '20px',
      }}>
        {renameMeterClick ? (
          <div style={{ display: 'flex' }}>
            <div ref={node}>
              <Input
                style={{ width: '100px' }}
                type="text"
                name="meterName"
                value={meterName}
                onChange={e => setMeterName(e.currentTarget.value)}
              />
            </div>
            <div>({meter.serial})</div>
          </div>
        ) : (
            <div style={{ display: "flex" }}>
              <div><span style={{ overflow: "hidden", textOverflow: "ellipsis", width: "180px", whiteSpace: "nowrap", display: "inline-block" }}>
                {meter.name}</span> <h5>({meter.serial})</h5></div>
            </div>
          )}
      </Col>
      <Col xl={3}>
        {
          <div ref={saveNode}>
            {renameMeterClick ? (
              <Button
                outline
                color="success"
                style={{ width: '88px', marginBottom: '0px', marginRight: '2px' }}
                onClick={() => saveMeter(meterName, meterID)}
              >
                Save
              </Button>
            ) : (
                <Button outline color="primary" style={{ marginBottom: '0px', marginRight: '2px' }} onClick={() => setRenameMeterClick(true)}>
                  Rename
              </Button>
              )}
          </div>
        }
      </Col>
    </Row>
  )
}

export default MeterDescription
