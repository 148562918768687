import React, { useState, useEffect } from 'react'
import {
  ButtonToolbar,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Row,
  ButtonGroup,
  Button,
} from 'reactstrap'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import { withSize } from 'react-sizeme'

const DaysFilter = ({ daysCallback, size }) => {
  const [alignMobile, setalignMobile] = useState('flex-end')
  const [days, setDays] = useState(['30', 'Last 30 Days'])
  const fixedDays = [
    { id: '7', name: 'Last 7 Days' },
    { id: '15', name: 'Last 15 Days' },
    { id: '30', name: 'Last 30 Days' },
  ]
  const handleDays = e => {
    setDays([e.currentTarget.getAttribute('id'), e.currentTarget.textContent])
    daysCallback(parseInt(e.currentTarget.getAttribute('id')))
  }

  useEffect(() => {
    if (size.width <= 450) {
      setalignMobile('flex-start')
    } else {
      setalignMobile('flex-end')
    }
  }, [size])
  return (
    <React.Fragment md={12} lg={12} xl={6} xs={12} style={{ display: 'flex', justifyContent: alignMobile }}>
      <ButtonToolbar style={{ justifyContent: 'center' }}>
        <UncontrolledDropdown>
          <ButtonGroup>
            <Button color="primary" outline>
              {days[1]}{' '}
            </Button>
            <DropdownToggle color="primary" className="icon icon--right">
              <ChevronDownIcon />
            </DropdownToggle>
          </ButtonGroup>
          <DropdownMenu right className="dropdown__menu">
            {fixedDays.map(fixedDay => {
              return (
                <DropdownItem id={fixedDay.id} key={fixedDay.id} onClick={e => handleDays(e)}>
                  {fixedDay.name}{' '}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonToolbar>
    </React.Fragment>
  )
}

export default withSize()(DaysFilter)
