import React, { useState, useEffect } from 'react';
import Panel from '../../../../shared/components/Panel';
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import EIPChart from '../../../../shared/components/Dashboard/EIPChart'
import * as utls from 'shared/utls/index.js'

const EIP = ({ facilityId, filterDays }) => {
    const [errorUI, seterrorUI] = useState(false);
    const [EIPdata, setEIPdata] = useState([]);
    const { data, loading, error } = useQuery(gql`
  query getFacility($id: ID!) {
      getFacility(id: $id) {
          fireBlight {
            riskHistory {
                  date
                  EIP
              }
              risk {
                date
                EIP
            }
          }
      }
  }`, {
        variables: { id: facilityId },
    })
    if (error) console.log(error);

    useEffect(() => {
        try {
            if (data && data.getFacility) {
                let { risk, riskHistory } = data.getFacility.fireBlight;
                setEIPdata(generateChartData([risk[0], ...riskHistory], filterDays))
            }
        } catch {
            seterrorUI(true)
        }
    }, [data, filterDays])


    return (
        <Panel md={6} lg={6} xl={6} xs={12} title="EIP" loading={loading || error || errorUI}>
            <EIPChart eipData={EIPdata} />
        </Panel>
    )
}

const generateChartData = (EIPdata, filterDays) => {
    let chartdata = [];
    let dateFrom = new Date(new Date().setDate(new Date().getDate() - filterDays));
    EIPdata.filter(eachdate => {
        return new Date(eachdate.date) > dateFrom
    }).map((d, i) => {
        chartdata.push({
            date: d.date,
            EIP: d.EIP == "-" ? "0" : d.EIP
        });
    })
    return utls.sortByDate(chartdata, false);
}

export default EIP
