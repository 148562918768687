import React from 'react'
import { Container, Row } from 'reactstrap'
import BeWhereList from './components/BeWhereList'
import CamerasList from './components/CamerasList'
import EKMList from './components/EKMList'
import DavisList from './components/DavisList'


const Devices = () => {
    return (
        <Container className="dashboard">
            <Row>
                <BeWhereList />
                <DavisList />
                <CamerasList />
                <EKMList />
            </Row>
        </Container>
    )
}

export default Devices