import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Image from './components/Image'
import DatesFilter from './components/DatesFilter'
import WeatherTable from './components/WeatherTable'

const Dashboard = () => {
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 3)))
    const [endDate, setEndDate] = useState(new Date())
    return (
        <Container className="dashboard">
            <Row style={{ marginBottom: "10px" }}>
                <Col md={12} lg={12} xl={4} xs={12}>
                    <h3 className="page-title" style={{ display: 'inline' }}>Davis WeatherStation</h3>
                </Col>
                <DatesFilter selectedStartDate={(startDate) => setStartDate(startDate)} selectedEndDate={(endDate) => setEndDate(endDate)} />
            </Row>
            <Row>
                <Image startDate={startDate} endDate={endDate} />
            </Row>
            <Row>
                <WeatherTable startDate={startDate} endDate={endDate} />
            </Row>
        </Container>
    )
}

export default Dashboard