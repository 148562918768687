import React from 'react'
import { useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import Layout from '../Layout/index'
import PowerBilling from './PowerBilling/routes'
import BuildingDashboard from './Dashboard/routes'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import Profile from 'containers/Profile/index.jsx'

export default () => {
  const { data: meResult, loading, error, refetch } = useQuery(
    gql`
      query {
        me {
          account {
            name
          }
          products {
            id
            name
            suite
          }
        }
      }
    `
  )
  let history = useHistory()
  let match = useRouteMatch()

  useEffect(() => {
    if (meResult) {
      let products = [...new Set(meResult.me.products.filter(product => product.suite === 'Smart Building').map(product => product.name))]
      if (products.includes('Power Billing')) {
        history.push(`${match.path}/power-billing`)
      } else if (products.includes('SmartThings')) {
        history.push(`${match.path}/smartthings`)
      } else if (products.includes('Dashboard')) {
        history.push(`${match.path}/dashboard`)
      }
    }
  }, [meResult])

  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Switch>
          <Route path={`${match.path}/power-billing`} component={PowerBilling} />
          <Route path={`${match.path}/smartthings`} component={BuildingDashboard} />
          <Route path={`${match.path}/profile`} component={Profile} />
        </Switch>
      </div>
    </div>
  )
}
