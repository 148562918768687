import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import Panel from 'shared/components/Panel';
import Filter from './Filter'
import * as Actions from './Davis.graphql'
import MaterialTable from 'material-table';
import _ from 'lodash'

const GatewaysAndZones = () => {
    const [facilities, setFacilities] = useState([]);
    const [errorUI, setErrorUI] = useState(false);
    const [facilityId, setFacilityId] = useState(null);
    const [gatewaysColumns, setGatewaysColumns] = useState([
        { title: 'Facility', field: 'facId' },
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Name', field: 'name' },
        { title: 'Serial', field: 'serial', editable: 'onAdd' }
    ])
    const [davisColumns, setDavisColumns] = useState([
        { title: 'Facility', field: 'facId' },
        { title: 'Zone', field: 'zoneId' },
        { title: 'Gateway', field: 'gatewayId' },
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Serial', field: 'serial', editable: 'onAdd' },
        { title: 'Host', field: 'host' },
        { title: 'Schedule', field: 'schedule' }
    ])
    const [camerasColumns, setCamerasColumns] = useState([
        { title: 'Facility', field: 'facId' },
        { title: 'Zone', field: 'zoneId' },
        { title: 'Gateway', field: 'gatewayId' },
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Name', field: 'name' },
        { title: 'Host', field: 'host' },
        { title: 'Schedule', field: 'schedule' }
    ])
    const [gatewaysTable, setGatewaysTable] = useState([])
    const [camerasTable, setCamerasTable] = useState([])
    const [davisTable, setDavisTable] = useState([])

    const { data: facilityData, loading: facilityLoading, error: facilityError, refetch } = useQuery(Actions.GET_GATEWAYS_AND_ZONES_BY_FACILITY,
        { variables: { id: facilityId } }, { fetchPolicy: "no-cache" });

    const [createGateway] = useMutation(Actions.CREATE_GATEWAY);
    const [editGateway] = useMutation(Actions.EDIT_GATEWAY);
    const [createCamera] = useMutation(Actions.CREATE_CAMERA);
    const [editCamera] = useMutation(Actions.EDIT_CAMERA);
    const [createDavis] = useMutation(Actions.CREATE_DAVIS);
    const [editDavis] = useMutation(Actions.EDIT_DAVIS);


    useEffect(() => {
        try {
            if (facilityData && facilityData.getFacility) {
                parseFacility(facilityData.getFacility);
                setGatewaysColumns(makeFacilityLookup(facilities, gatewaysColumns));
                setDavisColumns(makeZonesFacilityGatewayLookup(facilityData.getFacility.zones, facilities, facilityData.getFacility.gateways, davisColumns));
                setCamerasColumns(makeZonesFacilityGatewayLookup(facilityData.getFacility.zones, facilities, facilityData.getFacility.gateways, camerasColumns));
            }
        }
        catch{
            setErrorUI(true);
        }
    }, [facilityData, facilityId, facilities])

    const parseFacility = (facility) => {
        let gatewayTable = [];
        facility.gateways.forEach(gateway => {
            gatewayTable.push({ facId: facility.id, ...gateway });
        })
        setGatewaysTable(gatewayTable);
        parseDavisAndCamera(facility);
    }

    const parseDavisAndCamera = (facility) => {
        let davisTable = [], cameraTable = [];
        facility.zones.forEach(zone => {
            zone.devices.davis.forEach(davis => {
                davisTable.push({ facId: facility.id, zoneId: zone.id, gatewayId: getGatewayIdFromDevice(facility.gateways, davis.id, 'davis'), id: davis.id, serial: davis.serial, schedule: davis.schedule, host: davis.host });
            })
            zone.devices.camera.forEach(camera => {
                cameraTable.push({ facId: facility.id, zoneId: zone.id, gatewayId: getGatewayIdFromDevice(facility.gateways, camera.id, 'camera'), id: camera.id, name: camera.name, host: camera.host, schedule: camera.schedule, });
            })
        })
        setDavisTable(davisTable);
        setCamerasTable(cameraTable);
    }

    const getGatewayIdFromDevice = (gateways, deviceId, type) => {
        return gateways.filter(g => g.devices[type].some(each => each.id == deviceId))[0].id
    }

    const makeFacilityLookup = (facilities, columns) => {
        let lookupFacilities = {};
        facilities.forEach(eachFacility => {
            lookupFacilities[(eachFacility.id).toString()] = eachFacility.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'facId') {
                eachCol['lookup'] = lookupFacilities
            }
        })
        return columns;
    }

    const makeZonesFacilityGatewayLookup = (zones, facilities, gateways, columns) => {
        let lookupFacilities = {}, lookupZones = {}, lookupGateways = {};
        facilities.forEach(eachFacility => {
            lookupFacilities[(eachFacility.id).toString()] = eachFacility.name
        })
        zones.forEach(eachZone => {
            lookupZones[(eachZone.id).toString()] = eachZone.name
        })
        gateways.forEach(eachGateway => {
            lookupGateways[(eachGateway.id).toString()] = eachGateway.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'facId') {
                eachCol['lookup'] = lookupFacilities
            }
            if (eachCol.field == 'zoneId') {
                eachCol['lookup'] = lookupZones
            }
            if (eachCol.field == 'gatewayId') {
                eachCol['lookup'] = lookupGateways
            }
        })
        return columns;
    }

    const handleSubmitDavisRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData) && (newData.gateway_id && newData.zone_id && newData.id && newData.serial && newData.schedule && newData.host))) {
                let inputs = {
                    id: newData.id,
                    serial: newData.serial,
                    zone_id: newData.zoneId,
                    gateway_id: newData.gatewayId,
                    host: newData.host,
                    schedule: newData.schedule,
                }
                await editDavis({ variables: inputs })
                refetch();
            }
        }
        catch{
            setErrorUI(true);
        }
    }

    const handleSubmitCamerasRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData) && (newData.gateway_id && newData.zone_id && newData.id && newData.name && newData.schedule && newData.host))) {
                let inputs = {
                    id: newData.id,
                    name: newData.name,
                    zone_id: newData.zoneId,
                    gateway_id: newData.gatewayId,
                    host: newData.host,
                    schedule: newData.schedule,
                }
                await editCamera({ variables: inputs })
                refetch();
            }
        }
        catch{
            setErrorUI(true);
        }
    }

    const handleSubmitGatewaysRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData) && (newData.id && newData.serial && newData.name && newData.facId))) {
                let inputs = {
                    id: newData.id,
                    name: newData.name,
                    serial: newData.serial,
                    facility_id: newData.facId,
                }
                await editGateway({ variables: inputs })
                refetch();
            }
        }
        catch{
            setErrorUI(true);
        }
    }

    const handleCreateDavisRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData) && (newData.gateway_id && newData.zone_id && newData.serial && newData.schedule && newData.host))) {
                let inputs = {
                    serial: newData.serial,
                    zone_id: newData.zoneId,
                    gateway_id: newData.gatewayId,
                    host: newData.host,
                    schedule: newData.schedule,
                }
                await createDavis({ variables: inputs })
                refetch();
            }
        }
        catch{
            setErrorUI(true);
        }
    }

    const handleCreateCamerasRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData) && (newData.gateway_id && newData.zone_id && newData.name && newData.schedule && newData.host))) {
                let inputs = {
                    name: newData.name,
                    zone_id: newData.zoneId,
                    gateway_id: newData.gatewayId,
                    host: newData.host,
                    schedule: newData.schedule,
                }
                await createCamera({ variables: inputs })
                refetch();
            }
        }
        catch{
            setErrorUI(true);
        }
    }

    const handleCreateGatewaysRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData) && (newData.serial && newData.name && newData.facId))) {
                let inputs = {
                    name: newData.name,
                    serial: newData.serial,
                    facility_id: newData.facId,
                }
                await createGateway({ variables: inputs })
                refetch();
            }
        }
        catch{
            setErrorUI(true);
        }
    }


    return (
        <React.Fragment>
            <Filter callbackFacilityID={setFacilityId} callbackFacilities={setFacilities} />
            <Panel md={12} lg={12} xl={12} loading={facilityLoading || facilityError || errorUI}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 5
                    }}
                    title="Gateways"
                    columns={gatewaysColumns}
                    data={gatewaysTable}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleCreateGatewaysRow(newData);
                            gatewaysTable.push(newData);
                            setGatewaysTable(gatewaysTable);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitGatewaysRow(newData, oldData);
                            gatewaysTable[gatewaysTable.indexOf(oldData)] = newData
                            setGatewaysTable(gatewaysTable);
                        }
                    }}
                />
            </Panel>
            <Panel md={12} lg={12} xl={12} loading={facilityLoading || facilityError || errorUI}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 5
                    }}
                    title="Davis Devices"
                    columns={davisColumns}
                    data={davisTable}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleCreateDavisRow(newData);
                            davisTable.push(newData);
                            setDavisTable(davisTable);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitDavisRow(newData, oldData);
                            davisTable[davisTable.indexOf(oldData)] = newData
                            setDavisTable(davisTable);
                        }
                    }}
                />
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 5
                    }}
                    title="Cameras"
                    columns={camerasColumns}
                    data={camerasTable}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleCreateCamerasRow(newData);
                            camerasTable.push(newData);
                            setCamerasTable(camerasTable);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitCamerasRow(newData, oldData);
                            camerasTable[camerasTable.indexOf(oldData)] = newData
                            setCamerasTable(camerasTable);
                        }
                    }}
                />
            </Panel>
        </React.Fragment>
    )
}

export default GatewaysAndZones