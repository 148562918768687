import React from 'react'
import Chart from 'react-apexcharts'
import moment from 'moment'

const RiskHistoryChart = ({ chartData }) => {
    return (
        <Chart options={chartOptions} series={getChartData(chartData)} type="heatmap" height="250" />
    )
}
export default RiskHistoryChart


const chartOptions = {
    plotOptions: {
        heatmap: {
            shadeIntensity: 0.5,
            colorScale: {
                ranges: [
                    {
                        from: -0.1,
                        to: 0.1,
                        name: 'No data',
                        color: '#8c8585',
                    },
                    {
                        from: 0.9,
                        to: 1.1,
                        name: 'No risk',
                        color: '#8BC85C',
                    },
                    {
                        from: 1.9,
                        to: 2.1,
                        name: 'Low',
                        color: '#5BA318',
                    },
                    {
                        from: 2.9,
                        to: 3.1,
                        name: 'Medium',
                        color: '#DEE300',
                    },
                    {
                        from: 3.9,
                        to: 4.1,
                        name: 'High',
                        color: '#FABC04',
                    },
                    {
                        from: 4.9,
                        to: 5.1,
                        name: 'Infection',
                        color: '#EC3F0A',
                    },
                ],
            },
        },
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        tickAmount: 2,
        labels: {
            rotate: -35,
            rotateAlways: true
        }
    },
    chart: {
        toolbar: {
            show: false,
        },
    },
}
const getChartData = (riskData, numberOfWeeks = 12) => {
    let weekStartDates = getWeekStartDates(numberOfWeeks)
    let chartData = []
    for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        chartData[dayOfWeek] = getDayOfWeekData(riskData, dayOfWeek, weekStartDates)
    }
    return chartData
}
const getDayOfWeekData = (riskData, dayOfWeek, weekStartDates) => {
    let dayOfWeekData = {
        name: moment()
            .day(dayOfWeek)
            .format('ddd'),
        data: [],
    }
    let riskToNumber = {
        "No Data": 0,
        "No Risk": 1,
        "Low": 2,
        "Medium": 3,
        "High": 4,
        "Infection": 5,
    }
    weekStartDates.forEach(startDate => {
        let currentDate = moment(startDate, 'YY MMM DD')
            .day(dayOfWeek)
            .format('YYYY-MM-DD');
        let riskLevel = riskData
            .filter(risk => { return risk['date'] == currentDate })
            .reduce((_, risk) => {
                return risk['level'] == "" ? riskToNumber['No Data'] : risk['level'] == "-" ? riskToNumber['No Risk'] : riskToNumber[risk['level']]
            }, 0)
        dayOfWeekData.data.push({
            x: moment(startDate, 'YY MMM DD').format('MMM DD'),
            y: riskLevel,
        })
    })
    return dayOfWeekData
}
const getWeekStartDates = (numberOfWeeks = 16) => {
    let weekStartDates = [
        moment()
            .startOf('week')
            .format('YY MMM DD'),
    ]
    for (let i = 1; i < numberOfWeeks; i++) {
        let prevWeekDate = moment(weekStartDates[i - 1], 'YY MMM DD')
        let currentWeekDate = prevWeekDate.subtract(1, 'week').format('YY MMM DD')
        weekStartDates.push(currentWeekDate)
    }
    return weekStartDates.reverse()
}
