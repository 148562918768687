import React, { useState, useEffect } from 'react'
import Panel from '../../../../shared/components/Panel'
import { useQuery } from '@apollo/react-hooks'
import FORECAST_WEATHER_QUERY from './ForecastWeather.graphql'
import { FormGroup, Form, Label, Input, } from 'reactstrap';
import RiskForcastWidget from '../../../../shared/components/Dashboard/RiskForecastWidget'
import { SizeMe } from 'react-sizeme'
import * as utls from 'shared/utls/index.js'

const ForecastWeather = ({ facilityId }) => {
  const [forecastData, setForecastData] = useState([])
  const [currentTemperature, setCurrentTemperature] = useState(null)
  const [forecastRadio, setForecastRadio] = useState(true);
  const [errorUI, setErrorUI] = useState(false)
  const { data, loading, error } = useQuery(FORECAST_WEATHER_QUERY, {
    variables: { id: facilityId },
  })

  useEffect(() => {
    try {
      if (data && data.getFacility) {
        setCurrentTemperature(data.getFacility.zones[0].devices.bewhere[0].data[0].temperature)
        let { risk, riskHistory } = data.getFacility.fireBlight;
        forecastRadio ?
          setForecastData(utls.sortByDate(risk, false).slice(1, 4)) :
          setForecastData(utls.sortByDate(riskHistory).slice(0, 3))
      }
    } catch {
      setErrorUI(true)
    }
  }, [data, forecastRadio])


  const isLoading = () => {
    return loading || error || errorUI || currentTemperature === null || forecastData.length === 0
  }

  const toggleRadio = (e) => {
    setForecastRadio(e.target.value == 'on' ? !forecastRadio : '');
  }

  const getHistForecast = () => {
    return (
      // <div>
      //   <CustomInput type="radio" label="Forecast" checked={forecastRadio}
      //     onChange={(e) => toggleRadio(e)}
      //     inline />
      //   <CustomInput type="radio" label="History" checked={!forecastRadio}
      //     onChange={(e) => toggleRadio(e)} inline />
      // </div>
      <Form style={{ textTransform: "none" }}>
        <FormGroup check inline style={{ marginLeft: "10px" }}>
          <Label check>
            <Input type="radio" checked={forecastRadio} onChange={(e) => toggleRadio(e)} /> Forecast
        </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <Input type="radio" checked={!forecastRadio} onChange={(e) => toggleRadio(e)} /> History
        </Label>
        </FormGroup>
      </Form>
    )
  }

  return (
    <Panel md={12} lg={12} xl={5} xs={12} title={'Risk'} loading={isLoading()} titleCenter={getHistForecast()}>
      <SizeMe>
        {({ size }) => <RiskForcastWidget ForecastData={forecastData} displayforecast={forecastRadio} currentTemperature={currentTemperature} />}
      </SizeMe>
    </Panel>
  )
}

export default ForecastWeather
