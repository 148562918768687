import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'reactstrap'
import LightbulbOnIcon from 'mdi-react/LightbulbOnIcon'
import LightbulbOffIcon from 'mdi-react/LightbulbOffIcon'
import styles from './Dashboard.module.scss'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from './UnitDashboard.graphql'
import { isEqual } from 'lodash'
import { ScaleLoader } from "react-spinners";
import Slider from 'shared/components/range_slider/Slider';


const Bulb = ({ device, screensize, refetch, smartThingsRefetch }) => {
    const [name, setName] = useState('');
    const [status, setStatus] = useState(false);
    const [serial, setSerial] = useState('');
    const [mouseDown, setMouseDown] = useState(false);
    const [toLoad, setToLoad] = useState(false);
    const [OnOffSwitch] = useMutation(Actions.SWITCH_ON_OFF);
    const [dimmerLevel, setDimmerLevel] = useState(50);
    const [errorUI, setErrorUI] = useState(false);

    function useDeepEffect(fn, deps) {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) =>
                isEqual(obj, deps[index])
            );

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        }, deps);
    }

    useDeepEffect(() => {
        try {
            if (device && device.id) {
                setName(device.name);
                setSerial(device.id);
                setStatus(device.status == 'true');
                setDimmerLevel(parseInt(device.brightness))
            }
        }
        catch{
            setErrorUI(true);
        }

    }, [device])




    const handleOnClick = async (serial, status) => {
        if (serial) {
            setToLoad(true);
            await OnOffSwitch({ variables: { smartId: serial, command: status ? 'off' : 'on' } });
            await refetch();
            await smartThingsRefetch();
            setToLoad(false);
        }
    }

    const handleOnLevelChange = async (level) => {
        if (serial) {
            setToLoad(true);
            await OnOffSwitch({ variables: { smartId: serial, command: level.toString() } });
            await refetch();
            await smartThingsRefetch();
            setToLoad(false);
        }
    }

    const getDevice = (status) => {
        let compareDevice = status ? '1' : '0'
        switch (compareDevice) {
            case '1':
                return [<LightbulbOnIcon size={60} color='#ffffff' />, 'On']
            case '0':
                return [<LightbulbOffIcon size={60} color='#00000' />, 'Off']
            default:
                return ['', '']
        }
    }


    let displayDevice = getDevice(status);
    return (
        errorUI ? '' :
            <React.Fragment>
                <Row>
                    <Col style={{ paddingRight: "0px" }}>
                        <div
                            className={[screensize.width > 400 ? styles['box'] : styles['box_mobile'], status ? styles['deviceOn'] : '', mouseDown ? styles['mouseDown'] : ''].join(' ')}
                            onMouseDown={() => {
                                setMouseDown(true)
                            }}
                            onMouseUp={() => setMouseDown(false)}
                            onMouseLeave={() => setMouseDown(false)}
                            onClick={() => handleOnClick(serial, status)}>
                            {
                                toLoad ? <div style={{ padding: "40px" }}>
                                    <ScaleLoader
                                        height={40}
                                        color={"#004B8D"}
                                        width={7}
                                        margin={3}
                                        loading={toLoad}
                                    />
                                </div> : <div>
                                        <div className="card__title">
                                            <h5 style={{ paddingTop: '5px', color: status ? '#ffffff' : '#000000' }} className={[styles['bold-text'], styles['flex-justify-center']].join(' ')}>
                                                {name}
                                            </h5>
                                        </div>
                                        <div className={["dashboard__health-chart", styles['flex-justify-center']].join(" ")}>
                                            {
                                                displayDevice[0]
                                            }
                                        </div>
                                    </div>

                            }
                            <div>
                                <span style={{ display: "flex", justifyContent: 'center' }}>{displayDevice[1]}</span>
                            </div>
                        </div>
                    </Col>
                    <Col style={{ paddingLeft: "0px" }}>
                        <DimmerSlider dimmerLevel={dimmerLevel} setDimmerLevel={handleOnLevelChange} disabled={!status} />
                    </Col>
                </Row>
            </React.Fragment>
    )
}

const DimmerSlider = ({ dimmerLevel, setDimmerLevel, disabled }) => {
    return (
        <Slider min={1} max={100} value={dimmerLevel} vertical={true} onsliderChange={setDimmerLevel} disabled={disabled} />
    )
};



export default Bulb