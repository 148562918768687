import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import Avatar from 'react-avatar';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { NotificationManager } from 'react-notifications';
import * as Actions from "./Profile.graphql"

const Picture = () => {
    const [uploadPicture] = useMutation(Actions.UPLOAD_PICTURE);
    const { data, loading, error, refetch } = useQuery(Actions.GET_PROFILE_PICTURE, { fetchPolicy: "network-only" });
    const [errorUI, setErrorUI] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [image, setImage] = useState('');

    useEffect(() => {
        try {
            if (data && data.getProfilePicture) {
                setImage(data.getProfilePicture);
            }
        }
        catch{
            setErrorUI(true)
        }
    }, [data])

    useEffect(() => {
        try {
            if (error) {
                setImage('');
            }
        }
        catch{
            setErrorUI(true)
        }
    }, [error])

    const parseImage = (imgData) => {
        if (imgData.filename && imgData.encoding) {
            let img = `data:${imgData.filename};base64,${imgData.encoding}`;
            return img;
        }
    }

    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();

            reader.onload = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        })
    }

    const handleUpload = async (e) => {
        try {
            let inputFile = e.target.files[0];
            if (inputFile.type.includes('image')) {
                if (inputFile.size < 32000000) {
                    let binaryStr = await readFileAsync(inputFile).catch(e => {
                        NotificationManager.error('Error in uploading', 'User Information', 3000)
                    })
                    let inputs = {
                        filename: inputFile.name,
                        encoding: binaryStr
                    }
                    let result = await uploadPicture({ variables: { input: inputs } })
                        .catch(e => {
                            NotificationManager.error('Error in uploading', 'User Information', 3000)
                        })
                    if (result) {
                        NotificationManager.success('Successfully saved', 'User Information', 2000);
                        await refetch();
                    }
                }
                else {
                    setErrorMsg('Image should be less than 4MB');
                }
            }
            else {
                setErrorMsg('Not an image');
            }
        }
        catch{
            setErrorUI(true);
        }
    }

    const isLoading = () => {
        return errorUI || loading
    }

    return (
        isLoading() ? '' :
            <React.Fragment>
                <Row>
                    <div style={{ marginBottom: "10px", margin: "auto" }}>
                        <Avatar name={image == '' ? "Hanatech IoT" : null} size="150" round={true} src={image == '' ? null : image} />
                    </div>
                </Row >
                <Row>
                    <div style={{ marginBottom: "10px", margin: "auto" }}>
                        <input type="file" style={{ visibility: "" }} onChange={handleUpload} />
                    </div>
                </Row>
                <Row>
                    <div style={{ marginBottom: "10px", margin: "auto" }}>
                        <p>{errorMsg}</p>
                    </div>
                </Row>
            </React.Fragment>
    )
}

export default Picture