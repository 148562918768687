import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Table } from 'reactstrap';
import { useRouteMatch } from 'react-router-dom'
import gql from 'graphql-tag'

const ThermostatZonePolicies = () => {
    let match = useRouteMatch();
    let zoneID = match.params.UnitId;
    const GET_ZONE = gql`
    query GET_ZONE($id: ID!){
        getZone(id:$id){
              id
              name
                HVAC{
                policies{
                  custom{
                    policy_name
                    weekday
                    start_date
                    end_date
                    heating_temp_max
                    cooling_temp_min
                    status
                  }
                  default{
                    policy_name
                    heating_temp_max
                    cooling_temp_min
                  }
                }
             
          }
        }
      }`

    const [defaultPolicy, setDefaultPolicy] = useState({});
    const [customPolicies, setCustomPolicies] = useState([]);
    const { data, zoneLoading, zoneError, refetch } = useQuery(GET_ZONE, 
        {
            variables: { id: zoneID }, fetchPolicy:"no-cache"
        }
    )

    useEffect(() => {
        if(data){
            let dp = data.getZone.HVAC.policies.default
            let cps = data.getZone.HVAC.policies.custom
            if(dp){
                setDefaultPolicy(dp)
            }
            
            if(cps.length > 0)
                setCustomPolicies(cps)
        }
        
    }, [data])

    if (zoneLoading) return ''
    if (zoneError) return ''

    return(
        <React.Fragment>
            <h3 style = {{color: "black"}}>Thermostat Policies</h3>
            <h5 style = {{paddingTop: "30px", color: "black"}}>Default</h5>
             <Table borderless>
                <thead>
                    <tr>
                        <th>Policy name</th>
                        <th>Min temperature</th>
                        <th>Max temperature</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        defaultPolicy ? 
                        <tr>
                            <td> {defaultPolicy.policy_name}</td>
                            <td> {defaultPolicy.cooling_temp_min}</td>
                            <td> {defaultPolicy.heating_temp_max}</td>
                        </tr> : "No records"
                    }
                    
                </tbody>
             </Table>
             <h5 style = {{color: "black"}}>Custom</h5>
             <Table borderless>
                <thead>
                    <tr>
                        <th>Policy name</th>
                        <th>Weekday</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Min temperature</th>
                        <th>Max temperature</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        customPolicies.length > 0 ?
                        customPolicies.map(policy => {
                            return(
                                <tr>
                                <td> {policy.policy_name}</td>
                                <td> {policy.weekday}</td>
                                <td> {policy.start_date}</td>
                                <td> {policy.end_date}</td>
                                <td> {policy.heating_temp_max}</td>
                                <td> {policy.cooling_temp_min}</td>
                                <td> {policy.status ? 1 : 0}</td>
                                </tr>
                            ) 
                        }) : "No records"
                    }
                </tbody>
             </Table>
        </React.Fragment>
    )
}

export default ThermostatZonePolicies