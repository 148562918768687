import React, { useEffect, useState } from 'react'
import { useApolloClient } from "@apollo/react-hooks";

const Idle = ({ onIdle, timeout }) => {
  // const [signoutTime, setSignoutTime] = useState(timeout)
  let logoutTimeout

  const logout = () => {
    const client = useApolloClient();
    client.clearStore()
    console.log('You have been loged out')
    onIdle()
    clearTimeouts()
    setTimeouts()
  }

  // const destroy = () => {
  //     console.log('Session destroyed');
  // }
  const setTimeouts = () => {
    logoutTimeout = setTimeout(logout, timeout)
  }

  const clearTimeouts = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout)
  }

  useEffect(() => {
    const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress']

    const resetTimeout = () => {
      clearTimeouts()
      setTimeouts()
    }

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout)
    }

    setTimeouts()
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout)
        clearTimeouts()
      }
    }
  }, [])

  return <div></div>
}
export default Idle
