import gql from 'graphql-tag'

const FORECAST_WEATHER_QUERY = gql`
query getFacility($id: ID!) {
    getFacility(id: $id) {
        zones{
            devices{
                bewhere{
                    data{
                        temperature
                    }
                }
            }
            }
        fireBlight {
            risk {
                date
                dayOfWeek
                icon
                minTemp
                maxTemp
                level
            }
            riskHistory {
                date
                dayOfWeek
                icon
                minTemp
                maxTemp
                level
            }
        }
    }
}
`

export default FORECAST_WEATHER_QUERY