import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, ListGroup, ListGroupItem } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import * as Actions from './Davis.graphql'
import moment from 'moment'


const EachDate = ({ date, setSelectedDate, selectedDate }) => {
    const handleDateClick = (e) => {
        setSelectedDate(e.currentTarget.innerText);
    }
    return (
        <ListGroupItem onClick={(e) => handleDateClick(e)} action active={selectedDate == date}>{date}</ListGroupItem>
    )
}

const DatesStack = ({ startDate, endDate, setSelectedDate, selectedDate }) => {
    const [dates, setDates] = useState([]);
    const [errorUI, setErrorUI] = useState(false);
    const { data, loading, error, refetch } = useQuery(Actions.GET_DATES_BY_ZONE, {
        variables: { id: 15, startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') },
    })
    useEffect(() => {
        try {
            if (data && data.getImagesByZone) {
                if (data.getImagesByZone.length == 0) {
                    setDates([]);
                    setSelectedDate('');
                }
                else {
                    let dates = []
                    setSelectedDate(data.getImagesByZone[0].timestamp);
                    data.getImagesByZone.forEach(eachDate => {
                        dates.push(eachDate.timestamp)
                    })
                    setDates(dates);
                }
            }
        }
        catch{
            setErrorUI(true)
        }
    }, [data])

    useEffect(() => {
        if (startDate && endDate) {
            refetch();
        }
    }, [startDate, endDate])

    return (
        loading || error != undefined || errorUI ? '' : <Col md={2} lg={2} xl={2} xs={2}>
            <div style={{ overflowY: "auto", width: "100%", height: "250px", borderColor: "#515966", borderStyle: "solid", borderWidth: "thin" }}>
                <ListGroup>
                    {
                        dates.map((date, i) => {
                            return <EachDate i={i} date={date} setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                        })
                    }
                </ListGroup>
            </div>
        </Col>
    )
}



export default DatesStack
