import gql from 'graphql-tag'

export const GET_DEVICES = gql`
query{
  getAccounts{
    id,
    name
    facilities{
      id,
      name,
      zones{
        id, 
        name
        devices{
      ekm{
        id,
        name,
        serial,
        timezone,
        active,
        status
      }
      camera{
        id,
        name,
        serial,
        host,
        schedule,
        timezone,
        active
      }
      davis{
        id,
        name,
        serial,
        host,
        schedule,
        timezone,
        active
      }
      bewhere{
        id,
        name,
        serial,
        latitude,
        longitude,
        sim,
        imsi,
        timezone,
        active
      }
    }
      }
      gateways{
        id,
        name
        devices{
      ekm{
        id,
        name,
        serial,
        timezone,
        active,
        status
      }
      camera{
        id,
        name,
        serial,
        host,
        schedule,
        timezone,
        active
      }
      davis{
        id,
        name,
        serial,
        host,
        schedule,
        timezone,
        active
      }
      bewhere{
        id,
        name,
        serial,
        latitude,
        longitude,
        sim,
        imsi,
        timezone,
        active
      }
    }
      }
      
    }
    devices{
      ekm{
        id,
        name,
        serial,
        timezone,
        active,
        status
      }
      camera{
        id,
        name,
        serial,
        host,
        schedule,
        timezone,
        active
      }
      davis{
        id,
        name,
        serial,
        host,
        schedule,
        timezone,
        active
      }
      bewhere{
        id,
        name,
        serial,
        latitude,
        longitude,
        sim,
        imsi,
        timezone,
        active
      }
    }
  }
}`

export const GET_ZONES = gql`
query{
  getZones{
    id,
    name
  }
}`

export const GET_GATEWAYS = gql`
query{
  getGateways{
    id,
    name
  }
}`

export const CREATE_BEWHERE = gql`
mutation createBeWhere(	
    $input: BeWhereInput!
    ){
    createBeWhere(input: $input)
  }`

export const UPDATE_BEWHERE = gql`
mutation updateBeWhere(	
    $id:ID!,
      $input: BeWhereInput!
      ){
      updateBeWhere( id:$id,input: $input)
    }`


export const CREATE_DAVIS = gql`
  mutation createDavis(	
    $input: DavisInput!
    ){
    createDavis(input: $input)
  }`

export const UPDATE_DAVIS = gql`
  mutation updateDavis(	
    $id:ID!,
      $input: DavisInput!
      ){
        updateDavis( id:$id,input: $input)
    }`

export const CREATE_EKM = gql`
    mutation createEKM(	
        $input: EKMInput!
        ){
        createEKM(input: $input)
      }`

export const UPDATE_EKM = gql`
    mutation updateEKM(	
        $id:ID!,
          $input: EKMInput!
          ){
          updateEKM( id:$id,input: $input)
        }`

export const CREATE_CAMERA = gql`
      mutation createCamera(	
        $input: CameraInput!
        ){
        createCamera(input: $input)
      }`

export const UPDATE_CAMERA = gql`
      mutation updateCamera(	
        $id:ID!,
          $input: CameraInput!
          ){
          updateCamera( id:$id,input: $input)
        }`