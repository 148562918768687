import React, { useState, useEffect } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import classnames from 'classnames'

const AssignedTabs = ({ meters, MeterDescription, dragStyle, callback }) => {
    const [activeTab, setActiveTab] = useState(null)

    useEffect(() => {
        try {
            if (meters && meters[0].id && !isActiveTabValid()) {
                setActiveTab(meters[0].id)
            }
        } catch {
        }
    }, [meters])

    const isActiveTabValid = () => {
        return meters.find(meter => {
            return meter.id === activeTab;
        })
    }
    return (
        <div className="tabs tabs--justify tabs--bordered-bottom">
            <div className="tabs__wrap">
                <Nav tabs>
                    {meters.map(meter => {
                        return (
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === meter['id'] })}
                                    onClick={() => {
                                        setActiveTab(meter['id'])
                                    }}
                                >
                                    {meter['name']}
                                </NavLink>
                            </NavItem>
                        )
                    })}
                </Nav>
                <TabContent activeTab={activeTab}>
                    {meters.map(zone => {
                        return (
                            <TabPane tabId={zone['id']}>
                                <div
                                    style={{
                                        backgroundColor: 'lightgrey',
                                        padding: '20px',
                                        width: '500px',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    <Droppable droppableId={zone['id']}>
                                        {provided => (
                                            <div style={{ minHeight: '50px' }} ref={provided.innerRef} {...provided.droppableProps}>
                                                {zone.meters.map((meter, index) => {
                                                    return (
                                                        <Draggable key={meter.serial} draggableId={meter.serial} index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={dragStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                >
                                                                    <MeterDescription meter={meter} zoneId={zone['id']} callback={callback}></MeterDescription>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </TabPane>
                        )
                    })}
                </TabContent>
            </div>
        </div>
    )
}

export default AssignedTabs