import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from "./Users.graphql"
import PageView from 'shared/components/PageView'
import _ from 'lodash'
import { NotificationManager } from 'react-notifications';

const UsersList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [columns, setColumns] = useState([{ title: 'ID', field: 'id', editable: 'never', hidden: true },
    { title: 'Account*', field: 'account_id' },
    { title: 'First Name*', field: 'firstName' },
    { title: 'Last Name', field: 'lastName' },
    { title: 'User Name*', field: 'username' },
    { title: 'Email*', field: 'email' },
    { title: 'Phone Number', field: 'phone_number' },
    { title: 'Password*', field: 'password' },
    { title: 'Website', field: 'website' },
    { title: 'Address', field: 'address' },
    { title: 'City', field: 'city' },
    { title: 'Postal code', field: 'postal_code' },
    { title: 'Province', field: 'province' },
    { title: 'Country', field: 'country' },
    {
        title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
            1: "Active", 0: "Inactive"
        }
    }]);

    const { data: accountsData, loading: accountsLoading, error: accountsError, refetch: accountsRefetch } = useQuery(Actions.GET_ACCOUNTS, { fetchPolicy: "network-only" })

    const { data, loading, error, refetch } = useQuery(Actions.GET_USERS, { fetchPolicy: "network-only" })

    const [createUser] = useMutation(Actions.CREATE_USER);
    const [updateUser] = useMutation(Actions.UPDATE_USER);
    const [disableUser] = useMutation(Actions.DISABLE_USER);

    useEffect(() => {
        try {
            if (data && accountsData) {
                let table = data.getUsers;
                setTable(getParsedTable(table));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [data])

    useEffect(() => {
        try {
            if (accountsData) {
                let accounts = accountsData.getAccounts;
                setColumns(makeAccountsLookup(accounts));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [accountsData])

    const getParsedTable = (users) => {
        let userTable = [];
        users.forEach(eachUser => {
            let accountID = eachUser.account.id;
            userTable.push({ account_id: accountID, ...eachUser, status: eachUser.active ? 1 : 0 });
        })
        return userTable;
    }

    const makeAccountsLookup = (accounts) => {
        let lookupAccount = {};
        accounts.forEach(eachAccount => {
            lookupAccount[(eachAccount.id).toString()] = eachAccount.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'account_id') {
                eachCol['lookup'] = lookupAccount
            }
        })
        return columns;
    }



    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData))) {
                if (newData.id && newData.account_id && newData.firstName && newData.username && newData.email
                    && newData.password) {
                    let inputs = {
                        account_id: newData.account_id,
                        firstName: newData.firstName,
                        lastName: newData.lastName,
                        username: newData.username,
                        password: newData.password,
                        email: newData.email,
                        phone_number: newData.phone_number,
                        address: newData.address,
                        city: newData.city,
                        province: newData.province,
                        country: newData.country,
                        postal_code: newData.postal_code,
                        website: newData.website,
                        active: newData.status == 1
                    }
                    let result = await updateUser({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in user update', 'User  Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'User  Information', 2000);
                    }
                    refetch();
                }
                else {
                    NotificationManager.error('Mandatory fields are not provided', 'User Information', 3000)
                }
            }
        }
        catch (e) {
            console.log(e);
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        if (newData.account_id && newData.firstName && newData.username && newData.email
            && newData.password) {
            let inputs = {
                account_id: newData.account_id,
                firstName: newData.firstName,
                lastName: newData.lastName,
                username: newData.username,
                password: newData.password,
                email: newData.email,
                phone_number: newData.phone_number,
                address: newData.address,
                city: newData.city,
                province: newData.province,
                country: newData.country,
                postal_code: newData.postal_code,
                website: newData.website,
                active: newData.status == 1
            }
            let result = await createUser({ variables: { input: inputs } }).catch(e => {
                NotificationManager.error('Error in user creation', 'User  Information', 3000)
            })
            if (result) {
                NotificationManager.success('Successfully created', 'User  Information', 2000);
            }
            refetch();
        }
        else {
            NotificationManager.error('Mandatory fields are not provided', 'User Information', 3000)
        }
    }

    const isLoading = () => {
        return loading || error || errorUI || accountsError || accountsLoading
    }


    return (
        <PageView trail={['Configuration', 'Users']}>
            <Panel md={12} lg={12} xl={12} xs={12} loading={isLoading()}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 20,
                        addRowPosition: "first"
                    }}
                    title="Users"
                    columns={columns}
                    data={table}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleAddRow(newData);
                            table.push(newData);
                            setTable(table);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitRow(newData, oldData);
                            table[table.indexOf(oldData)] = newData
                            setTable(table);
                        },
                    }}
                />
            </Panel>
        </PageView>
    )
}

export default UsersList
