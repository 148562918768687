export const defaults = {
    "facilityId": "0",
    "clientId": "0"
}

export const typeDefs = `
    type Mutation{
        updateFacilityID(id:String)
        updateClientID(id:String)
    }
`

export const resolvers = {
    Mutation: {
        updateFacilityID: async (_, { facilityId }, { cache, getCacheKey }) => {
            await cache.writeData({ data: { facilityId } });
            return null
        },
        updateClientID: async (_, { clientId }, { cache, getCacheKey }) => {
            await cache.writeData({ data: { clientId } });
            return null
        }
    }
}