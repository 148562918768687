import React from 'react'
import { Container, Row } from 'reactstrap'
import AccountsList from './components/AccountsList'

const Accounts = () => {
    return (
        <Container className="dashboard">
            <Row>
                <AccountsList />
            </Row>
        </Container>
    )
}

export default Accounts