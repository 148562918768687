import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from "./ProductActivationsList.graphql"
import PageView from 'shared/components/PageView'
import _ from 'lodash'
import { NotificationManager } from 'react-notifications';

const ProductActivationsList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [columns, setColumns] = useState([{ title: 'ID', field: 'id', editable: 'never', hidden: true },
    { title: 'Product*', field: 'productId' },
    { title: 'Account*', field: 'accountId' },
    { title: 'Facility*', field: 'facilityId' },
    {
        title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
            1: "Active", 0: "Inactive"
        }
    }])

    const { data: accountsData, loading: accountsLoading, error: accountsError, refetch: accountsRefetch } = useQuery(Actions.GET_ACCOUNTS, { fetchPolicy: "network-only" })
    const { data: facilitiesData, loading: facilitiesLoading, error: facilitiesError, refetch: facilitiesRefetch } = useQuery(Actions.GET_FACILITIES, { fetchPolicy: "network-only" })
    const { data: productsData, loading: productsLoading, error: productsError, refetch: productsRefetch } = useQuery(Actions.GET_PRODUCTS, { fetchPolicy: "network-only" })

    const [createProductActivation] = useMutation(Actions.CREATE_PRODUCT_ACTIVATION);
    const [updateProductActivation] = useMutation(Actions.UPDATE_PRODUCT_ACTIVATION);
    const [disableProductActivation] = useMutation(Actions.DISABLE_PRODUCT_ACTIVATION);

    useEffect(() => {
        try {
            if (accountsData && facilitiesData && productsData) {
                let accounts = accountsData.getAccounts;
                setTable(getParsedTable(accounts));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [accountsData])

    useEffect(() => {
        try {
            if (facilitiesData) {
                let facilities = facilitiesData.getFacilities;
                setColumns(makeFacilityLookup(facilities));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [facilitiesData])

    useEffect(() => {
        try {
            if (productsData) {
                let products = productsData.getProducts;
                setColumns(makeProductsLookup(products));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [productsData])

    const getParsedTable = (accounts) => {
        let accountsTable = [];
        let lookupaccounts = {};
        accounts.map(account => {
            lookupaccounts[(account.id).toString()] = account.name;
            if (account.name != "Hanatech") {
                account.facilities.map(facility => {
                    facility.products.map(product => {
                        if (product.length != 0) {
                            accountsTable.push({ id: product.id, accountId: account.id, facilityId: facility.id, productId: product.product_id, status: product.active ? 1 : 0 })
                        }
                    })
                })
            }
        })
        columns.map(eachCol => {
            if (eachCol.field == 'accountId') {
                eachCol['lookup'] = lookupaccounts
            }
        })
        setColumns(columns);
        return accountsTable;
    }

    const makeFacilityLookup = (facilities) => {
        let lookupFacilities = {};
        facilities.forEach(facility => {
            lookupFacilities[(facility.id).toString()] = facility.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'facilityId') {
                eachCol['lookup'] = lookupFacilities
            }
        })
        return columns;
    }

    const makeProductsLookup = (products) => {
        let lookupProducts = {};
        products.forEach(product => {
            lookupProducts[(product.id).toString()] = product.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'productId') {
                eachCol['lookup'] = lookupProducts
            }
        })
        return columns;
    }


    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData))) {
                if (newData.accountId && newData.facilityId && newData.productId) {
                    let inputs = {
                        account_id: newData.accountId,
                        facility_id: newData.facilityId,
                        product_id: newData.productId,
                        active: newData.status == 1
                    }
                    let result = await updateProductActivation({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in activating product to account', 'Product Activation  Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'Product Activation  Information', 2000);
                    }
                    accountsRefetch();
                    facilitiesRefetch();
                    productsRefetch()
                }
                else {
                    NotificationManager.error('Mandatory fields are not provided', 'Product Activation Information', 3000)
                }
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        console.log(newData);
        if (newData.accountId && newData.facilityId && newData.productId) {
            let inputs = {
                account_id: newData.accountId,
                facility_id: newData.facilityId,
                product_id: newData.productId
            }
            let result = await createProductActivation({ variables: { input: inputs } }).catch(e => {
                NotificationManager.error('Error in activating product to account', 'Product Activation  Information', 3000)
            })
            if (result) {
                NotificationManager.success('Successfully created', 'Product Activation  Information', 2000);
            }
            accountsRefetch();
            facilitiesRefetch();
            productsRefetch()
        }
        else {
            NotificationManager.error('Mandatory fields are not provided', 'Product Activation Information', 3000)
        }
    }

    const isLoading = () => {
        return accountsLoading || accountsError || errorUI || facilitiesError || facilitiesLoading || productsError || productsLoading
    }


    return (
        <PageView trail={['Configuration', 'Product Activations']}>
            <Panel md={12} lg={12} xl={12} xs={12} loading={isLoading()}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 15,
                        addRowPosition: "first"
                    }}
                    title="Product Activations"
                    columns={columns}
                    data={table}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleAddRow(newData);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitRow(newData, oldData);
                        }
                    }}
                />
            </Panel>
        </PageView>
    )
}

export default ProductActivationsList
