import gql from 'graphql-tag'

export const GET_ZONE = gql`
query($id:ID!){
    getZone(id:$id){
      name
      devices{
        smartthings{
          id,
          name,
          type,
          serial,
          status
        }
      }
    }
  }`

export const GET_SWITCHES = gql`
query{
  getSwitches{
    id,
    name, 
    status
  }
}`

export const GET_LOCKS = gql`
query{
  getLocks{
    id,
    name, 
    status
  }
}`

export const LOCK_ON_OFF = gql`
mutation controlLock(	$smartId:String!
  $command: String!
  ){
  controlLock(smartId:$smartId,command: $command)
}`

export const SWITCH_ON_OFF = gql`
mutation controlSwitch(	$smartId:String!
  $command: String!
  ){
  controlSwitch(smartId:$smartId,command: $command)
}`

export const RELAY_ON_OFF = gql`
mutation controlRelay(	$smartId:String!
  $command: String!
  ){
  controlRelay(smartId:$smartId,command: $command)
}`

export const CONTROL_THERMOSTAT = gql`
mutation controlThermostat(	$smartId:String!
  $command: String!,
  $control: String! 
  ){
    controlThermostat(smartId:$smartId,command: $command, control: $control)
}`

export const GET_ALL_SMARTTHINGS = gql`
query{
  getAllSmartThings{
    id
    name
    status
		type
     ... on Switch{
      brightness
    }
    ... on Thermostat{
      temperature
      humidity
      coolingPoint
      heatingPoint
      mode
      fanMode
      modes
      fanModes
    }
  }
}`