import gql from 'graphql-tag'

export const GET_FACILITY = gql`
query($id:ID!){
    getFacility(id:$id){
      name
      zones{
        id,
        name,
        devices{
          smartthings{
            id,
            name,
            type
          }
        }
      }
    }
  }`

export const GET_ALL_SMARTTHINGS = gql`
query{
  getAllSmartThings{
    id,
    name,
    status
    type
  }
}`


