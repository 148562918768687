import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import * as Actions from './ZonesConfigurations.graphql'
import { useMutation } from '@apollo/react-hooks'
import { NotificationManager } from 'react-notifications';

const AddClient = (callback) => {
    const [clientName, setClientName] = useState('');
    const [createClient] = useMutation(Actions.CREATE_CLIENT)

    const saveNewClient = async (Clientname) => {
        await createClient({
            variables: { input: { name: Clientname } },
        }).catch(e => {
            NotificationManager.error('Error in saving', 'Client Information', 3000)
        })
        NotificationManager.success('Successfully new Client added', 'Client Information', 2000);
        await callback();
        setClientName('');
    }

    return (
        <div style={{ display: 'flex' }}>
            <Form inline>
                <FormGroup className="mt-0 mr-sm-2 ml-sm-2">
                    <Input type="text" value={clientName} onChange={e => setClientName(e.currentTarget.value)} placeholder='Add a client...' />
                </FormGroup>
                <Button onClick={() => saveNewClient(clientName)} style={{ marginBottom: '0px' }}>Save</Button>
            </Form>
        </div>
    );
}

export default AddClient;