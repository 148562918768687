import React, { useState } from 'react'
import ThermostatZoneDefaultPolicy from './Components/ThermostatZoneDefaultPolicy'
import ThermostatZoneCustomPolicy from './Components/ThermostatZoneCustomPolicy'
import ThermostatCustomPolicy from './Components/ThermostatCustomPolicy'
import ThermostatDefaultPolicy from './Components/ThermostatDefaultPolicy'
import PageView from 'shared/components/PageView'
import Collapse from 'shared/components/Collapse';
import { Row } from 'reactstrap'

const Configuration = () => {
  const [zoneCPCollapse, setZoneCPCollapse] = useState(false);
  const [zoneDPCollapse, setZoneDPCollapse] = useState(false);
  const [cpCollapse, setCPCollapse] = useState(false);
  const [dpCollapse, setDPCollapse] = useState(false);

  const handleZoneCPCollapse = () => {
    setZoneCPCollapse(!zoneCPCollapse);
  }
  const handleZoneDPCollapse = () => {
    setZoneDPCollapse(!zoneDPCollapse);
  }
  const handleDPCollapse = () => {
    setDPCollapse(!dpCollapse);
  }
  const handleCPCollapse = () => {
    setCPCollapse(!cpCollapse);
  }

    return (
        <PageView trail={['Smart Things', 'Configuration']}>
            <div className="collapse-blue">
                <Collapse title="Assign default policy to zone" className="with-shadow" collapse={zoneDPCollapse} callback={handleZoneDPCollapse}>
                    <Row>
                        <ThermostatZoneDefaultPolicy />
                    </Row>
                </Collapse>
            </div>
            <div className="collapse-light-1-blue">
                <Collapse title="Manage default policy" className="with-shadow" collapse={dpCollapse} callback={handleDPCollapse}>
                    <Row>
                        <ThermostatDefaultPolicy />
                    </Row>
                </Collapse>
            </div>
            <div className="collapse-light-2-blue">
                <Collapse title="Assign custom policy to zone" className="with-shadow" collapse={zoneCPCollapse} callback={handleZoneCPCollapse}>
                    <Row>
                        <ThermostatZoneCustomPolicy />
                    </Row>
                </Collapse>
            </div>
            <div className="collapse-light-3-blue">
                <Collapse title="Manage custom policy" className="with-shadow" collapse={cpCollapse} callback={handleCPCollapse}>
                    <Row>
                        <ThermostatCustomPolicy />
                    </Row>
                </Collapse>
            </div>
        </PageView>
    )
}

export default Configuration