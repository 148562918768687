import React, { useState, useEffect } from 'react'
import {
  ButtonToolbar,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Col,
  Row,
  ButtonGroup,
  Button,
} from 'reactstrap'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import { withSize } from 'react-sizeme'

const TemperatureUnitFilter = ({ unitsCallback, size }) => {
  const [alignMobile, setalignMobile] = useState('flex-end')
  const [unit, setUnit] = useState([
    (localStorage.getItem('temperatureUnit') ||"F"),
    (localStorage.getItem('temperatureUnit') ? "°"+localStorage.getItem('temperatureUnit') : '°F')
  ])
  const fixedUnits = [
    { id: 'F', name: '°F' },
    { id: 'C', name: '°C' }
  ]
  const handleUnits = e => {
    setUnit([e.currentTarget.getAttribute('id'), e.currentTarget.textContent])
    localStorage.setItem('temperatureUnit', e.currentTarget.getAttribute('id'))
    unitsCallback(e.currentTarget.getAttribute('id'))
  }

  useEffect(() => {
    if (size.width <= 450) {
      setalignMobile('flex-start')
    } else {
      setalignMobile('flex-end')
    }
  }, [size])
  return (
    <React.Fragment md={12} lg={12} xl={6} xs={12} style={{ display: 'flex', justifyContent: alignMobile }}>
      <ButtonToolbar style={{ justifyContent: 'center' }}>
        <UncontrolledDropdown>
          <ButtonGroup>
            <Button color="primary" outline>
              {unit[1]}{' '}
            </Button>
            <DropdownToggle color="primary" className="icon icon--right">
              <ChevronDownIcon />
            </DropdownToggle>
          </ButtonGroup>
          <DropdownMenu right className="dropdown__menu">
            {fixedUnits.map(fixedUnit => {
              return (
                <DropdownItem id={fixedUnit.id} key={fixedUnit.id} onClick={e => handleUnits(e)}>
                  {fixedUnit.name}{' '}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ButtonToolbar>
      <br />
    </React.Fragment>
  )
}

export default withSize()(TemperatureUnitFilter)
