import React, { useState, useEffect } from 'react';
import { ButtonToolbar, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import * as utls from 'shared/utls/index.js'


const Filter = ({ callbackStartDate, callbackEndDate, clientSelected }) => {

    // const GET_FACILITY_FILTER = gql`
    // {
    //     visibilityFilter @client
    // }
    // `;
    const [facilities, setFacilities] = useState([]);
    const [facility, setFacility] = useState([null, ""]);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState([null, ""]);
    const [startDate, setstartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setendDate] = useState(new Date());

    // const { testData, apolloClient } = useQuery(GET_FACILITY_FILTER);
    const handleClient = (e) => {
        setClient([e.currentTarget.getAttribute("id"), e.currentTarget.textContent]);
        clientSelected(parseInt(e.currentTarget.getAttribute("id")));
    }

    const handleFacility = (e) => {
        setFacility([e.currentTarget.getAttribute("id"), e.currentTarget.textContent]);
    }

    const handleStartChange = (date) => {
        setstartDate(date);
    }

    const handleEndChange = (date) => {
        setendDate(date);
    }

    const { data: facilityData, loading: facilityLoading, error: facilityError } = useQuery(gql`
    query GetFacilities{
        getFacilities{
            id
            name
        }
    }
    `)

    const { data, loading, error } = useQuery(gql`
    query getPowerBillingByFacility($facilityId: Int!){
        getPowerBillingByFacility(facility_id:$facilityId){
          clients{
            id,
            name
          }
        }
      }`,
        {
            variables: { facilityId: parseInt(facility[0]) }
        })

    useEffect(() => {
        if (facilityData) {
            let sortedFacilities = utls.sortByName(facilityData.getFacilities);
            setFacilities(sortedFacilities);
            let { id, name } = sortedFacilities[0];
            setFacility([id, name])
        }
    }, [facilityData])

    useEffect(() => {
        if (facilities.length > 0) {
            setFacility([facilities[0].id, facilities[0].name]);
        }
    }, [facilities])

    useEffect(() => {
        if (data) {
            let sortedClients = utls.sortByName(data.getPowerBillingByFacility.clients);
            setClients(sortedClients)
        }
    }, [data])

    useEffect(() => {
        callbackStartDate(startDate);
        callbackEndDate(endDate);
    }, [startDate, endDate])

    useEffect(() => {
        if (clients.length > 0) {
            setClient([clients[0].id, clients[0].name]);
            clientSelected(parseInt(clients[0].id));
        }
    }, [clients])

    if (loading || facilityLoading)
        return "Loading"
    if (error || facilityError) return `Error! ${error.message}`

    return (
        <div>
            <Row>
                <Col md={12} lg={12} xl={3} xs={12}>
                    <ButtonToolbar style={{ justifyContent: "center" }}>
                        <UncontrolledDropdown>
                            <DropdownToggle color="primary" className="icon icon--right">
                                <p>{facility[1]} <ChevronDownIcon /></p>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                                {facilities.map(facility => {
                                    return <DropdownItem id={facility.id} key={facility.id} onClick={(e) => handleFacility(e)}>{facility.name} </DropdownItem>
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonToolbar>
                </Col>
                <Col md={12} lg={12} xl={3} xs={12}>
                    <ButtonToolbar style={{ justifyContent: "center" }}>
                        <UncontrolledDropdown>
                            <DropdownToggle color="primary" className="icon icon--right">
                                <p>{client[1]} <ChevronDownIcon /></p>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown__menu">
                                {clients.map(client => {
                                    return <DropdownItem id={client.id} key={client.id} onClick={(e) => handleClient(e)}>{client.name} </DropdownItem>
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </ButtonToolbar>
                </Col>
                <Col md={12} lg={12} xl={3} xs={12}>
                    <span className="form__form-group-label">From Date</span>
                    <div className="date-picker">
                        <DatePicker
                            className="form__form-group-datepicker"
                            selected={startDate}
                            onChange={handleStartChange}
                            dropDownMode="select"
                        // maxDate={endDate.setDate(endDate.getDate() - 1)}
                        />
                    </div>
                </Col>
                <Col md={12} lg={12} xl={3} xs={12}>
                    <span className="form__form-group-label">To Date</span>
                    <div className="date-picker">
                        <DatePicker
                            className="form__form-group-datepicker"
                            selected={endDate}
                            onChange={handleEndChange}
                            dropDownMode="select"
                            maxDate={new Date()}
                        // minDate={startDate.setDate(startDate.getDate() + 1)}
                        />
                    </div>
                </Col>
            </Row>
        </div >
    )
}

export default Filter