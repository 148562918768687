import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import PageView from 'shared/components/PageView'
import CardLink from 'shared/components/CardLink'

const SmartFarmingCards = () => {
    return (
        <PageView trail={['Smart Farming']}>
            <Row>
                <Col xl={3} md={3} xs={12}>
                    <CardLink title={'FireBlight'} url={'/smartfarming/fireblight'}>
                    </CardLink>
                </Col>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Davis'} url={'/smartfarming/davis'}>
                    </CardLink>
                </Col>
            </Row>
        </PageView>
    )
}
export default SmartFarmingCards
