import gql from 'graphql-tag'

export const GET_DEVICES = gql`
query{
    getFacilities{
      id,
      name
      gateways{
        id,
        name,
        serial,
        active
      }
    }
  }`

export const GET_FACILITIES = gql`
  query {
    getFacilities {
      id,
      name
    }
  }`

export const CREATE_GATEWAY = gql`
mutation createGateway(	
    $input: GatewayInput!
    ){
    createGateway(input: $input)
  }`

export const UPDATE_GATEWAY = gql`
mutation updateGateway(	
    $id:ID!,
      $input: GatewayInput!
      ){
      updateGateway( id:$id,input: $input)
    }`