import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from "./Locations.graphql"
import PageView from 'shared/components/PageView'
import _ from 'lodash'
import momentTZ from 'moment-timezone';
import { NotificationManager } from 'react-notifications';

const LocationsList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [columns, setColumns] = useState([
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Name*', field: 'name' },
        { title: 'City', field: 'city' },
        { title: 'Province', field: 'province' },
        { title: 'Country', field: 'country' },
        {
            title: 'Timezone*', field: 'timezone'
        },
        { title: 'Latitude*', field: 'latitude' },
        { title: 'Longitude*', field: 'longitude' },
        {
            title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
                1: "Active", 0: "Inactive"
            }
        }

    ])

    const { data, loading, error, refetch } = useQuery(Actions.GET_LOCATIONS, { fetchPolicy: "network-only" })

    const [createLocation] = useMutation(Actions.CREATE_LOCATION);
    const [updateLocation] = useMutation(Actions.UPDATE_LOCATION);
    const [disableLocation] = useMutation(Actions.DISABLE_LOCATION);

    const makeTimezoneLookup = () => {
        let lookupLocation = {};
        momentTZ.tz.names().forEach(timezone => {
            lookupLocation[timezone.toString()] = timezone
        })
        columns.map(eachCol => {
            if (eachCol.field == 'timezone') {
                eachCol['lookup'] = lookupLocation
            }
        })
        setColumns(columns)
    }



    useEffect(() => {
        try {
            if (data) {
                makeTimezoneLookup();
                let table = data.getLocations;
                setTable(getParsedTable(table));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [data])

    const getParsedTable = (locations) => {
        let locationTable = [];
        locations.forEach(location => {
            locationTable.push({ status: location.active ? 1 : 0, ...location });
        })
        return locationTable;
    }



    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData))) {
                if (newData.name && newData.timezone && newData.latitude && newData.longitude) {
                    let inputs = {
                        name: newData.name,
                        city: newData.city,
                        province: newData.province,
                        country: newData.country,
                        timezone: newData.timezone,
                        latitude: newData.latitude,
                        longitude: newData.longitude,
                        active: newData.status == 1
                    }
                    let result = await updateLocation({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in Location updation', 'Location Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'Location Information', 2000);
                    }
                    refetch();
                }
                else {
                    NotificationManager.error('Mandatory fields are not provided', 'Location Information', 3000)
                }
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        if (newData.name && newData.timezone && newData.latitude && newData.longitude) {
            let inputs = {
                name: newData.name,
                city: newData.city,
                latitude: newData.latitude,
                longitude: newData.longitude,
                province: newData.province,
                country: newData.country,
                timezone: newData.timezone
            }
            let result = await createLocation({ variables: { input: inputs } }).catch(e => {
                NotificationManager.error('Error in Location creation', 'Location Information', 3000)
            })
            if (result) {
                NotificationManager.success('Successfully created', 'Location Information', 2000);
                refetch();
            }
        }
        else {
            NotificationManager.error('Mandatory fields are not provided', 'Location Information', 3000)
        }
    }


    return (
        <PageView trail={['Configuration', 'Locations']}>
            <Panel md={12} lg={12} xl={12} xs={12} loading={loading || error || errorUI}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 20,
                        addRowPosition: "first"
                    }}
                    title="Locations"
                    columns={columns}
                    data={table}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleAddRow(newData);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitRow(newData, oldData);
                        }
                    }}
                />
            </Panel>
        </PageView >
    )
}

export default LocationsList
