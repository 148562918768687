import React from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { useHistory, useRouteMatch } from 'react-router-dom'
import CardLink from '../../../../../shared/components/CardLink'
import { fontSize } from '@material-ui/system'
import { List } from 'react-content-loader'

const FacilityList = () => {
    let history = useHistory()
    let match = useRouteMatch()
    let selectedLocation = match.params.LocationId
    const { data, loading, error } = useQuery(gql`
    query getLocation($id: ID!) {
        getLocation(id: $id) {
          facilities{
            id,
            name
          }
        }
    }`, {
        variables: { id: selectedLocation },
    })
    if (error) console.log(error);
    if (loading) return <List /> 
    let facilitiesData;
    if (data) {
        facilitiesData = data.getLocation.facilities;
    }
    return (
        <Row>
            {
                facilitiesData && facilitiesData.length > 0 ?
                    facilitiesData.length > 1 ?
                        facilitiesData.map(facility => {
                            return <Col md={12} xl={4} lg={6} xs={12}>
                                <CardLink title={facility.name} url={'/' + facility.id}>
                                    <ul >
                                        <li>{facility.city}</li>
                                        <li>{facility.province}</li>
                                    </ul>
                                </CardLink>
                            </Col>
                        }) : history.push(`${match.params.LocationId}${'/' + facilitiesData[0].id}`) : <div style={{ marginTop: "30px",marginLeft:"30px", fontSize:"30px" }}>
                        No Facilities Registered
                            </div>
            }
        </Row>
    )
}

export default FacilityList
