import gql from 'graphql-tag'

export const FIREBLIGHT_QUERY = gql`
query getFireBlightByFacility($id: ID!) {
  getFireBlightByFacility(facility_id: $id) {
    datetime
    risk{
      date,
      minTemp,
      maxTemp,
      wetness,
      EIP,
      BBS,
      CBS,
      SBS,
      TBS,
      level
    },
    riskHistory{
      date,
      minTemp,
      maxTemp,
      wetness
      EIP,
      BBS,
      CBS,
      SBS,
      TBS,
      level
    },
  events{
      phenology{
        id,
        date,
        value
          },
      spray{
          id,
          date,
          value
      },
      trauma{
          id,
          date,
          value
    }
      }
  }
}`

export const GET_ZONES = gql`
query getZones($id: ID!) {
  getFacility(id:$id) {
    zones{
      id
    }
  }
}`

export const CREATE_PHENOLOGY_EVENT = gql`
    mutation CreatePhenologyEvent(	
                                $zoneId: ID!, 
                                $phenology: String!, 
                                $date: String!
                            ){
        createPhenologyEvent( zone_id: $zoneId, phenology: $phenology, date: $date )
    }`

export const EDIT_PHENOLOGY_EVENT = gql`
    mutation EditPhenologyEvent(    $id: ID!,
                                    $zoneId: ID, 
                                    $phenology: String, 
                                    $date: String
                            ){
        editPhenologyEvent(id:$id, zone_id: $zoneId, phenology: $phenology, date: $date)
    }`
export const CREATE_SPRAY_EVENT = gql`
    mutation CreateSprayEvent(	
                                $zoneId: ID!, 
                                $spray: String!, 
                                $date: String!
                            ){
        createSprayEvent( zone_id: $zoneId, spray: $spray, date: $date )
    }`
export const DELETE_SPRAY_EVENT = gql`
    mutation DeleteSprayEvent( 
                                    $id : ID!
                                ){
        deleteSprayEvent(id: $id)
    }`


export const DELETE_PHENOLOGY_EVENT = gql`
    mutation DeletePhenologyEvent( 
                                    $id : ID!
                                ){
        deletePhenologyEvent(id: $id)
    }`

export const CREATE_TRAUMA_EVENT = gql`
    mutation CreateTraumaEvent(	
                                $zoneId: ID!, 
                                $trauma: String!, 
                                $date: String!
                            ){
                              createTraumaEvent( zone_id: $zoneId, trauma: $trauma, date: $date )
    }`

export const EDIT_TRAUMA_EVENT = gql`
    mutation EditTraumaEvent(    $id: ID!,
                                    $zoneId: ID, 
                                    $trauma: String, 
                                    $date: String
                            ){
                              editTraumaEvent(id:$id, zone_id: $zoneId, trauma: $trauma, date: $date)
    }`
export const DELETE_TRAUMA_EVENT = gql`
    mutation DeleteTraumaEvent( 
                                    $id : ID!
                                ){
                                  deleteTraumaEvent(id: $id)
    }`
