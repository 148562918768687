import React from 'react'
import PageView from 'shared/components/PageView'

const PowerBilling = () => {
    return (
        <PageView trail={['Smart Building', 'Power-Billing']}>

        </PageView>
    )
}
export default PowerBilling
