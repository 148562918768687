import React from 'react'
import { Col } from 'reactstrap'

const Divider = ({ name }) => {
    return (
        <Col xl={12} md={12} xs={12} lg={12}>
            <div style={{ textAlign: "center", fontSize: "20px" }}>
                {name}
            </div>
            <hr />
        </Col>
    )
}
export default Divider
