import React from 'react'
import { Container, Row } from 'reactstrap'
import ZonesList from './components/ZonesList'

const Users = () => {
    return (
        <Container className="dashboard">
            <Row>
                <ZonesList />
            </Row>
        </Container>
    )
}

export default Users