import React, { useState } from 'react'
import { Col, Button, Form, FormGroup, Label, Input, FormText, Row, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PageView from 'shared/components/PageView'
import Panel from 'shared/components/Panel';
import classnames from 'classnames'

const Davis = ({ match }) => {
    const devices = [{ "id": "1", "deviceId": "274718", "deviceType": "davis", "name": 'Davis1', "configuration": { "enabled": true, "host": "192.168.1.65", "schedule": "0 */10 * * * *" } },
    { "id": "2", "deviceId": "274718", "deviceType": "camera", "name": 'Camera1', "configuration": { "enabled": true, "host": "192.168.1.246", "username": "admin", "password": "", "schedule": "0 0 0 * * *" } }];
    const [activeTab, setActiveTab] = useState(null)
    return (
        <PageView trail={['Smart Farming', 'Davis', match.params.davisid]}>
            {/* <Panel md={12} lg={12} xl={8} xs={12}>
                <Form>
                    <FormGroup row>
                        <Label sm={2}>Gateway Name</Label>
                        <Col sm={3}>
                            <Input />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2}>Status</Label>
                        <Col sm={3}>
                            <FormGroup check inline style={{ marginTop: "5px" }}>
                                <Label check>
                                    <Input type="checkbox" /> <span style={{ color: "green" }}>enabled</span>
                                </Label>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                </Form>
                <Row>
                    <Col md={12} lg={12} xl={3} xs={12} style={{ justifyContent: "flex-end", display: "flex" }}>
                        <Button outline color="success">Save</Button>
                    </Col>
                    <Col md={12} lg={12} xl={3} xs={12}>
                        <Button outline color="danger">Cancel</Button>
                    </Col>
                </Row>
            </Panel>
            <Panel md={12} lg={12} xl={8} xs={12} title="Devices">
                <div className="tabs tabs--justify tabs--bordered-bottom">
                    <div className="tabs__wrap">
                        <Nav tabs>
                            {devices.map(device => {
                                return (
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === device['id'] })}
                                            onClick={() => {
                                                setActiveTab(device['id'])
                                            }}>
                                            {device['name']}
                                        </NavLink>
                                    </NavItem>
                                )
                            })}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {devices.map(device => {
                                return (
                                    <TabPane tabId={device['id']}>
                                        {
                                            device.deviceType == 'davis' ?
                                                <React.Fragment>
                                                    <Form>
                                                        <FormGroup row>
                                                            <Label sm={2}>Device ID</Label>
                                                            <Col sm={3}>
                                                                {device.deviceId}
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Device Name</Label>
                                                            <Col sm={3}>
                                                                <Input value={device.name} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Host</Label>
                                                            <Col sm={3}>
                                                                <Input value={device.configuration.host} />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label sm={2}>Schedule</Label>
                                                            <Col sm={3}>
                                                                <Input value={device.configuration.schedule} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Form>
                                                    <Row>
                                                        <Col md={12} lg={12} xl={3} xs={12} style={{ justifyContent: "flex-end", display: "flex" }}>
                                                            <Button outline color="success">Save</Button>
                                                        </Col>
                                                        <Col md={12} lg={12} xl={3} xs={12}>
                                                            <Button outline color="danger">Cancel</Button>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment> : device.deviceType == 'camera' ?
                                                    <React.Fragment>
                                                        <Form>
                                                            <FormGroup row>
                                                                <Label sm={2}>Device ID</Label>
                                                                <Col sm={3}>
                                                                    {device.deviceId}
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label sm={2}>Device Name</Label>
                                                                <Col sm={3}>
                                                                    <Input value={device.name} />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label sm={2}>Host</Label>
                                                                <Col sm={3}>
                                                                    <Input value={device.configuration.host} />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label sm={2}>User name</Label>
                                                                <Col sm={3}>
                                                                    <Input value={device.configuration.username} />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label sm={2}>Password</Label>
                                                                <Col sm={3}>
                                                                    <Input value={device.configuration.password} />
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label sm={2}>Schedule</Label>
                                                                <Col sm={3}>
                                                                    <Input value={device.configuration.schedule} />
                                                                </Col>
                                                            </FormGroup>
                                                        </Form>
                                                        <Row>
                                                            <Col md={12} lg={12} xl={3} xs={12} style={{ justifyContent: "flex-end", display: "flex" }}>
                                                                <Button outline color="success">Save</Button>
                                                            </Col>
                                                            <Col md={12} lg={12} xl={3} xs={12}>
                                                                <Button outline color="danger">Cancel</Button>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment> : ''
                                        }
                                    </TabPane>
                                )
                            })}
                        </TabContent>
                    </div>
                </div>
            </Panel> */}
        </PageView >
    )
}

export default Davis