import React, { useState } from 'react'
import { Col, Row } from 'reactstrap'
import PageView from 'shared/components/PageView'
import CardLink from 'shared/components/CardLink'

const LandingPage = () => {
    return (
        <PageView trail={['Configuration']}>
            <Row>
                <Col xl={3} md={3} xs={12}>
                    <CardLink title={'Product Activations'} url={'/productactivations'}>

                    </CardLink>
                </Col>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Accounts'} url={'/accounts'}>
                    </CardLink>
                </Col>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Users'} url={'/users'}>
                    </CardLink>
                </Col>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Roles'} url={'/roles'}>
                    </CardLink>
                </Col>
            </Row>
            <Row>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Locations'} url={'/locations'}>
                    </CardLink>
                </Col>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Facilities'} url={'/facilities'}>
                    </CardLink>
                </Col>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Zones'} url={'/zones'}>
                    </CardLink>
                </Col>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Devices'} url={'/devices'}>
                    </CardLink>
                </Col>
            </Row>
            <Row>
                <Col md={3} xl={3} xs={12}>
                    <CardLink title={'Gateways'} url={'/gateways'}>
                    </CardLink>
                </Col>
            </Row>
        </PageView>
    )
}
export default LandingPage
