import gql from 'graphql-tag'


export const GET_ACCOUNTS = gql`
query {
  getAccounts {
    id,
    name,
    active,
    facilities{
      id,
      name,
      products{
        id,
        product_id,
        name,
        active
      }
    }
  }
}`

export const GET_FACILITIES = gql`
query {
  getFacilities {
    id,
    name
  }
}`

export const GET_PRODUCTS = gql`
query {
  getProducts {
    id,
    name,
  }
}
`

export const CREATE_PRODUCT_ACTIVATION = gql`
mutation createProductActivation(	
    $input: ProductActivationInput!
    ){
        createProductActivation(input: $input)
  }`

export const UPDATE_PRODUCT_ACTIVATION = gql`
mutation updateProductActivation(	
    $id:ID!
      $input: ProductActivationInput!
      ){
        updateProductActivation(id:$id, input: $input)
    }`

export const DISABLE_PRODUCT_ACTIVATION = gql`
mutation disableProductActivation(	
    $id: ID!
    ){
        disableProductActivation(id: $id)
  }`

