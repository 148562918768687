import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Actions from './ZonesConfigurations.graphql'
import { useMutation } from '@apollo/react-hooks'
import { NotificationManager } from 'react-notifications';

const EnableNotification = ({ clientId, email, checked, status, setEmailProp, setCheckedProp, callback }) => {
    const [updateClient] = useMutation(Actions.UPDATE_CLIENT);
    const [disableClient] = useMutation(Actions.UPDATE_CLIENT);
    const [deleteClientPermanent] = useMutation(Actions.DELETE_CLIENT);

    const saveClient = async (clientId, email, checked) => {
        await updateClient({
            variables: { id: clientId, input: { email: email, email_enabled: checked } },
        }).catch(e => {
            NotificationManager.error('Error in saving', 'Client Information', 3000)
        })
        NotificationManager.success('Successfully saved', 'Client Information', 2000);
        callback()
    }

    const clientAction = async (clientId, status) => {
        await disableClient({
            variables: { id: clientId, input: { active: !status } },
        }).catch(e => {
            NotificationManager.error('Error in saving', 'Client Information', 3000)
        })
        NotificationManager.success('Successfully' + (status ? ' Disabled' : ' Enabled'), 'Client Information', 2000);
        callback()
    }

    const deleteClientAction = async (clientId) => {
        await deleteClientPermanent({
            variables: { id: clientId },
        }).catch(e => {
            NotificationManager.error('Error in saving', 'Client Information', 3000)
        })
        NotificationManager.success('Successfully deleted', 'Client Information', 2000);
        callback();
    }

    return (
        <React.Fragment>
            <Form inline>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                    <Label for="email" className="mr-sm-2">Email</Label>
                    <Input type="text" value={email} onChange={e => setEmailProp(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup check className="mb-2 mr-sm-2 mb-sm-0">
                    <Input type="checkbox" checked={checked} onChange={(e) => setCheckedProp(e.target.checked)} />
                    <Label for="exampleCheck" check>Enable</Label>
                </FormGroup>
                <Button onClick={() => saveClient(clientId, email, checked)} style={{ marginBottom: "0px" }}>Save</Button>
                {
                    status ? <Button onClick={() => clientAction(clientId, status)}
                        style={{ marginBottom: "0px" }} color="warning">
                        Disable Client</Button> :
                        <Button onClick={() => clientAction(clientId, status)}
                            style={{ marginBottom: "0px" }} color="success">
                            Enable Client</Button>
                }
                <DeleteClientModal deleteClient={deleteClientAction} clientId={clientId} />
            </Form>
        </React.Fragment>
    );
}

const DeleteClientModal = ({ deleteClient, clientId }) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    return (
        <div>
            <Button color="danger" onClick={toggle} style={{ marginBottom: "0px" }}>Delete</Button>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <ModalBody>
                    All the client Data, Bills are permanently deleted...
                    Are sure to delete?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => {
                        setModal(!modal);
                        deleteClient(clientId);
                    }}>Delete permanently</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default EnableNotification;