import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import gql from 'graphql-tag'
import { NotificationManager } from 'react-notifications';

const ThermostatZoneCustomPolicy = () => {

  const GET_CUSTOM_POLICIES = gql`                    
    query GET_CUSTOM_POLICIES{
        getCustomThermostatPolicies{
          id
          name
        }
    }`

    const GET_FACILITIES = gql`
    query {
      getFacilities{
        zones{
          id
          name
          HVAC{
            policies{
              custom{
                id
                zone_id
                zone_name
                policy_id
                policy_name
              }
            }
          }
        }
      }
    }`

    const CREATE_LINK_POLICY_ZONE = gql`
    mutation LNK_TP_ZONE($input: ThermostatPolicyLinkInput!){
      linkCustomThermostatPolicyToZone(input: $input)
    }`

    const UPDATE_LINK_POLICY_ZONE = gql`
    mutation UDT_LNK_TP_ZONE($id: ID!, $input: ThermostatPolicyLinkInput!){
      updateCustomThermostatPolicyToZone(id: $id, input: $input)
    }`

    const DELETE_LINK_POLICY_ZONE = gql`
    mutation DEL_LNK_TP_ZONE($id: ID!){
      deleteCustomThermostatPolicyToZone(id: $id)
    }`

    const[policyLookupObj, setPolicyLookupObj] = useState({});
    const[zoneLookupObj, setZoneLookupObj] = useState({})
    let columns = [
      { title: 'id', field: 'id', editable: 'never', hidden: true },
      { title: 'zone_id', field: 'zone_id' , hidden: true},
      { title: '*Zone name', field: 'zone_id', editable: 'always', lookup: zoneLookupObj},
      { title: 'policy_id', field: 'policy_id', hidden: true},
      { title: '*Policy name', field: 'policy_id', editable: 'always', lookup: policyLookupObj}
    ]
    const [zonePolicies, setZonePolicies] = useState([]);
    const { data: policyData, loading: policyDataLoading, error:policyDataError, refetch: pdRefetch } = useQuery(GET_CUSTOM_POLICIES, { fetchPolicy: "network-only" })
    const { data: facsData, loading: facDataLoading, error:facDataError, refetch: zpdRefetch } = useQuery(GET_FACILITIES, { fetchPolicy: "network-only" })
    let [createLink, { data: createLinkResponse, error: createLinkError, loading: createLinkLoading }] = useMutation(CREATE_LINK_POLICY_ZONE);
    let [updateLink, { data: updateLinkResponse, error: updateLinkError, loading: updateLinkLoading }] = useMutation(UPDATE_LINK_POLICY_ZONE);
    let [deleteLink, { data: deleteLinkResponse, error: deleteLinkError, loading: deleteLinkLoading }] = useMutation(DELETE_LINK_POLICY_ZONE);

    useEffect(()=> {
      if(policyData){
          let lookupObj = {}
          let policies = policyData.getCustomThermostatPolicies
          policies.map(policy => {
            lookupObj[policy.id] = policy.name
          })
          setPolicyLookupObj(lookupObj)
      }
    },[policyData])

    useEffect(()=>{
    
      if(facsData){
          let policies = []
          let lookupObj = {}
          let facs = facsData.getFacilities
          for(let fac of facs){
            let zones = fac.zones
            zones.map(zone => {
              lookupObj[zone.id] =  zone.name
              policies.push(...zone.HVAC.policies.custom)
            })
          }
          setZoneLookupObj(lookupObj)
          setZonePolicies(policies)
      }
    },[facsData])

    useEffect(() => {

      if(createLinkError){
          NotificationManager.error(modifyErrorMessage(createLinkError.message), "THERMOSTAT ZONE CUSTOM POLICY", 3000)
      }

      if(updateLinkError){
          NotificationManager.error(modifyErrorMessage(updateLinkError.message), "THERMOSTAT ZONE CUSTOM POLICY", 3000)
      }

    }, [createLinkError, updateLinkError])

    const modifyErrorMessage = (message) => {
      message = message.replace(/GraphQL error:/, '')
      return message
  }

    if (policyDataLoading || facDataLoading) return 'Loading'
    if (policyDataError || facDataError) return 'error'

    return(
        <React.Fragment>
            <MaterialTable
                  options={{
                      addRowPosition: "first"
                  }}
                  title = "Thermostat Zone Custom Policies"
                  columns={columns}
                  data={zonePolicies}
                  editable={{
                    onRowAdd : async(newData) =>{
                      await createLink({
                        variables: {
                          input: {
                            zone_id: newData.zone_id,
                            policy_id: newData.policy_id
                          }
                        }
                      })
                      pdRefetch()
                      zpdRefetch()
                    },
                    onRowUpdate: async(newData, oldData) => {
                      await updateLink({
                        variables: {
                          id: oldData.id,
                          input: {
                            zone_id: newData.zone_id,
                            policy_id: newData.policy_id
                          }
                        }
                      })
                      pdRefetch()
                      zpdRefetch()
                    },
                    onRowDelete: async(oldData) => {
                        await deleteLink({
                            variables: {
                                id: oldData.id
                            }
                        })
                      pdRefetch()
                      zpdRefetch()
                    } 
                  }}
            >
            </MaterialTable>
        </React.Fragment>
    )
}

export default ThermostatZoneCustomPolicy