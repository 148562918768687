import React, { useState, useEffect, useRef } from 'react';
import Panel from 'shared/components/Panel';
import { Bar } from 'react-chartjs-2';
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import * as utls from 'shared/utls/index.js'
import { isEqual } from 'lodash'
import moment from 'moment'
import {
    Row, Form,
    FormGroup, Label, Input
} from 'reactstrap'

const ConsumptionChart = ({ meterId, interval, range }) => {
    const [errorUI, seterrorUI] = useState(false);
    const [parsedConsumptionData, setParsedConsumptionData] = useState([]);
    const [parsedRange, parsedRType] = range.split('_');
    const { data: consumptionData, loading: consumptionLoading, error: consumptionError, refetch: consumptionRefetch } = useQuery(gql`
    query($meterId:ID!, $interval: String!, $range:Int!, $rType:String!){
        getPowerConsumption(meterId:$meterId, interval:$interval,
        range:$range, rType:$rType){
            date,
            consumption,
            cost
          }
        }`, {
        variables: { meterId: meterId, interval: interval, range: parseInt(parsedRange), rType: parsedRType }, fetchPolicy: "network-only"
    })

    useDeepEffect(() => {
        try {
            if (consumptionData && consumptionData.getPowerConsumption.length > 0) {
                setParsedConsumptionData(parseConsumption(consumptionData.getPowerConsumption));
            }
        }
        catch{
            seterrorUI(true);
        }
    }, [consumptionData])

    const parseConsumption = (consumptionData) => {
        let chartdata = [];
        consumptionData.map((d, i) => {
            chartdata.push({
                date: d.date,
                consumption: d.consumption == "-" ? "0" : d.consumption.toString(),
                cost: d.cost.toString()
            });
        })
        return utls.sortByDate(chartdata, false);
    }

    const getXaxisDates = (interval) => {
        switch (interval) {
            case "fifteen":
                return parsedConsumptionData.map(d => moment(d.date, 'YYYY-MM-DD HH:mm').format('MMM DD HH:mm'))
            case "hour":
                return parsedConsumptionData.map(d => moment(d.date, 'YYYY-MM-DD HH:mm').format('MMM DD HH:mm'))
            case "day":
                return parsedConsumptionData.map(d => moment(d.date, 'YYYY-MM-DD HH:mm').format('MMM DD, YYYY'))
            case "week":
                return parsedConsumptionData.map(d => moment(d.date, 'YYYY-MM-DD HH:mm').format('MMM DD, YYYY'))
            case "month":
                return parsedConsumptionData.map(d => moment(d.date, 'YYYY-MM-DD HH:mm').format('MMM YYYY'))
        }
    }

    let fixedPro = {
        fill: false,
        backgroundColor: "#ff4d00",
        borderDash: [],
        borderWidth: 1.5,
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointHoverRadius: 3,
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
    }
    let datasetObject = [
        {
            label: '',
            data: parsedConsumptionData.map(d => d.consumption),
            price: parsedConsumptionData.map(d => d.cost || ''),
            borderColor: "#ff1100",
            ...fixedPro
        }
    ];
    let date = getXaxisDates(interval);
    const chartData = {
        labels: date,
        datasets: datasetObject
    }
    const options = {
        legend: {
            display: false,
            position: 'bottom'
        },
        scales: {
            legend: {
                display: false,
                position: 'bottom'
            },
            yAxes: [{
                gridLines: {
                    display: true,
                },
                scaleLabel: {
                    display: true,
                    labelString: 'kWh'
                },
                ticks: {
                    suggestedMax: (Math.max(...parsedConsumptionData.map(d => d.consumption)) / 100) * 110,
                    suggestedMin: (Math.min(...parsedConsumptionData.map(d => d.consumption)) / 100) * 80,
                    maxTicksLimit: 10
                }
            }]
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    debugger;
                    let label = 'Energy: '
                    label += Math.round(tooltipItem.yLabel * 100) / 100;
                    label += ' kWh'
                    return label;
                },
                afterLabel: function (tooltipItem, data) {
                    let text = 'Cost: $'
                    text += data.datasets[0].price[tooltipItem.index];
                    return text;
                }
            }
        }
    }

    const isLoading = () => {
        return consumptionError || consumptionLoading || errorUI
    }


    return (
        isLoading() ? '' :
            <Bar data={chartData} options={options} height="50vh" />
    )
}

function useDeepEffect(fn, deps) {
    const isFirst = useRef(true);
    const prevDeps = useRef(deps);

    useEffect(() => {
        const isSame = prevDeps.current.every((obj, index) =>
            isEqual(obj, deps[index])
        );

        if (isFirst.current || !isSame) {
            fn();
        }

        isFirst.current = false;
        prevDeps.current = deps;
    }, deps);
}


const PowerConsumptionInterval = ({ clientId }) => {
    const [errorUI, seterrorUI] = useState(false);
    const [meters, setMeters] = useState([]);
    const [range, setRange] = useState(12);
    const [interval, setInterval] = useState('fifteen');
    const { data, loading, error, refetch } = useQuery(gql`
    query getPowerBillingByClient($id: Int!) {
        getPowerBillingByClient(client_id: $id) {
          clients{
          zones{
            id,
            name
            devices{
              ekm{
                serial,
                name
              }
            }
          }
        }
      }
    }`, {
        variables: { id: clientId }, fetchPolicy: "network-only"
    })

    function useDeepEffect(fn, deps) {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) =>
                isEqual(obj, deps[index])
            );

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        }, deps);
    }

    if (error) console.log(error);

    useEffect(() => {
        try {
            if (interval) {
                setRange(interval == 'fifteen' ? '12_hours' : interval == 'hour' ? '2_days' :
                    interval == 'day' ? '1_weeks' : interval == 'week' ? '3_months' : '6_months');
            }
        }
        catch{
            seterrorUI(true);
        }
    }, [interval])


    useDeepEffect(() => {
        try {
            if (data && data.getPowerBillingByClient && data.getPowerBillingByClient.clients[0].zones) {
                setMeters(parseClient(data.getPowerBillingByClient.clients[0].zones))
            }
        } catch {
            seterrorUI(true)
        }
    }, [data])



    const parseClient = (client) => {
        let meters = [];
        client.forEach(zone => {
            zone.devices.ekm.forEach(eachMeter => {
                let meter = {
                    serial: eachMeter.serial,
                    name: eachMeter.name
                }
                meters.push(meter)
            })
        })
        return meters;
    }

    return (
        <Panel md={12} lg={12} xl={12} xs={12} title="Power Consumption" loading={loading || error || errorUI}>
            <div style={{ float: "right" }}>
                <Options interval={interval} range={range} callbackInterval={setInterval} callbackRange={setRange} />
            </div>
            <div style={{ display: "inline-block", overflowX: "auto", width: "100%" }}>
                {
                    meters.map(meter => {
                        if (meter.name && meter.serial) {
                            return (
                                <React.Fragment>
                                    <div style={{ minWidth: "600px" }}>
                                        <h4>{meter.name}</h4>
                                        <ConsumptionChart meterId={meter.serial} interval={interval} range={range} />
                                    </div>
                                    <br />
                                </React.Fragment>
                            )
                        }
                    })
                }
            </div>
        </Panel>
    )
}



const Options = ({ range, interval, callbackRange, callbackInterval }) => {
    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Label style={{ marginRight: "10px", fontWeight: "500", fontSize: "larger" }}>Select Interval: </Label>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" value="fifteen" checked={interval == "fifteen"} onChange={(e) => { callbackInterval(e.target.value) }} /> 15 Min
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" value="hour" checked={interval == "hour"} onChange={(e) => { callbackInterval(e.target.value) }} /> 1 hour
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" value="day" checked={interval == "day"} onChange={(e) => { callbackInterval(e.target.value) }} /> 1 day
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" value="week" checked={interval == "week"} onChange={(e) => { callbackInterval(e.target.value) }} /> 1 week
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" value="month" checked={interval == "month"} onChange={(e) => { callbackInterval(e.target.value) }} /> 1 month
                        </Label>
                    </FormGroup>
                </Form>
            </Row>
            <Row>
                <Form>
                    <Label style={{ marginRight: "10px", fontWeight: "500", fontSize: "larger" }}>Select Range: </Label>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" value={getValueRange(interval, 1)[0]}
                                checked={range == getValueRange(interval, 1)[0]}
                                onChange={(e) => { callbackRange(e.target.value) }} /> {
                                getValueRange(interval, 1)[1]
                            }
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" value={getValueRange(interval, 2)[0]}
                                checked={range == getValueRange(interval, 2)[0]}
                                onChange={(e) => { callbackRange(e.target.value) }} />{
                                getValueRange(interval, 2)[1]
                            }
                        </Label>
                    </FormGroup>
                    <FormGroup check inline>
                        <Label check>
                            <Input type="radio" value={getValueRange(interval, 3)[0]}
                                checked={range == getValueRange(interval, 3)[0]}
                                onChange={(e) => { callbackRange(e.target.value) }} />{
                                getValueRange(interval, 3)[1]
                            }
                        </Label>
                    </FormGroup>
                </Form>
            </Row>
        </React.Fragment>
    )
}

const getValueRange = (interval, num) => {
    switch (interval) {
        case "fifteen":
            return num == 1 ? ["12_hours", "12 hours"] : num == 2 ? ["1_days", "1 day"] : num == 3 ? ["2_days", "2 days"] : ['', ''];
        case "hour":
            return num == 1 ? ["2_days", "2 days"] : num == 2 ? ["5_days", "5 days"] : num == 3 ? ["1_weeks", "1 week"] : ['', ''];
        case "day":
            return num == 1 ? ["1_weeks", "1 week"] : num == 2 ? ["2_weeks", "2 weeks"] : num == 3 ? ["1_months", "1 month"] : ['', ''];
        case "week":
            return num == 1 ? ["3_months", "3 months"] : num == 2 ? ["6_months", "6 months"] : num == 3 ? ["1_years", "1 year"] : ['', '']
        case "month":
            return num == 1 ? ["6_months", "6 months"] : num == 2 ? ["1_years", "1 year"] : num == 3 ? ["2_years", "2 years"] : ['', '']
    }
}



export default PowerConsumptionInterval

