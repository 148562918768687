import React, { useState, useEffect } from 'react'
import UnassignedMetersComponenent from './UnassignedMeters'
import AssignedZonesMeters from './AssignedTabs'
import MeterDescription from './MeterDescription'
import { DragDropContext } from 'react-beautiful-dnd'
import Panel from 'shared/components/Panel.jsx'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from './MeterConfiguration.graphql'

const MeterConfiguration = () => {
    const [errorUI, seterrorUI] = useState(false)
    const [unassignedMeters, setUnassignedMeters] = useState([])
    const [assignedMeters, setAssignedMeters] = useState([])
    const [meterMoved, setMeterMoved] = useState({})
    const [updateMeter] = useMutation(Actions.UPDATE_METER)
    const { data, loading, error, refetch } = useQuery(Actions.GET_METERS_ACCOUNT, {
        fetchPolicy: 'network-only',
    })
    useEffect(() => {
        try {
            if (meterMoved.id) {
                (async () => {
                    let zoneId = meterMoved.zone_id == '' ? null : meterMoved.zone_id;
                    await updateMeter({
                        variables: { id: meterMoved.id, input: { zone_id: zoneId } },
                    })
                    refetch()
                })()
            }
        } catch {
            seterrorUI(true)
        }
    }, [meterMoved])

    useEffect(() => {
        try {
            if (data && data.getAccounts[0]) {
                let { assigned, unAssigned } = parseMetersData(data.getAccounts[0]);
                setAssignedMeters(assigned);
                setUnassignedMeters(unAssigned);
            }
        } catch {
            seterrorUI(true)
        }
    }, [data])

    const parseMetersData = (account) => {
        let assigned = [], unAssigned = [], metersCompare = [];
        account.facilities[0].zones.forEach(zone => {
            let allMeterData = [];
            zone.devices.ekm.forEach(meter => {
                allMeterData.push({ id: meter.id, name: meter.name, serial: (meter.serial).toString() });
                metersCompare.push(meter.serial);
            })
            assigned.push({ id: zone.id, name: zone.name, meters: allMeterData });
        })
        account.devices.ekm.forEach(ekm => {
            if (!(metersCompare.includes(ekm.serial))) {
                unAssigned.push({ id: ekm.id, name: ekm.name, zone_id: "", serial: (ekm.serial).toString() })
            }
        })
        return { assigned, unAssigned }
    }

    const getMeterDetails = (sourceZoneName, meterIndex, destZoneName) => {
        let meterDetails = {}
        if (destZoneName == 'unassigned') {
            let zone = assignedMeters.filter(zoned => zoned.id == sourceZoneName)
            meterDetails['zone_id'] = ''
            meterDetails['id'] = zone[0].meters[meterIndex].id

        } else {
            meterDetails['zone_id'] = assignedMeters.filter(zone => zone.id === destZoneName)[0].id
            meterDetails['id'] = unassignedMeters[meterIndex].id
        }
        setMeterMoved(meterDetails)
    }

    const grid = 8
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        'border-style': isDragging ? 'solid' : 'none',
        background: isDragging ? 'lightblue' : 'white',
        ...draggableStyle,
    })

    const onDragEnd = (result) => {
        const { source, destination } = result
        if (!destination) {
            return
        }
        if (source.droppableId != destination.droppableId) {
            getMeterDetails(source.droppableId, source.index, destination.droppableId)

        }
    }

    const isLoading = () => {
        return errorUI || loading || error
    }

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <Panel title='Units' xs={6} xl={6} loading={isLoading()}>
                    <AssignedZonesMeters
                        meters={assignedMeters}
                        MeterDescription={MeterDescription}
                        dragStyle={getItemStyle}
                        callback={() => {
                            refetch()
                        }}
                    />
                </Panel>
                <Panel title="Unassigned meters" xs={6} xl={6} >
                    <UnassignedMetersComponenent
                        meters={unassignedMeters}
                        MeterDescription={MeterDescription}
                        dragStyle={getItemStyle}
                        callback={() => {
                            refetch()
                        }}
                    />
                </Panel>
            </DragDropContext>
        </React.Fragment>
    )
}
export default MeterConfiguration
