import React, { useState, useEffect, useRef } from 'react'
import {
    Row, ButtonToolbar, Card, CardBody, Col, Button, Popover, PopoverBody, PopoverHeader, Label, Input
} from 'reactstrap';
import styles from './Dashboard.module.scss'
import ThermostatIcon from 'mdi-react/ThermostatIcon'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from './UnitDashboard.graphql'
import { ScaleLoader } from "react-spinners";

const Thermostat = ({ id, device, screensize, refetch, smartThingsRefetch }) => {
    const name = device.name;
    const [popoverOpen, setPopoverOpen] = useState(false);
    return (
        <div className={screensize.width > 400 ? styles['box'] : styles['box_mobile']}>
            <div>
                <div className="card__title">
                    <h5 style={{ paddingTop: '5px' }} className={[styles['bold-text'], styles['flex-justify-center']].join(' ')}>
                        {name}
                    </h5>
                </div>
                <div className={["dashboard__health-chart", styles['flex-justify-center']].join(" ")}>
                    <ButtonToolbar className="btn-toolbar--center">
                        <ThermostatIcon id={'popover-' + id} onClick={() => setPopoverOpen(!popoverOpen)} size={60} />
                        <Popover
                            placement="auto-start"
                            isOpen={popoverOpen}
                            target={'popover-' + id}
                        >
                            <PopoverHeader>Settings</PopoverHeader>
                            <PopoverBody>
                                <Settings thermostatData={device} refetch={refetch} smartThingsRefetch={smartThingsRefetch} />
                            </PopoverBody>
                        </Popover>
                    </ButtonToolbar>
                </div>
            </div>
        </div>
    )
}

const Settings = ({ thermostatData, refetch, smartThingsRefetch }) => {
    const [toLoad, setToLoad] = useState(false);
    const [controlThermostat] = useMutation(Actions.CONTROL_THERMOSTAT);
    const [heatingPoint, setHeatingPoint] = useState('');
    const [coolingPoint, setCoolingPoint] = useState('');
    const [temperature, setTemperature] = useState('');
    const [fanMode, setFanMode] = useState('');
    const [mode, setMode] = useState('');
    const [modes, setModes] = useState([]);
    const [fanModes, setFanModes] = useState([]);

    useEffect(() => {
        try {
            if (thermostatData && thermostatData.id) {
                setHeatingPoint(thermostatData.heatingPoint);
                setCoolingPoint(thermostatData.coolingPoint);
                setTemperature(thermostatData.temperature);
                setFanMode(thermostatData.fanMode);
                setMode(thermostatData.mode);
                setModes(thermostatData.modes);
                setFanModes(thermostatData.fanModes);
            }
        }
        catch{
            setToLoad(true);
        }
    }, [thermostatData])


    const handleThermostat = async (control, command) => {
        if (thermostatData.id) {
            setToLoad(true);
            await controlThermostat({ variables: { smartId: thermostatData.id, command: command, control: control } })
            await refetch();
            await smartThingsRefetch();
            setToLoad(false);
        }
    }

    const handleMode = (e) => {
        if (e.currentTarget.value) {
            handleThermostat("setMode", e.currentTarget.value)
        }
    }

    const handleFanMode = (e) => {
        if (e.currentTarget.value) {
            handleThermostat("setFanMode", e.currentTarget.value)
        }
    }

    const heatOrCoolingTemp = (type, level) => {
        if (type == "heating") {
            if (level == "increase") {
                handleThermostat("setHeatingpoint", String(parseInt(heatingPoint) + 1))
            }
            else {
                handleThermostat("setHeatingpoint", String(parseInt(heatingPoint) - 1))
            }
        }
        else {
            if (level == "increase") {
                handleThermostat("setCoolingpoint", String(parseInt(heatingPoint) + 1))
            }
            else {
                handleThermostat("setCoolingpoint", String(parseInt(heatingPoint) - 1))
            }
        }
    }

    return (
        toLoad ? <div style={{ justifyContent: "center", display: "flex" }}>
            <ScaleLoader
                height={40}
                color={"#004B8D"}
                width={7}
                margin={3}
                loading={toLoad}
            /> </div> : (

                <React.Fragment>
                    <Row style={{ marginBottom: "5px" }}>
                        <Col xl={4}>
                            <div className={styles['rcorners_paddingLeft_30']}>
                                <Row>
                                    <b>Temperature</b>
                                </Row>
                                <Row>
                                    {temperature}
                                </Row>
                            </div>
                        </Col>
                        <Col xl={8}>
                            <div className={styles['rcorners']}>
                                <Row>
                                    <b>Heating Temperature</b>
                                </Row>
                                <Row>
                                    {heatingPoint}
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <Button onClick={() => heatOrCoolingTemp("heating", "decrease")}>-</Button>
                                    </Col>
                                    <Col xl={3}>
                                        <Button onClick={() => heatOrCoolingTemp("heating", "increase")}>+</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "5px" }}>
                        <Col xl={4}>
                            <div className={styles['rcorners_paddingLeft_30']}>
                                <Row>
                                    <b>State</b>
                                </Row>
                                <Row>
                                    {mode}
                                </Row>
                            </div>
                        </Col>
                        <Col xl={8}>
                            <div className={styles['rcorners']}>
                                <Row>
                                    <b>Cooling Temperature</b>
                                </Row>
                                <Row>
                                    {coolingPoint}
                                </Row>
                                <Row>
                                    <Col xl={3}>
                                        <Button onClick={() => heatOrCoolingTemp("cooling", "decrease")}>-</Button>
                                    </Col>
                                    <Col xl={3}>
                                        <Button onClick={() => heatOrCoolingTemp("cooling", "increase")}>+</Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "5px" }}>
                        <Col xl={6}>
                            <div className={styles['rcorners']}>
                                <Row>
                                    <b>Mode</b>
                                </Row>
                                {
                                    modes.map(eachMode => {
                                        return (
                                            <Row>
                                                <Input type="radio" value={eachMode} checked={eachMode == mode} onChange={(e) => handleMode(e)} /> {eachMode}
                                            </Row>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                        <Col xl={6}>
                            <div className={styles['rcorners']}>
                                <Row>
                                    <b>Fan Mode</b>
                                </Row>
                                {
                                    fanModes.map(eachFanMode => {
                                        return (
                                            <Row>
                                                <Input type="radio" value={eachFanMode} checked={eachFanMode == fanMode} onChange={(e) => handleFanMode(e)} /> {eachFanMode}
                                            </Row>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            )
    )
}

export default Thermostat