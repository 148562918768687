import React from 'react'
import { Route } from 'react-router-dom'
import { Switch, useRouteMatch } from 'react-router-dom'
import Layout from '../Layout/index'
import Accounts from './Accounts/routes'
import Facilities from './Facilities/routes'
import Users from './Users/routes'
import Locations from './Locations/routes'
import Zones from './Zones/routes'
import Roles from './Roles/routes'
import Products from './Products/routes'
import ProductActivations from './ProductActivations/routes'
import Home from './index'
import FireBlight from 'containers/Smartfarming/Settings/FireBlight/FireBlight'
import GatewaysAndZones from 'containers/Smartfarming/Settings/Davis/GatewaysAndZones'
import powerbilling from 'containers/Smartbuilding/Settings/PowerBilling'
import Davis from 'containers/Smartfarming/Settings/Davis/Davis'
import Devices from './Devices/routes'
import Gateways from './Gateways/routes'
import Profile from 'containers/Profile/index.jsx'

export default () => {
  let match = useRouteMatch()
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Switch>
          <Route path={`${match.path}/smartfarming/davis/:davisid`} component={Davis} />
          <Route path={`${match.path}/smartfarming/davis`} component={GatewaysAndZones} />
          <Route path={`${match.path}/smartfarming/fireblight`} component={FireBlight} />
          <Route path={`${match.path}/smartbuilding/powerbilling`} component={powerbilling} />
          <Route path={`${match.path}/users`} component={Users} />
          <Route path={`${match.path}/accounts`} component={Accounts} />
          <Route path={`${match.path}/facilities`} component={Facilities} />
          <Route path={`${match.path}/users`} component={Users} />
          <Route path={`${match.path}/locations`} component={Locations} />
          <Route path={`${match.path}/zones`} component={Zones} />
          <Route path={`${match.path}/devices`} component={Devices} />
          <Route path={`${match.path}/gateways`} component={Gateways} />
          <Route path={`${match.path}/roles`} component={Roles} />
          <Route path={`${match.path}/products`} component={Products} />
          <Route path={`${match.path}/productactivations`} component={ProductActivations} />
          <Route path={`${match.path}/profile`} component={Profile} />
          <Route path={`${match.path}`} component={Home} />
        </Switch>
      </div>
    </div>
  )
}
