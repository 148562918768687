import gql from 'graphql-tag'

export const GET_ZONES = gql`
query {
    getZones {
      id,
      name,
      facility_id,
      parent_zone_id,
      active
    }
  }`

export const GET_FACILITIES = gql`
query {
    getFacilities {
      id,
      name
    }
  }`


export const CREATE_ZONE = gql`
mutation createZone(	
  $input: ZoneInput!
  ){
    createZone(input: $input)
}`

export const UPDATE_ZONE = gql`
mutation updateZone(	
  $id:ID!
    $input: ZoneInput!
    ){
      updateZone(id:$id, input: $input)
  }`


