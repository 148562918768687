import React from 'react'
import BuildingsDashboard from '../Dashboard/Buildings'
import PageView from 'shared/components/PageView'
import SettingsOutlineIcon from 'mdi-react/SettingsOutlineIcon'
import BuildingIcon from 'mdi-react/BuildingIcon'
import { Col, Container, Row } from 'reactstrap'
import CardLink from 'shared/components/CardLink'
import styles from './Dashboard.module.scss'

const Dashboard = () => {
  return (
    <PageView trail={['Smart Things']}>
      <Row>
        <Col md={4} xs={12}>
          <CardLink title={'Buildings'} url={'buildings'}>
            <Row>
              <Col xs={4}>
                <BuildingIcon size={50} style={{ fill: '#1d55bf' }} />
              </Col>
              <Col style={{ textAlign: 'end', paddingTop: '20px' }} xs={8}></Col>
            </Row>
          </CardLink>
        </Col>
        <Col className={styles.configuration} md={4} xs={12}>
          <CardLink title={'Configuration'} url={'configuration'}>
            <Row>
              <Col md={4}>
                <SettingsOutlineIcon size={50} style={{ fill: '#1d55bf' }} />
              </Col>
              <Col style={{ textAlign: 'end', paddingTop: '20px' }} xs={8}></Col>
            </Row>
          </CardLink>
        </Col>
      </Row>
    </PageView>
  )
}

export default Dashboard
