import React, { useState, useEffect, useRef } from 'react'
import UnassignedZonesComponenent from './UnassignedZones'
import AssignedClients from './AssignedClients'
import ZoneDescription from './ZoneDescription'
import { DragDropContext } from 'react-beautiful-dnd'
import Panel from 'shared/components/Panel.jsx'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import * as Actions from './ZonesConfigurations.graphql'
import AddClient from './AddClient'
import { isEqual } from 'lodash'

const ZonesConfiguration = () => {
    const [facility, setFacility] = useState(null)
    const [errorUI, seterrorUI] = useState(false)
    const [unassignedZones, setUnassignedZones] = useState([])
    const [assignedZones, setAssignedZones] = useState([])
    const [zoneMoved, setZoneMoved] = useState({})
    const [updateZone] = useMutation(Actions.UPDATE_ZONE)

    const { data: facilitiesData, loading: facilitiesLoading, error: facilitiesError, refetch: facilitiesRefetch } = useQuery(Actions.GET_FACILITIES, {
        fetchPolicy: 'network-only',
    })
    const [loadClientsZones, { data, loading, error, refetch }] = useLazyQuery(Actions.GET_CLIENTS_ZONES, {
        fetchPolicy: 'network-only', variables: { facilityId: parseInt(facility) }
    })

    function useDeepEffect(fn, deps) {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) =>
                isEqual(obj, deps[index])
            );

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        }, deps);
    }

    useDeepEffect(() => {
        try {
            if (facilitiesData && facilitiesData.getFacilities) {
                setFacility(facilitiesData.getFacilities[0].id);
                loadClientsZones();
            }
        }
        catch{
            seterrorUI(true)
        }
    }, [facilitiesData])

    useDeepEffect(() => {
        try {
            if (zoneMoved.zoneId) {
                (async () => {
                    await updateZone({
                        variables: { client_id: zoneMoved.clientId, zone_id: zoneMoved.zoneId },
                    })
                    refetch()
                })()
            }
        } catch {
            seterrorUI(true)
        }
    }, [zoneMoved])

    useDeepEffect(() => {
        try {
            if (data && data.getPowerBillingByFacility.clients && facilitiesData) {
                let { assigned, unAssigned } = parseZonesData(facilitiesData.getFacilities[0], data.getPowerBillingByFacility.clients);
                setAssignedZones(assigned);
                setUnassignedZones(unAssigned);
            }
        } catch {
            seterrorUI(true)
        }
    }, [data])

    const parseZonesData = (allZones, assignedZones) => {
        let assigned = [], unAssigned = [], zonesCompare = [];
        assignedZones.forEach(client => {
            let zonesData = [];
            client.zones.forEach(zone => {
                zonesData.push({ id: zone.id, name: zone.name });
                zonesCompare.push(zone.id);
            })
            assigned.push({ id: client.id, name: client.name, email: client.email, active: client.active, enabled: client.email_enabled, zones: zonesData });
        })
        allZones.zones.forEach(zone => {
            if (!(zonesCompare.includes(zone.id))) {
                unAssigned.push({ id: zone.id, name: zone.name, client_id: "" })
            }
        })
        return { assigned, unAssigned }
    }

    const getZoneDetails = (sourceClientName, zoneIndex, destClientName) => {
        let zoneDetails = {}
        if (destClientName == 'unassigned') {
            let client = assignedZones.filter(clientd => clientd.id == sourceClientName)
            zoneDetails['clientId'] = ''
            zoneDetails['zoneId'] = client[0].zones[zoneIndex].id

        } else {
            zoneDetails['clientId'] = assignedZones.filter(clientd => clientd.id == destClientName)[0].id
            zoneDetails['zoneId'] = unassignedZones[zoneIndex].id
        }
        setZoneMoved(zoneDetails)
    }

    const grid = 8
    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,
        'border-style': isDragging ? 'solid' : 'none',
        background: isDragging ? 'lightblue' : 'white',
        ...draggableStyle,
    })

    const onDragEnd = (result) => {
        const { source, destination } = result
        if (!destination) {
            return
        }
        if (source.droppableId != destination.droppableId) {
            getZoneDetails(source.droppableId, source.index, destination.droppableId)

        }
    }

    const isLoading = () => {
        return errorUI || loading || error || facilitiesError || facilitiesLoading
    }

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={onDragEnd}>
                <Panel title='Clients' xs={8} loading={isLoading()} titleCenter={AddClient(refetch)}>
                    <AssignedClients
                        clients={assignedZones}
                        ZoneDescription={ZoneDescription}
                        dragStyle={getItemStyle}
                        callback={async () => {
                            await refetch();
                            await facilitiesRefetch();
                        }}
                    />
                </Panel>
                <Panel title="Unassigned units" xs={4}>
                    <UnassignedZonesComponenent
                        zones={unassignedZones}
                        ZoneDescription={ZoneDescription}
                        dragStyle={getItemStyle}
                    />
                </Panel>
            </DragDropContext>
        </React.Fragment>
    )
}
export default ZonesConfiguration
