import React, { useEffect, useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { useQuery } from '@apollo/react-hooks';
import { useRouteMatch } from 'react-router-dom'
import gql from 'graphql-tag'

const TopbarProfile = () => {
  let match = useRouteMatch()
  const [errorUI, setErrorUI] = useState(false);
  const Ava = `${process.env.PUBLIC_URL}/img/user.png`;
  const [collapse, setCollapse] = useState(false);
  const [image, setImage] = useState('');
  const [profileName, setProfileName] = useState(null);

  const { data, loading, error } = useQuery(gql`
    query getMe {
      me{
        firstName,
        lastName
      }
  }`);
  const { data: profileData, loading: profileLoading, error: profileError } = useQuery(gql`
  query{
    getProfilePicture
  }`);

  useEffect(() => {
    try {
      if (data && data.me) {
        let { firstName, lastName } = data.me;
        setProfileName(firstName + " " + lastName);
      }
    } catch {
      setErrorUI(true)
    }
  }, [data])

  useEffect(() => {
    try {
      if (profileData && profileData.getProfilePicture) {
        setImage(profileData.getProfilePicture);
      }
    }
    catch{
      setErrorUI(true)
    }
  }, [profileData])

  useEffect(() => {
    try {
      if (profileError) {
        setImage('');
      }
    }
    catch{
      setErrorUI(true)
    }
  }, [profileError])

  const parseImage = (imgData) => {
    if (imgData.filename && imgData.encoding) {
      let img = `data:${imgData.filename};base64,${imgData.encoding}`;
      return img;
    }
  }

  const isLoading = () => {
    return loading || error || errorUI || profileLoading;
  }



  return (
    isLoading() ? '' :
      <div className="topbar__profile">
        <button className="topbar__avatar" type="button" onClick={() => setCollapse(!collapse)}>
          <img className="topbar__avatar-img" src={(image == '' || errorUI) ? Ava : image} alt="avatar" />
          <p className="topbar__avatar-name">{error || errorUI || loading ? '' : profileName}</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button className="topbar__back" type="button" onClick={() => setCollapse(!collapse)} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            {/* <TopbarMenuLink title="My Profile" icon="user" path="/account/profile" />
          <div className="topbar__menu-divider" /> */}
            <TopbarMenuLink title="Profile" icon="user" path={`${match.path}/profile`} />
            <TopbarMenuLink title="Log Out" icon="exit" path="/login" />
          </div>
        </Collapse>
      </div>
  );

}

export default TopbarProfile
