import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from "./Products.graphql"
import PageView from 'shared/components/PageView'

const ProductsList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const columns = [
        { title: 'ID', field: 'id', editable: 'never', hidden: true },
        { title: 'Name', field: 'name' }
    ]

    const { data, loading, error, refetch } = useQuery(Actions.GET_PRODUCTS, { fetchPolicy: "network-only" })

    const [createProduct] = useMutation(Actions.CREATE_PRODUCT);
    const [updateProduct] = useMutation(Actions.UPDATE_PRODUCT);
    const [disableProduct] = useMutation(Actions.DISABLE_PRODUCT);



    useEffect(() => {
        try {
            if (data) {
                let table = data.getProducts;
                setTable(table);
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [data])



    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (newData.name != oldData.name) {
                let inputs = {
                    name: newData.name,
                    id: newData.id
                }
                await updateProduct({ variables: inputs })
                refetch();
            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        if (newData.name) {
            await createProduct({ variables: { name: newData.name } })
            refetch();
        }
    }

    const handleDeleteRow = async (oldData) => {
        if (oldData.id) {
            await disableProduct({ variables: { id: oldData.id } })
            refetch();
        }
    }


    return (
        <PageView trail={['Configuration', 'Products']}>
            <Panel md={12} lg={12} xl={12} xs={12} loading={loading || error || errorUI}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 10
                    }}
                    title="Products"
                    columns={columns}
                    data={table}
                    editable={{
                        // onRowAdd: async (newData) => {
                        //     await handleAddRow(newData);
                        //     table.push(newData);
                        //     setTable(table);
                        // },
                        // onRowUpdate: async (newData, oldData) => {
                        //     await handleSubmitRow(newData, oldData);
                        //     table[table.indexOf(oldData)] = newData
                        //     setTable(table);
                        // },
                        // onRowDelete: async (oldData) => {
                        //     await handleDeleteRow(oldData);
                        //     table.splice(table.indexOf(oldData), 1)
                        //     setTable(table);
                        // }
                    }}
                />
            </Panel>
        </PageView>
    )
}

export default ProductsList
