import React, { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel.jsx'
import gql from 'graphql-tag'

const EnergyRates = () => {
    const GET_ENERGY_RATES = gql`
                    query{
                        getAccounts{
                        facilities{
                            name
                            rates{
                                id
                                facility_id
                                energy_cost1_rate
                                energy_cost2_rate
                                ondemand_rate
                                tax_rate
                            }
                        }
                        }
                    }`

    const UPDATE_ENERGY_RATE = gql`
                    mutation EDIT_RATE($id: ID!, $input: RatesInput!){
                        editRate(id:$id, input: $input)
                    }`
                    
    const columns = [
        { title: 'id', field: 'id', editable: 'never', hidden: true },
        { title: 'facility id', field: 'facility_id', editable: 'never', hidden: true },
        { title: 'facility name', field: 'name', editable: 'never'},
        //{ title: 'Energy cost 1', field: 'energy_cost1_rate'},
        { title: 'Energy rate@', field: 'energy_cost2_rate'},
        //{ title: 'On demand rate', field: 'ondemand_rate'},
        { title: 'HST%', field: 'tax_rate'}
    ]
    const [facilityRates, setfacilityRates] = useState([]);
    const { data, loading, error, refetch } = useQuery(GET_ENERGY_RATES, { fetchPolicy: "network-only" })
    let [updateFacRate, { data: updateFacRateResponse, error: updateFacRateError }] = useMutation(UPDATE_ENERGY_RATE);

   
  
    useEffect(() => {
        if(data){
            let account = data.getAccounts[0]
            let facilities = account.facilities
            let facRates = facilities.map(facility => {
                let facRate = {}
                facRate["name"] = facility["name"]
                // facRate["energy_cost1_rate"] = facility.rates["energy_cost1_rate"]
                // facRate["energy_cost2_rate"] = facility.rates["energy_cost2_rate"]
                // facRate["ondemand_rate"] = facility.rates["ondemand_rate"]
                // facRate["tax_rate"] = facility.rates["tax_rate"]
                facRate = {...facRate, ...facility.rates}

                return facRate
            })
            setfacilityRates(facRates)
        }
       
    }, [data])

    if (loading) return 'Loading'
    if (error) return 'error'

    const handleUpdateRow = async (oldData, newData) => {
        let id = oldData["id"]
        let dataIn = {}
        dataIn["facility_id"] = oldData["facility_id"]
        dataIn["energy_cost1_rate"] = parseFloat(newData["energy_cost1_rate"])
        dataIn["energy_cost2_rate"] = parseFloat(newData["energy_cost2_rate"])
        dataIn["ondemand_rate"] = parseFloat(newData["ondemand_rate"])
        dataIn["tax_rate"] = parseFloat(newData["tax_rate"])
        updateFacRate({ variables: { id: id, input: dataIn } })
    }

    return(
        <React.Fragment>
            <Panel xs={12} xl={12}>
                <MaterialTable
                    title = "Facility energy rates"
                    columns={columns}
                    data={facilityRates}
                    editable={{
                        onRowUpdate: async (newData, oldData) => {
                            await handleUpdateRow(oldData, newData)
                            refetch()
                        }
                    }}
                >

                </MaterialTable>
            </Panel>
        </React.Fragment>
    )
}

export default EnergyRates