import React from 'react'
import { Route } from 'react-router-dom'
import { Switch, useRouteMatch } from 'react-router-dom'
import Home from './'
import ConfigurationRoutes from './Configuration/routes'
import PowerUsage from './PowerUsage'
import Billing from './Billing'
import Bill from './Billing/Bill'

export default () => {
  let match = useRouteMatch()
  return (
    <div>
      <Switch>
        <Route path={`${match.path}/configuration`} component={ConfigurationRoutes} />
        <Route path={`${match.path}/power-usage`} component={PowerUsage} />
        <Route path={`${match.path}/billing/bill/:id`} exact component={Bill} />
        <Route path={`${match.path}/billing`} exact component={Billing} />
        <Route path={`${match.path}/`} component={Home} />
      </Switch>
    </div>
  )
}
