import React, { useState } from 'react'
import PowerPlugIcon from 'mdi-react/PowerPlugIcon'
import PowerPlugOffIcon from 'mdi-react/PowerPlugOffIcon'
import styles from './Dashboard.module.scss'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from './UnitDashboard.graphql'
import { ScaleLoader } from "react-spinners";

const Relay = ({ device, screensize, refetch, smartThingsRefetch }) => {
    const name = device.name;
    const status = device.status == 'true';
    const serial = device.id;
    const [mouseDown, setMouseDown] = useState(false);
    const [toLoad, setToLoad] = useState(false);
    const [OnOffRelay] = useMutation(Actions.RELAY_ON_OFF);


    const handleOnClick = async (serial, status) => {
        if (serial) {
            setToLoad(true);
            await OnOffRelay({ variables: { smartId: serial, command: status ? 'off' : 'on' } });
            await refetch();
            await smartThingsRefetch();
            setToLoad(false);
        }
    }

    const getDevice = (status) => {
        let compareDevice = status ? '1' : '0'
        switch (compareDevice) {
            case '1':
                return [<PowerPlugIcon size={60} color='#ffffff' />, 'On']
            case '0':
                return [<PowerPlugOffIcon size={60} color='#00000' />, 'Off']
            default:
                return ['', '']
        }
    }


    let displayDevice = getDevice(status);
    return (
        <div
            className={[screensize.width > 400 ? styles['box'] : styles['box_mobile'], status ? styles['deviceOn'] : '', mouseDown ? styles['mouseDown'] : ''].join(' ')}
            onMouseDown={() => {
                setMouseDown(true)
            }}
            onMouseUp={() => setMouseDown(false)}
            onMouseLeave={() => setMouseDown(false)}
            onClick={() => handleOnClick(serial, status)}>
            {
                toLoad ? <div style={{ padding: "40px" }}>
                    <ScaleLoader
                        height={40}
                        color={"#004B8D"}
                        width={7}
                        margin={3}
                        loading={toLoad}
                    />
                </div> : <div>
                        <div className="card__title">
                            <h5 style={{ paddingTop: '5px', color: status ? '#ffffff' : '#000000' }} className={[styles['bold-text'], styles['flex-justify-center']].join(' ')}>
                                {name}
                            </h5>
                        </div>
                        <div className={["dashboard__health-chart", styles['flex-justify-center']].join(" ")}>
                            {
                                displayDevice[0]
                            }
                        </div>
                    </div>

            }
            <div>
                <span style={{ display: "flex", justifyContent: 'center' }}>{displayDevice[1]}</span>
            </div>
        </div>
    )
}

export default Relay