import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import Panel from 'shared/components/Panel'
import { useQuery, useMutation } from '@apollo/react-hooks'
import * as Actions from "./Facilities.graphql"
import PageView from 'shared/components/PageView'
import _ from 'lodash'
import momentTZ from 'moment-timezone';
import { NotificationManager } from 'react-notifications';

const FacilityList = () => {
    const [table, setTable] = useState([]);
    const [errorUI, seterrorUI] = useState(false);
    const [columns, setColumns] = useState([{ title: 'ID', field: 'id', editable: 'never', hidden: true },
    { title: 'Location*', field: 'location_id' },
    { title: 'Name*', field: 'name' },
    { title: 'City', field: 'city' },
    { title: 'Province', field: 'province' },
    { title: 'Country', field: 'country' },
    { title: 'Postal Code*', field: 'postal_code' },
    { title: 'Address*', field: 'address' },
    { title: 'Address Ext', field: 'address_ext' },
    { title: 'Timezone*', field: 'timezone' },
    {
        title: 'Status', field: 'status', editable: 'onUpdate', lookup: {
            1: "Active", 0: "Inactive"
        }
    }])

    const { data: locationsData, loading: locationsLoading, error: locationsError, refetch: locationsRefetch } = useQuery(Actions.GET_LOCATIONS, { fetchPolicy: "network-only" })
    const { data, loading, error, refetch } = useQuery(Actions.GET_FACILITIES, { fetchPolicy: "network-only" })

    const [createFacility] = useMutation(Actions.CREATE_FACILITY);
    const [updateFacility] = useMutation(Actions.UPDATE_FACILITY);
    const [disableFacility] = useMutation(Actions.DISABLE_FACILITY);

    useEffect(() => {
        try {
            if (data && locationsData) {
                makeTimezoneLookup();
                let table = data.getFacilities;
                setTable(getParsedTable(table));
            }
        }
        catch (e) {
            if (!errorUI) {
                seterrorUI(true);
            }
            console.log(e);
        }
    }, [data])

    useEffect(() => {
        try {
            if (locationsData) {
                let locations = locationsData.getLocations
                setColumns(makeLocationsLookup(locations));
            }
        }
        catch{
            if (!errorUI) {
                seterrorUI(true);
            }
        }
    }, [locationsData])

    const getParsedTable = (facilities) => {
        let facilityTable = [];
        facilities.forEach(facility => {
            facilityTable.push({ status: facility.active ? 1 : 0, ...facility });
        })
        return facilityTable;
    }
    const makeTimezoneLookup = () => {
        let lookupLocation = {};
        momentTZ.tz.names().forEach(timezone => {
            lookupLocation[timezone.toString()] = timezone
        })
        columns.map(eachCol => {
            if (eachCol.field == 'timezone') {
                eachCol['lookup'] = lookupLocation
            }
        })
        setColumns(columns)
    }

    const makeLocationsLookup = (locations) => {
        let lookupLocations = {};
        locations.forEach(eachLocation => {
            lookupLocations[(eachLocation.id).toString()] = eachLocation.name
        })
        columns.map(eachCol => {
            if (eachCol.field == 'location_id') {
                eachCol['lookup'] = lookupLocations
            }
        })
        return columns;
    }




    const handleSubmitRow = async (newData, oldData) => {
        try {
            if (!(_.isEqual(newData, oldData))) {
                if (newData.location_id && newData.name && newData.postal_code && newData.address && newData.timezone) {
                    let inputs = {
                        location_id: newData.location_id || null,
                        name: newData.name,
                        postal_code: newData.postal_code,
                        address: newData.address,
                        address_ext: newData.address_ext || '',
                        city: newData.city,
                        province: newData.province,
                        country: newData.country,
                        timezone: newData.timezone,
                        active: newData.status == 1
                    }
                    let result = await updateFacility({ variables: { id: newData.id, input: inputs } }).catch(e => {
                        NotificationManager.error('Error in Facility updation', 'Facility Information', 3000)
                    })
                    if (result) {
                        NotificationManager.success('Successfully updated', 'Facility Information', 2000);
                    }
                    refetch();
                }
                else {
                    NotificationManager.error('Mandatory fields are not provided', 'Facility Information', 3000)
                }
            }
            else {

            }
        }
        catch{
            seterrorUI(true);
        }
    }

    const handleAddRow = async (newData) => {
        if (newData.location_id && newData.name && newData.postal_code && newData.address && newData.timezone) {
            let inputs = {
                location_id: newData.location_id || null,
                name: newData.name,
                postal_code: newData.postal_code,
                address: newData.address,
                address_ext: newData.address_ext || '',
                city: newData.city,
                province: newData.province,
                country: newData.country,
                timezone: newData.timezone
            }
            let result = await createFacility({ variables: { input: inputs } }).catch(e => {
                NotificationManager.error('Error in Facility creation', 'Facility Information', 3000)
            })
            if (result) {
                NotificationManager.success('Successfully created', 'Facility Information', 2000);
            }
            refetch();
        }
        else {
            NotificationManager.error('Mandatory fields are not provided', 'Facility Information', 3000)
        }
    }

    const isLoading = () => {
        return loading || error || errorUI || locationsLoading || locationsError
    }


    return (
        <PageView trail={['Configuration', 'Facilities']}>
            <Panel md={12} lg={12} xl={12} xs={12} loading={isLoading()}>
                <MaterialTable
                    options={{
                        padding: "dense",
                        headerStyle: { backgroundColor: '#ada9a8', padding: '12px' },
                        exportButton: true,
                        exportAllData: true,
                        pageSize: 15,
                        addRowPosition: "first"
                    }}
                    title="Facilities"
                    columns={columns}
                    data={table}
                    editable={{
                        onRowAdd: async (newData) => {
                            await handleAddRow(newData);
                        },
                        onRowUpdate: async (newData, oldData) => {
                            await handleSubmitRow(newData, oldData);
                        }
                    }}
                />
            </Panel>
        </PageView>
    )
}

export default FacilityList
