import React, { useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Mapequipment from 'shared/components/Mapequipment/Mapequipment.jsx'
import SettingsOutlineIcon from 'mdi-react/SettingsOutlineIcon'
import FileChartIcon from 'mdi-react/FileChartIcon'
import ChartLineIcon from 'mdi-react/ChartLineIcon'
import PageView from 'shared/components/PageView'
import CardLink from 'shared/components/CardLink'
import styles from './PowerBilling.module.scss'
import { SizeMe } from 'react-sizeme'
import FacilityFilter from './FacilityFilter'
import { useApolloClient } from '@apollo/react-hooks'

const SmartbuildingDashboard = () => {
  const [facility, setFacility] = useState(null);
  const client = new useApolloClient()
  client.cache.writeData({
    data: {
      facilityId: 0,
      clientId: 0
    }
  });
  return (
    <PageView trail={['Power Billing']}>
      <Row>
        <Mapequipment facility={facility} />
      </Row>
      <Row>
        <Col md={4} xs={12}>
          <CardLink title={'Billing'} url={'billing'}>
            <Row>
              <Col xs={4}>
                <FileChartIcon size={50} style={{ fill: '#1d55bf' }} />
              </Col>
              <Col style={{ textAlign: 'end', paddingTop: '20px' }} xs={8}></Col>
            </Row>
          </CardLink>
        </Col>
        <Col md={4} xs={12}>
          <CardLink title={'Power Usage'} url={'power-usage'}>
            <Row>
              <Col xs={4}>
                <ChartLineIcon size={50} style={{ fill: '#1d55bf' }} />
              </Col>
              <Col style={{ textAlign: 'end', paddingTop: '20px' }} xs={8}></Col>
            </Row>
          </CardLink>
        </Col>
        <Col className={styles.configuration} md={4} xs={12}>
          <CardLink title={'Configuration'} url={'configuration'}>
            <Row>
              <Col md={4}>
                <SettingsOutlineIcon size={50} style={{ fill: '#1d55bf' }} />
              </Col>
              <Col style={{ textAlign: 'end', paddingTop: '20px' }} xs={8}></Col>
            </Row>
          </CardLink>
        </Col>
      </Row>
    </PageView>
  )
}
export default SmartbuildingDashboard
