import React, { useState } from 'react'
import MeterConfiguration from './components/MeterConfiguration'
import ZonesConfiguration from './components/ZonesConfiguration'
import EnergyRates from './components/EnergyRates'
import PageView from 'shared/components/PageView'
import Collapse from 'shared/components/Collapse';
import { Row } from 'reactstrap'



const SmartbuildingDashboard = () => {
  const [metersCollapse, setMetersCollapse] = useState(false);
  const [zonesCollapse, setZonesCollapse] = useState(false);
  const [ratesCollapse, setRatesCollapse] = useState(true);

  const handleMetersCollapse = () => {
    setMetersCollapse(!metersCollapse);
  }
  const handleZonesCollapse = () => {
    setZonesCollapse(!zonesCollapse);
  }
  const handleRatesCollapse = () => {
    setRatesCollapse(!ratesCollapse);
  }

  return (
    <PageView trail={['Power Billing', 'Configuration']}>
      <div className="collapse-blue">
        <Collapse title="Assign Meters to Units" className="with-shadow" collapse={metersCollapse} callback={handleMetersCollapse}>
          <Row>
            <MeterConfiguration />
          </Row>
        </Collapse>
      </div>
      <div className="collapse-light-1-blue">
        <Collapse title="Assign Units to Clients" className="with-shadow" collapse={zonesCollapse} callback={handleZonesCollapse}>
          <Row>
            <ZonesConfiguration />
          </Row>
        </Collapse>
      </div>
      <div className="collapse-light-2-blue">
        <Collapse title="Configure Energy rate" className="with-shadow" collapse={ratesCollapse} callback={handleRatesCollapse}>
          <Row>
            <EnergyRates />
          </Row>
        </Collapse>
      </div>
    </PageView>
  )
}
export default SmartbuildingDashboard
