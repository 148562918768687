import React, { useEffect, useState } from 'react'
import Panel from 'shared/components/Panel'
import * as Actions from './Mapequipment.graphql'
import { useQuery } from '@apollo/react-hooks'
import * as utls from 'shared/utls/index.js'
import classnames from 'classnames'
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col } from 'reactstrap'
import moment from 'moment'
import Geocode from 'react-geocode'
import { func } from 'prop-types'

const { compose, withProps, withHandlers, withStateHandlers } = require('recompose')
const { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow, Circle } = require('react-google-maps')

const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer')
const MapWithAMakredInfoWindow = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyDv_DaU0LLpt4UlveX2lVmSXeqHveGWnS8&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => markerClusterer => {
      // const clickedMarkers = markerClusterer.getMarkers()
    },
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
      infoIndex: null,
    }),
    {
      onToggleOpen: ({ isOpen, infoIndex }) => index => ({
        isOpen: !isOpen,
        infoIndex: index,
      }),
    }
  ),

  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <GoogleMap defaultZoom={15} defaultCenter={{ lat: props.markers[0].latitude, lng: props.markers[0].longitude }}>
      <MarkerClusterer onClick={props.onMarkerClustererClick} averageCenter enableRetinaIcons gridSize={60}>
        {props.markers.map(marker => (
          <Marker
            key={marker.locID}
            position={{ lat: marker.latitude, lng: marker.longitude }}
            onClick={() => props.onToggleOpen(marker.locID)}
          >
            {props.isOpen && props.infoIndex === marker.locID && (
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <div>
                  <p>
                    <b>{marker.locName}</b>
                    <br />
                    Address: {marker.address}
                    <br />
                  </p>
                  {
                    <AllMetersStatus meters={marker.allClients} />
                    // <MetersStatus clients={marker.clients} />
                  }
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </MarkerClusterer>
    </GoogleMap>
  )
})

const AllMetersStatus = ({ meters }) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <b>Meters Status</b>
        </Col>
        <Col>
          {meters.filter(meter => {
            return meter.status !== "Online"
          }).length > 0 ? (
              <span
                style={{ borderRadius: 50, height: 10, width: 10, backgroundColor: '#ff0000', display: 'inline-block', whiteSpace: 'nowrap' }}
              >
                {/* <div style={{ fontSize: '8px', fontWeight: '800' }}>&emsp;&nbsp;&nbsp;Offline</div> */}
              </span>
            ) : (
              <span
                style={{ borderRadius: 50, height: 10, width: 10, backgroundColor: '#00ff00', display: 'inline-block', whiteSpace: 'nowrap' }}
              >
                {/* <div style={{ fontSize: '8px', fontWeight: '800' }}>&emsp;&nbsp;&nbsp;Online</div> */}
              </span>
            )}
        </Col>
      </Row>
      <hr />
      <div>
        {meters.map(meter => {
          return (
            <Row>
              <Col>
                <b>{meter.name}</b>
              </Col>
              <Col>
                <span
                  style={{
                    borderRadius: 50,
                    height: 10,
                    width: 10,
                    backgroundColor: meter.status == "Online" ? '#00ff00' : '#ff0000',
                    display: 'inline-block',
                    whiteSpace: 'nowrap',
                  }}
                >
                    <div style={{ fontSize: '8px', fontWeight: '800' }}>&emsp;&nbsp;&nbsp;{meter.status}</div>
                </span>
              </Col>
            </Row>
          )
        })}
      </div>
    </React.Fragment>
  )
}

const MetersStatus = ({ clients }) => {
  const [activeTab, setActiveTab] = useState(clients[0]['id'])
  return (
    <React.Fragment>
      <div className="tabs tabs--justify tabs--bordered-top">
        <div className="tabs__wrap">
          <Nav tabs>
            {clients.map(client => {
              return (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === client['id'] })}
                    onClick={() => {
                      setActiveTab(client['id'])
                    }}
                  >
                    {client['name']}
                  </NavLink>
                </NavItem>
              )
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            {clients.map(client => {
              return (
                <TabPane tabId={client['id']}>
                  <div>
                    {client.meters.map(meter => {
                      return (
                        <Row>
                          <Col>
                            <b>{meter.name}</b>
                          </Col>
                          <Col>
                            {meter.status ? (
                              <span
                                style={{
                                  borderRadius: 50,
                                  height: 10,
                                  width: 10,
                                  backgroundColor: '#00ff00',
                                  display: 'inline-block',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <div style={{ fontSize: '8px', fontWeight: '800' }}>&emsp;&nbsp;&nbsp;Online</div>
                              </span>
                            ) : (
                                <span
                                  style={{
                                    borderRadius: 50,
                                    height: 10,
                                    width: 10,
                                    backgroundColor: '#ff0000',
                                    display: 'inline-block',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  <div style={{ fontSize: '8px', fontWeight: '800' }}>&emsp;&nbsp;&nbsp;Offline</div>
                                </span>
                              )}
                          </Col>
                        </Row>
                      )
                    })}
                  </div>
                </TabPane>
              )
            })}
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  )
}

const Equipmentmap = () => {
  const [locationData, setLocationData] = useState([])
  const [allMeters, setAllMeters] = useState([])
  const [clients, setClients] = useState([])
  const [address, setAddress] = useState('')
  const [latlong, setLatlong] = useState('')
  const [errorUI, seterrorUI] = useState(false)
  const { data, loading, error } = useQuery(Actions.GET_LOCATION, { fetchPolicy: 'network-only', pollInterval: 300000 })
  // const { data: metersData, loading: metersLoading, error: metersError } = useQuery(Actions.GET_METERS_FACILITY);

  const parseLocationData = (location, latlong, address) => {
    let loc = []
    const { lat, lng } = latlong
    loc.push({
      locID: parseInt(location.id),
      locName: location.name,
      address: address,
      latitude: lat,
      longitude: lng,
      allClients: getParsedMeters(location.facilities[0].powerBilling.clients),
    })
    return loc;
  }

  const getLatLong = address => {
    Geocode.setApiKey('AIzaSyDv_DaU0LLpt4UlveX2lVmSXeqHveGWnS8')
    Geocode.setLanguage('en')
    Geocode.setRegion('ca')
    return Geocode.fromAddress(address).then(
      response => {
        setLatlong(response.results[0].geometry.location)
      },
      error => {
        seterrorUI(true)
      }
    )
  }

  useEffect(() => {
    try {
      if (data) {
        let { postal_code, address, address_ext } = data.getLocations[0].facilities[0]
        setAddress(address + ' ' + address_ext + ', ' + postal_code)
      }
    } catch (e) {
      if (!errorUI) {
        seterrorUI(true)
      }
      console.log(e)
    }
  }, [data])

  useEffect(() => {
    try {
      if (latlong) {
        let location = data.getLocations[0]
        setLocationData(parseLocationData(location, latlong, address))
      }
    } catch (e) {
      if (!errorUI) {
        seterrorUI(true)
      }
      console.log(e)
    }
  }, [latlong])

  useEffect(() => {
    if (address != '') {
      getLatLong(address)
    }
  }, [address])

  // const getParsedMeters = data => {
  //   let clients = []
  //   data.forEach(client => {
  //     let meters = []
  //     client.meters.forEach(meter => {
  //       meters.push({ id: meter.id, name: meter.name, status: meter.status })
  //       allMeters.push({ id: meter.id, name: meter.name, status: meter.status })
  //     })
  //     clients.push({ id: client.id, name: client.name, meters: meters })
  //   })
  //   return allMeters
  // }

  const getParsedMeters = data => {
    let allMeters = []
    data.forEach(client => {
      client.zones.forEach(zone => {
        zone.devices.ekm.forEach(meter => {
          allMeters.push({ id: meter.serial, name: meter.name, status: meter.status["status"], lastUpdatedTime: meter.status["last_updated_time"]  })
        })
      })
    })
    return allMeters
  }

  const isLoading = () => {
    return loading || error || errorUI
  }

  return (
    <Panel md={12} lg={12} xl={12} xs={12} title={'Location'} loading={isLoading()}>
      {locationData.length != 0 ? <MapWithAMakredInfoWindow markers={locationData} /> : ''}
    </Panel>
  )
}
export default Equipmentmap
